var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "registrationForm",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.emitSignUp($event)
        },
      },
    },
    [
      _c(
        "v-slide-x-transition",
        { attrs: { group: "" } },
        [
          _c(
            "v-main",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step == 1,
                  expression: "step == 1",
                },
              ],
              key: "1",
            },
            [
              _c("h4", { staticClass: "text-center text-uppercase mt-6" }, [
                _vm._v(" " + _vm._s(_vm.$t("signup.createAccount")) + " "),
              ]),
              _vm.signupType
                ? _c(
                    "v-alert",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.anonymousUserInfo.type != "Playtient",
                          expression: "anonymousUserInfo.type != 'Playtient'",
                        },
                      ],
                      staticClass:
                        "mt-4 mb-0 text-center font-weight-bold px-6",
                      attrs: { color: "warning", tile: "" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("signup.types." + _vm.signupType)) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.betaClosedError
                ? _c(
                    "v-alert",
                    {
                      staticClass:
                        "mt-4 mb-0 text-center font-weight-bold px-6",
                      attrs: { color: "info", tile: "" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("signup.betaClosed")) + " ")]
                  )
                : _vm._e(),
              _c(
                "v-container",
                {
                  staticClass: "col-10 offset-1 col-md-4 offset-md-4",
                  attrs: { fluid: "", "fill-heigh": "" },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "mgl-prepend-absolute mgl-noborder",
                            attrs: {
                              name: "email",
                              placeholder: _vm.$t("signup.email"),
                              outlined: "",
                              rounded: "",
                              color: "accent",
                              "prepend-inner-icon": "mdi-email-outline",
                              type: "text",
                              rules: [_vm.rules.required, _vm.rules.email],
                              "validate-on-blur": "",
                              required: "",
                              "background-color": "primary",
                              readonly: _vm.inviteEmail !== "",
                            },
                            on: {
                              input: function ($event) {
                                _vm.email = _vm.email
                                  .replace(/\s+/g, "")
                                  .toLowerCase()
                              },
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "space",
                                    32,
                                    $event.key,
                                    [" ", "Spacebar"]
                                  )
                                ) {
                                  return null
                                }
                                $event.preventDefault()
                              },
                              paste: _vm.handlePaste,
                            },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                top: "",
                                color: "secondary",
                                "allow-overflow": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            staticClass: "mgl-noborder",
                                            attrs: {
                                              placeholder:
                                                _vm.$t("signup.password"),
                                              name: "password",
                                              outlined: "",
                                              rounded: "",
                                              "prepend-inner-icon":
                                                "mdi-lock-outline",
                                              color: "accent",
                                              "validate-on-blur": "",
                                              rules: [
                                                _vm.rules.required,
                                                _vm.rules.minLength,
                                                _vm.rules.uppercase,
                                                _vm.rules.lowercase,
                                                _vm.rules.symbol,
                                                _vm.rules.number,
                                                _vm.rules.writableSymbols,
                                              ],
                                              required: "",
                                              type: "password",
                                              "background-color": "primary",
                                            },
                                            model: {
                                              value: _vm.password,
                                              callback: function ($$v) {
                                                _vm.password =
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                              },
                                              expression: "password",
                                            },
                                          },
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.showTooltip,
                                callback: function ($$v) {
                                  _vm.showTooltip = $$v
                                },
                                expression: "showTooltip",
                              },
                            },
                            [
                              _c(
                                "ul",
                                { staticClass: "mgl-pwd-list my-2" },
                                _vm._l(
                                  _vm.passwordCriterias,
                                  function (criteria) {
                                    return _c(
                                      "li",
                                      {
                                        key: criteria.id,
                                        staticClass: "d-flex align-center mb-1",
                                      },
                                      [
                                        _c(
                                          "v-chip",
                                          {
                                            staticClass: "mr-2",
                                            attrs: {
                                              small: "",
                                              color: criteria.fulfilled
                                                ? "success"
                                                : "white",
                                              outlined: !criteria.fulfilled,
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: criteria.fulfilled,
                                                    expression:
                                                      "criteria.fulfilled",
                                                  },
                                                ],
                                                attrs: { small: "" },
                                              },
                                              [_vm._v("mdi-check")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(
                                          " " + _vm._s(criteria.text) + " "
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                          _c("v-text-field", {
                            staticClass: "mgl-append-green mgl-noborder",
                            attrs: {
                              placeholder: _vm.$t("signup.confirmPassword"),
                              name: "password2",
                              outlined: "",
                              rounded: "",
                              "prepend-inner-icon": "mdi-lock-outline",
                              color: "accent",
                              type: "password",
                              rules: [
                                _vm.rules.required,
                                _vm.rules.matchPassword,
                              ],
                              required: "",
                              "validate-on-blur": "",
                              "append-icon": _vm.passwordMatch
                                ? "mdi-check"
                                : "",
                              "background-color": "primary",
                            },
                            model: {
                              value: _vm.password2,
                              callback: function ($$v) {
                                _vm.password2 =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "password2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "uppercase-text",
                              attrs: {
                                "x-large": "",
                                rounded: "",
                                block: "",
                                color: "secondary",
                                disabled: !_vm.step1Complete,
                              },
                              on: { click: _vm.nextStep },
                            },
                            [_vm._v(_vm._s(_vm.$t("generic.next")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-footer",
                {
                  staticClass: "text-center pt-0",
                  attrs: { color: "transparent" },
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("signup.already")) + " "
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "pl-2 text-uppercase",
                                  attrs: {
                                    to: {
                                      name: "login",
                                      query: this.$route.query,
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("login.login")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-slide-x-transition",
        [
          _c(
            "v-main",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.step == 2,
                  expression: "step == 2",
                },
              ],
              key: "1",
            },
            [
              _c(
                "v-container",
                {
                  staticClass: "col-12 col-md-4 offset-md-4 px-4",
                  attrs: { fluid: "", "fill-height": "" },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "align-center mt-4 mx-3" },
                    [
                      _c("v-col", { staticClass: "pt-0" }, [
                        _c("h5", { staticClass: "text-center mb-2" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("signup.step2Header")) + " "
                          ),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-center caption font-italic grey--text text--lighten-2 mb-0",
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("signup.step2Details")) + " "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "pa-5",
                              attrs: {
                                color: "primaryDark",
                                flat: "",
                                rounded: "",
                              },
                            },
                            [
                              _c("h5", { staticClass: "mb-3" }, [
                                _vm._v(_vm._s(_vm.$t("signup.nickname"))),
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  rounded: "",
                                  dense: "",
                                  color: "accent",
                                  "item-color": "accent",
                                  maxLength: "20",
                                },
                                model: {
                                  value: _vm.userInfo.nickname,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.userInfo,
                                      "nickname",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "userInfo.nickname",
                                },
                              }),
                              _c("p", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.anonymousUserInfo.birthYear,
                                    expression: "!anonymousUserInfo.birthYear",
                                  },
                                ],
                                staticClass:
                                  "text-center caption error--text mb-0 mt-2",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "pa-5",
                              attrs: {
                                color: "primaryDark",
                                flat: "",
                                rounded: "",
                              },
                            },
                            [
                              _c("h5", { staticClass: "mb-3" }, [
                                _vm._v(_vm._s(_vm.$t("signup.yearOfBirth"))),
                              ]),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.yearOptions,
                                  outlined: "",
                                  rounded: "",
                                  dense: "",
                                  color: "accent",
                                  "item-color": "accent",
                                  placeholder: "----",
                                },
                                model: {
                                  value: _vm.anonymousUserInfo.birthYear,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.anonymousUserInfo,
                                      "birthYear",
                                      $$v
                                    )
                                  },
                                  expression: "anonymousUserInfo.birthYear",
                                },
                              }),
                              _c(
                                "p",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.anonymousUserInfo.birthYear,
                                      expression:
                                        "!anonymousUserInfo.birthYear",
                                    },
                                  ],
                                  staticClass:
                                    "text-center caption error--text mb-0 mt-2",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("signup.minimumAge")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "pa-5",
                              attrs: {
                                color: "primaryDark",
                                flat: "",
                                rounded: "",
                              },
                            },
                            [
                              _c("h5", { staticClass: "mb-3" }, [
                                _vm._v(_vm._s(_vm.$t("signup.gender"))),
                              ]),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.genderOptions,
                                  outlined: "",
                                  rounded: "",
                                  dense: "",
                                  color: "accent",
                                  "item-color": "accent",
                                  placeholder: "----",
                                },
                                model: {
                                  value: _vm.anonymousUserInfo.gender,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.anonymousUserInfo,
                                      "gender",
                                      $$v
                                    )
                                  },
                                  expression: "anonymousUserInfo.gender",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isPlaytient
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-5",
                                  attrs: {
                                    color: "primaryDark",
                                    flat: "",
                                    rounded: "",
                                  },
                                },
                                [
                                  _c("h5", { staticClass: "mb-3" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("signup.diagnosis.label"))
                                    ),
                                  ]),
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.diagnosisOptions,
                                      outlined: "",
                                      rounded: "",
                                      dense: "",
                                      placeholder: "----",
                                      color: "accent",
                                      "item-color": "accent",
                                    },
                                    on: { change: _vm.onDiagnosisChange },
                                    model: {
                                      value: _vm.anonymousUserInfo.diagnosis,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.anonymousUserInfo,
                                          "diagnosis",
                                          $$v
                                        )
                                      },
                                      expression: "anonymousUserInfo.diagnosis",
                                    },
                                  }),
                                  _c("v-slide-y-transition", [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.isDiagnosed,
                                            expression: "isDiagnosed",
                                          },
                                        ],
                                        key: "3",
                                      },
                                      [
                                        _c("h5", { staticClass: "mb-3" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("signup.diagnosedWhen")
                                            )
                                          ),
                                        ]),
                                        _c("v-date-picker", {
                                          ref: "diagnosisDatePicker",
                                          staticClass: "mgl-picker",
                                          attrs: {
                                            type: "month",
                                            "full-width": "",
                                            locale: _vm.$i18n.locale,
                                            flat: "",
                                            color: "secondary",
                                            max: _vm.maxDiagnoseDateString,
                                            min: _vm.minDiagnoseDateString,
                                            reactive: "",
                                          },
                                          on: {
                                            "click:month": function ($event) {
                                              return _vm.formatDiagnosisDate()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.anonymousUserInfo
                                                .diagnosisDate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.anonymousUserInfo,
                                                "diagnosisDate",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "anonymousUserInfo.diagnosisDate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "caption mx-4 d-flex align-center justify-center",
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "v-chip",
                                {
                                  staticClass: "mgl-chip-checkbox mr-3",
                                  attrs: {
                                    color: _vm.haveAgreed
                                      ? "success"
                                      : "secondary",
                                    outlined: !_vm.haveAgreed,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.haveAgreed = !_vm.haveAgreed
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.haveAgreed,
                                          expression: "haveAgreed",
                                        },
                                      ],
                                    },
                                    [_vm._v("mdi-check")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("fig.registration.pleaseRead")) +
                                " "
                            ),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "white--text text-decoration-underline",
                                attrs: {
                                  href: _vm.privacyPolicyUrl,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("fig.registration.privacyPolicy")
                                    )
                                ),
                              ]
                            ),
                            _vm._v(
                              " " + _vm._s(_vm.$t("fig.registration.and")) + " "
                            ),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "white--text text-decoration-underline",
                                attrs: { href: _vm.termsUrl, target: "_blank" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("fig.registration.terms")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "mx-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "uppercase-text",
                              attrs: {
                                "x-large": "",
                                rounded: "",
                                block: "",
                                color: "secondary",
                                type: "submit",
                                disabled: !_vm.isFormFilled,
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("signup.signup")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }