/**
 * @typedef {{name: string, sensors: {typeId: string, name: string, dataKey: string}[]}} RoutineDefinition
 * @typedef {{medication: RoutineDefinition, wellbeing: RoutineDefinition, sleep: RoutineDefinition,
 * food: RoutineDefinition, exercise: RoutineDefinition}} RoutineConfig
 */

import { hasDefinedProperty } from '../../../utils/objects';

const routineConfig = {
  medication: {
    name: 'Medication',
    sensors: [
      {
        typeId: '396c14fa-e2e3-4510-914e-b0d7fe56bb49',
        name: 'adherence',
        dataKey: 'categoryIndexValue',
      },
    ],
  },
  wellbeing: {
    name: 'Wellbeing',
    sensors: [
      {
        typeId: '9e4e8fca-36d0-402f-bdc0-40e45545fe81',
        name: 'mood-bipolar',
        dataKey: 'categoryIndexValue',
      },
      {
        typeId: '33301b11-6167-405e-bced-b567eb5da41e',
        name: 'mood-depression',
        dataKey: 'categoryIndexValue',
      },
      {
        typeId: '70ccb310-b14b-48a7-947f-337aa0daf4ec',
        name: 'mood-emotions-positive',
        dataKey: 'epochValue',
      },
      {
        typeId: 'e2729a71-01f4-4eaf-9e40-2b4baff4640c',
        name: 'mood-emotions-negative',
        dataKey: 'epochValue',
      },
    ],
  },
  sleep: {
    name: 'Sleep',
    sensors: [
      {
        typeId: '8831f42c-bdc9-46e9-b1f4-9925d87647c2',
        name: 'bedtime-epoch-assessed',
        dataKey: 'epochValue',
      },
      {
        typeId: 'b7f9c016-eb7c-4470-9dae-992aa67a6f61',
        name: 'bedtime-epoch-measured',
        dataKey: 'epochValue',
      },
      {
        typeId: '8474404a-f9de-4983-b019-47a2918192f5',
        name: 'sleep-duration-assessed',
        dataKey: 'intValue',
      },
      {
        typeId: 'cd944ce3-26ef-4388-b93a-3496d243da06',
        name: 'sleep-duration-measured',
        dataKey: 'intValue',
      },
    ],
  },
  food: {
    name: 'Food',
    sensors: [
      {
        typeId: 'a4038e55-e260-4321-8126-4399d1c57257',
        name: 'meal-breakfast',
        dataKey: 'boolValue',
      },
      { typeId: '801fb8b4-4835-456f-8ff2-1294cff81570', name: 'meal-lunch', dataKey: 'boolValue' },
      { typeId: 'b0cb47ad-88e8-40d6-96cb-be1e82419017', name: 'meal-dinner', dataKey: 'boolValue' },
    ],
  },
  exercise: {
    name: 'Exercise',
    sensors: [
      { typeId: '498d97a3-82f8-4d49-a99a-de2c21eca4b0', name: 'walk-steps', dataKey: 'intValue' },
    ],
  },
};

const sensorTypeToRoutineMap = [];
addRoutineSensorsToMap(routineConfig);

/**
 * Adds sensor to routine map
 * @param {RoutineConfig} routines
 */
function addRoutineSensorsToMap(routines) {
  for (const routineName in routines) {
    if (hasDefinedProperty(routines, routineName)) {
      const routine = routines[routineName];
      for (const sensor of routine.sensors) {
        const { name, typeId, dataKey } = sensor;
        sensorTypeToRoutineMap[typeId] = { routine: routine.name, name, dataKey };
      }
    }
  }
}

/**
 * Get routine definition by name
 * @param {string} routineName
 * @returns {RoutineDefinition|undefined}
 */
export function getRoutineDefinition(routineName) {
  if (hasDefinedProperty(routineConfig, routineName)) {
    return routineConfig[routineName];
  }
  console.warn(`Routine config does not contain routine with name: ${routineName}`);
  return undefined;
}

/**
 * Get sensor name using type id
 * @param {string} sensorTypeId
 * @returns {string}
 */
export function getSensorName(sensorTypeId) {
  const sensorInfo = sensorTypeToRoutineMap[sensorTypeId];
  if (!sensorInfo) {
    return undefined;
  }
  return sensorInfo.name;
}

/**
 * Get sensor data key using type id
 * @param {string} sensorTypeId
 * @returns {string}
 */
export function getSensorDataKey(sensorTypeId) {
  const sensorInfo = sensorTypeToRoutineMap[sensorTypeId];
  if (!sensorInfo) {
    return undefined;
  }
  return sensorInfo.dataKey;
}

/**
 * Get sensor type id for sensor name
 * @param {string} sensorName
 * @returns {string}
 */
export function getSensorTypeId(sensorName) {
  for (const sensorTypeId in sensorTypeToRoutineMap) {
    if (hasDefinedProperty(sensorTypeToRoutineMap, sensorTypeId)) {
      const sensorInfo = sensorTypeToRoutineMap[sensorTypeId];
      if (sensorInfo.name === sensorName) {
        return sensorTypeId;
      }
    }
  }
  return undefined;
}

export function getRoutineSensorInfo(sensorTypeId) {
  return sensorTypeToRoutineMap[sensorTypeId];
}
