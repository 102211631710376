<template>
  <v-container>
    <v-card style="max-width: 500px; margin: auto" color="primary" flat rounded class="pa-5">
      <h2 class="ma-0 text-center">
        {{ $t('reports.meals') }}
      </h2>

      <p class="pa-5 text-center subtitle" v-html="mealsDescription" />

      <!-- Graph -->
      <section class="graph">
        <div class="meals">
          <div class="yaxis">
            <div>{{ $t('reports.breakfast') }}</div>
            <div>{{ $t('reports.lunch') }}</div>
            <div>{{ $t('reports.dinner') }}</div>
          </div>
          <div class="checks-content">
            <div v-for="(c, i) in reportData" :key="`c${i}`">
              <div
                class="check"
                v-for="(m, i) in c"
                :key="`cc${i}`"
                :class="{ checked: !!m, unchecked: m == false }"
              >
                <v-icon v-if="m == true" dark>mdi-check</v-icon>
                <v-icon v-else-if="m == false" dark>mdi-close</v-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="xaxis">
          <div v-for="(d, i) in this.selectedReport.xAxis" :key="`d${i}`">
            <p>
              {{ d.day }} <br />
              {{ d.date }}
            </p>
          </div>
        </div>
      </section>
    </v-card>
  </v-container>
</template>
<script>
import { getWeeklySensorData } from '../../progress/routines';

export default {
  name: 'ReportMeal',
  props: {
    selectedReport: Object,
  },
  data() {
    return {
      viewId: '015',
      loaded: false,
      yAxis: [],
      reportData: [],
    };
  },
  computed: {
    mealsDescription() {
      if (this.loaded === false) {
        return this.$i18n.t('reports.loading');
      }
      const avgMeal = this.calculateMealAverage(this.reportData);
      if (avgMeal === null) {
        return this.$i18n.t('reports.noData');
      }
      return this.$i18n.t('reports.mealsLoggedHtml', {
        mealsPercentage: avgMeal,
      });
    },
  },
  methods: {
    calculateMealAverage(arr) {
      if (!arr) return null;
      const flat = arr.flat();
      const trueValues = flat.filter((value) => value === true);
      const avg = trueValues.length ? trueValues.length / flat.length : 0;
      return avg ? Math.round(avg * 100) : 0;
    },
    mapMeals(meals) {
      if (!meals || !Array.isArray(meals)) {
        return [];
      }

      return meals.map((m) => {
        if (m && m[0] && m[0].value !== undefined) {
          return m[0].value;
        }
        return null;
      });
    },
  },
  async mounted() {
    const breakfastData = await getWeeklySensorData(
      this.$store,
      this.$router,
      this.viewId,
      `meal-breakfast`,
      this.selectedReport.week,
    );
    const breakfast = this.mapMeals(breakfastData);

    const lunchData = await getWeeklySensorData(
      this.$store,
      this.$router,
      this.viewId,
      `meal-lunch`,
      this.selectedReport.week,
    );
    const lunch = this.mapMeals(lunchData);

    const dinnerData = await getWeeklySensorData(
      this.$store,
      this.$router,
      this.viewId,
      `meal-dinner`,
      this.selectedReport.week,
    );
    const dinner = this.mapMeals(dinnerData);

    this.reportData = [breakfast, lunch, dinner];

    this.loaded = true;
  },
};
</script>
<style lang="scss" scoped>
.subtitle {
  font-size: larger;
}

.graph {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.meals {
  display: flex;
  flex: 1;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    margin-bottom: 10px;
    > div:nth-child(odd) {
      background: #56369a;
    }
  }

  .yaxis {
    min-width: 80px;
    > div {
      flex: 1;
      display: flex;
      align-items: center;
      padding-left: 10px;
    }
  }

  .checks-content {
    display: flex;
    flex: 6;
    > div {
      padding: 10px 0;
      display: flex;
    }
    .check {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      width: 100%;
      max-width: 38px;
      height: 38px;
      margin: auto;
      background: #301d59;
      border-radius: 100%;
      @media (max-width: 600px) {
        max-width: 25px;
        height: 25px;
      }
    }
    .checked {
      background: #5db6bf !important;
    }
    .unchecked {
      background: #9b6ed4 !important;
    }
  }
}

.xaxis {
  display: flex;
  text-align: center;
  padding-left: 80px;
  width: 100%;
  > div {
    position: relative;
    flex: 1;
  }
  p {
    margin-bottom: 0;
  }
}
</style>
