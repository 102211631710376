var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          staticClass: "mgl-hero d-flex align-center justify-center",
          attrs: { flat: "", color: "primary" },
        },
        [_c("Logo")],
        1
      ),
      _c("SimpleLocalePicker", { staticClass: "mgl-simple-locale" }),
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-row",
            { staticClass: "mt-1" },
            [
              _c(
                "v-col",
                { staticClass: "col-md-6 offset-md-3 mt-6" },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pb-10 mb-10",
                      attrs: { color: "primaryDark" },
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "pa-5 d-flex align-center justify-center",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("resetPassword.setNewPassword")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "pa-5 d-flex align-center justify-center text-center",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("resetPassword.fill")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "v-form",
                        {
                          model: {
                            value: _vm.isPasswordFormValid,
                            callback: function ($$v) {
                              _vm.isPasswordFormValid = $$v
                            },
                            expression: "isPasswordFormValid",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mx-5" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    top: "",
                                    color: "secondary",
                                    "allow-overflow": "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                ref: "newPassword",
                                                staticClass:
                                                  "mgl-append-green mb-4",
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "changePassword.newPassword"
                                                  ),
                                                  outlined: "",
                                                  rounded: "",
                                                  color: "secondary",
                                                  type: "password",
                                                  "prepend-inner-icon":
                                                    "mdi-lock-outline",
                                                  validateOnInput: "",
                                                  rules: [
                                                    _vm.rules.required,
                                                    _vm.rules.minLength,
                                                    _vm.rules.uppercase,
                                                    _vm.rules.lowercase,
                                                    _vm.rules.symbol,
                                                    _vm.rules.number,
                                                    _vm.rules.writableSymbols,
                                                  ],
                                                  required: "",
                                                  "hide-details": "",
                                                  "append-icon":
                                                    _vm.allCriteriaFullfilled
                                                      ? "mdi-check"
                                                      : "",
                                                },
                                                model: {
                                                  value: _vm.newPassword,
                                                  callback: function ($$v) {
                                                    _vm.newPassword = $$v
                                                  },
                                                  expression: "newPassword",
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.showTooltip,
                                    callback: function ($$v) {
                                      _vm.showTooltip = $$v
                                    },
                                    expression: "showTooltip",
                                  },
                                },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "mgl-pwd-list my-2" },
                                    _vm._l(
                                      _vm.passwordCriterias,
                                      function (criteria) {
                                        return _c(
                                          "li",
                                          {
                                            key: criteria.id,
                                            staticClass:
                                              "d-flex align-center mb-1",
                                          },
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  small: "",
                                                  color: criteria.fulfilled
                                                    ? "success"
                                                    : "white",
                                                  outlined: !criteria.fulfilled,
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          criteria.fulfilled,
                                                        expression:
                                                          "criteria.fulfilled",
                                                      },
                                                    ],
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("mdi-check")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(
                                              " " + _vm._s(criteria.text) + " "
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                              _c("v-text-field", {
                                ref: "repeatPassword",
                                staticClass: "mgl-append-green mb-4",
                                attrs: {
                                  placeholder: _vm.$t(
                                    "changePassword.repeatPassword"
                                  ),
                                  outlined: "",
                                  rounded: "",
                                  color: "secondary",
                                  name: "repeatPassword",
                                  "prepend-inner-icon": "mdi-lock-outline",
                                  type: "password",
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.matchPassword,
                                  ],
                                  required: "",
                                  validateOnInput: "",
                                  "hide-details": "",
                                  "append-icon": _vm.passwordMatch
                                    ? "mdi-check"
                                    : "",
                                },
                                model: {
                                  value: _vm.repeatPassword,
                                  callback: function ($$v) {
                                    _vm.repeatPassword =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "repeatPassword",
                                },
                              }),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-2",
                                  attrs: {
                                    rounded: "",
                                    depressed: "",
                                    block: "",
                                    "x-large": "",
                                    color: "secondary",
                                    disabled: !_vm.isPasswordFormValid,
                                  },
                                  on: { click: _vm.setPassword },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("account.changePassword"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-footer",
        { staticClass: "text-center mt-15", attrs: { color: "background" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("resetPassword.needHelp")) + " "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "pl-2",
                        attrs: { href: "mailto:support@mindforcegamelab.com" },
                      },
                      [
                        _c("u", [
                          _vm._v(_vm._s(_vm.$t("resetPassword.contact"))),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }