var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px", "content-class": "elevation-0" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "button",
                    _vm._g(
                      {
                        staticStyle: {
                          "border-radius": "50%",
                          width: "20px",
                          height: "20px",
                          "padding-top": "2px",
                          "padding-right": "1px",
                          margin: "10px",
                          background: "transparent",
                          border: "1px solid grey",
                          color: "grey",
                          "text-align": "center",
                          font: "12px Arial, sans-serif",
                          "text-decoration-color": "#c6c4c8",
                          position: "absolute",
                          right: "0",
                        },
                      },
                      on
                    ),
                    [_vm._v(" ? ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialogOpen,
            callback: function ($$v) {
              _vm.dialogOpen = $$v
            },
            expression: "dialogOpen",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                padding: "50px 10px 20px 10px",
                "background-color": "transparent",
                overflow: "hidden",
              },
            },
            [
              _c(
                "v-card",
                {
                  staticStyle: {
                    "border-radius": "20px",
                    "text-align": "center",
                    "vertical-align": "top",
                    display: "inline-block",
                    width: "100%",
                    "background-color": "transparent",
                  },
                  attrs: { color: "primary" },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.dialogOpen = false
                        },
                      },
                    },
                    [_vm._v("X")]
                  ),
                  _c(
                    "v-card-title",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center",
                      },
                    },
                    [
                      _c(
                        "h3",
                        {
                          staticStyle: {
                            "padding-bottom": "20px",
                            "text-align": "center",
                            "word-break": "break-word",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("PHQ9.severityScore")) + " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "5px 0px 5px 0px",
                        "margin-left": "10px",
                        "margin-right": "10px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            "justify-content": "flex-start",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                margin: "0px",
                                display: "flex",
                                flex: "1 1 0px",
                              },
                            },
                            [
                              _c("b", [
                                _vm._v(_vm._s(_vm.$t("PHQ9.phq9score"))),
                              ]),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                display: "flex",
                                flex: "2 1 0px",
                                "font-weight": "400",
                                margin: "0px",
                              },
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("PHQ9.depressionSymptoms")) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "5px 0px 5px 20px",
                        "background-color": "#5e3aab",
                        "margin-left": "10px",
                        "margin-right": "10px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "5px 0px 5px 0px",
                            display: "flex",
                            "flex-direction": "row",
                            "justify-content": "flex-start",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                display: "flex",
                                flex: "1 1 0px",
                                margin: "0px",
                              },
                            },
                            [_c("b", [_vm._v("0 - 4")])]
                          ),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                display: "flex",
                                flex: "2 1 0px",
                                "font-weight": "400",
                                margin: "0px",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("PHQ9.none")) + " ")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "5px 0px 5px 20px",
                        "margin-left": "10px",
                        "margin-right": "10px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "5px 0px 5px 0px",
                            display: "flex",
                            "flex-direction": "row",
                            "justify-content": "flex-start",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                display: "flex",
                                flex: "1 1 0px",
                                margin: "0px",
                              },
                            },
                            [_c("b", [_vm._v("5 - 9")])]
                          ),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                display: "flex",
                                flex: "2 1 0px",
                                "font-weight": "400",
                                margin: "0px",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("PHQ9.mild")) + " ")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "5px 0px 5px 20px",
                        "background-color": "#5e3aab",
                        "margin-left": "10px",
                        "margin-right": "10px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "5px 0px 5px 0px",
                            display: "flex",
                            "flex-direction": "row",
                            "justify-content": "flex-start",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                display: "flex",
                                flex: "1 1 0px",
                                margin: "0px",
                              },
                            },
                            [_c("b", [_vm._v("10 - 14")])]
                          ),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                display: "flex",
                                flex: "2 1 0px",
                                "font-weight": "400",
                                margin: "0px",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("PHQ9.moderate")) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "5px 0px 5px 20px",
                        "margin-left": "10px",
                        "margin-right": "10px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "5px 0px 5px 0px",
                            display: "flex",
                            "flex-direction": "row",
                            "justify-content": "flex-start",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                display: "flex",
                                flex: "1 1 0px",
                                margin: "0px",
                              },
                            },
                            [_c("b", [_vm._v("15 - 19")])]
                          ),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                display: "flex",
                                flex: "2 1 0px",
                                "font-weight": "400",
                                margin: "0px",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("PHQ9.moderateSevere")) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        padding: "5px 0px 5px 20px",
                        "background-color": "#5e3aab",
                        "margin-left": "10px",
                        "margin-right": "10px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "5px 0px 5px 0px",
                            display: "flex",
                            "flex-direction": "row",
                            "justify-content": "flex-start",
                          },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                display: "flex",
                                flex: "1 1 0px",
                                margin: "0px",
                              },
                            },
                            [_c("b", [_vm._v("20 - 27")])]
                          ),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                display: "flex",
                                flex: "2 1 0px",
                                "font-weight": "400",
                                margin: "0px",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("PHQ9.severe")) + " ")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "font-weight": "400",
                        "max-width": "500px",
                        "word-break": "break-word",
                        "margin-top": "20px",
                        "margin-bottom": "30px",
                        padding: "20px",
                        "text-align": "left",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("PHQ9.ifQuestion")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }