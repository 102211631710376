<template>
  <div v-if="isLoggedIn">
    <v-container fluid fill-height class="pb-0">
      <!-- Header -->
      <v-row>
        <v-col class="text-center pb-0">
          <h2>{{ isPlaytient ? $t('team.header') : $t('team.headerTeams') }}</h2>
        </v-col>
      </v-row>
      <!-- Description -->
      <v-row>
        <v-col
          v-if="isPlaytient"
          v-html="$t('team.descriptionHtml')"
          class="text-center col-10 offset-1 col-md-4 offset-md-4"
        />
        <v-col
          v-else
          v-html="$t('team.descriptionHtmlTeams')"
          class="text-center col-10 offset-1 col-md-4 offset-md-4"
        />
      </v-row>
      <!-- Playtient team -->
      <v-row v-if="isPlaytient">
        <v-col class="mb-2 text-center" v-if="team.length < 1">
          {{ $t('team.noRelations') }}
        </v-col>
        <v-col v-for="(user, i) in team" :key="i" class="col-md-4 offset-md-4 pb-8">
          <v-card text rounded color="primaryDark" flat>
            <v-toolbar rounded flat color="primary" :key="user.id" class="mb-2">
              <v-toolbar-title class="subtitle-1 font-weight-bold">
                {{ user.nickname }}
              </v-toolbar-title>
              <v-spacer />
              <v-menu bottom close-on-click transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon large>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list tile class="mgl-menu-list py-0" color="primary">
                  <template v-for="(item, index) in teamMenuItems">
                    <v-list-item :key="index" @click="item.method(user)">
                      <v-list-item-title :class="item.class">{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </v-toolbar>
            <v-card-text>
              <span>{{ user.email }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="isPlaytient">
        <v-col v-for="(invite, i) in invites" :key="i" class="col-md-4 offset-md-4 pb-8">
          <v-card text rounded color="primaryDark" flat>
            <v-toolbar rounded flat color="primary" :key="invite.id" class="mb-2">
              <v-toolbar-title style="color: #c9a4ea">({{ $t('team.pending') }})</v-toolbar-title>
              <v-spacer />
              <v-menu bottom close-on-click transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon large>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list tile class="mgl-menu-list py-0" color="primary">
                  <template v-for="(item, index) in inviteMenuItems">
                    <v-list-item :key="index" @click="item.method(invite)">
                      <v-list-item-title :class="item.class">{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </v-toolbar>
            <v-card-text>
              <span>{{ invite.email }}</span>
              <br />
              <span>Expires in: {{ getDaysDifference(invite.expires) }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Invite team member dialog -->
      <v-row v-if="isPlaytient">
        <v-col
          class="col-md-4 offset-md-4"
          style="display: flex; flex-direction: row; justify-content: space-around"
        >
          <v-dialog v-model="showDialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="width: 350px"
                rounded
                depressed
                x-large
                color="secondary"
                class="mb-5"
                v-bind="attrs"
                v-on="on"
                >{{ $t('team.inviteTeamMember') }}</v-btn
              >
            </template>
            <v-card text color="primaryDark" flat class="text-center pa-5">
              <h2 class="py-2">{{ $t('team.inviteTeamMember') }}</h2>
              <div>
                <v-form @submit.prevent="inviteTeamMember" ref="teamForm">
                  <v-text-field
                    :placeholder="$t('team.teamMemberEmail')"
                    outlined
                    rounded
                    color="secondary"
                    type="text"
                    v-model="tempEmail"
                    validate-on-blur
                    :rules="[rules.email]"
                    @input="
                      tempEmail =
                        tempEmail !== null ? tempEmail.replace(/\s+/g, '').toLowerCase() : ''
                    "
                    @keydown.space.prevent=""
                    @paste="handlePaste"
                  ></v-text-field>
                  <v-textarea
                    :placeholder="$t('team.inviteMessage')"
                    color="secondary"
                    filled
                    v-model.trim="inviteMessage"
                    validate-on-blur
                    counter="100"
                    maxLength="100"
                  ></v-textarea>
                  <p v-html="$t('team.InivteEmailWarningHtml')" />
                  <v-btn
                    style="width: 300px; margin: auto"
                    rounded
                    depressed
                    x-large
                    color="secondary"
                    class="mb-5 mt-5"
                    @click="inviteTeamMember"
                    >{{ $t('team.sendInvite') }}</v-btn
                  >
                </v-form>
              </div>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <!-- Team: members teams -->
      <v-row>
        <v-col class="col-md-4 offset-md-4 pb-0">
          <h3 class="mb-2 text-center">
            {{ $t('team.relationsTeams') }}
          </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center col-md-4 offset-md-4 pb-0">
          {{ $t('team.relationsTeamsDescription') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="(relation, i) in teams" :key="i" class="col-md-4 offset-md-4 pb-8">
          <v-card text rounded color="primaryDark" flat>
            <v-toolbar rounded flat color="primary" :key="relation.id" class="mb-2">
              <v-toolbar-title class="subtitle-1 font-weight-bold">{{
                $t('team.playtientsTeam', [relation.nickname])
              }}</v-toolbar-title>
              <v-card-actions v-if="relation.issuerId" class="pa-5">
                <v-btn color="secondary" rounded @click="acceptInvite(relation)">{{
                  $t('team.acceptInvite')
                }}</v-btn>
              </v-card-actions>
              <v-spacer />
              <v-menu
                v-if="!relation.issuerId"
                bottom
                close-on-click
                transition="slide-y-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon large>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list tile class="mgl-menu-list py-0" color="primary">
                  <template v-for="(item, index) in teamsMenuItems">
                    <v-list-item :key="index" @click="item.method(relation)">
                      <v-list-item-title :class="item.class">{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </v-toolbar>
            <v-card-text>
              <span>{{ relation.email }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="mb-2 text-center grey--text" v-if="teams.length === 0">
          {{ $t('team.noRelationsTeams') }}
        </v-col>
      </v-row>
    </v-container>
    <!-- Onboarding for team -->
    <div v-if="isPlaytient" class="container">
      <v-row>
        <v-col
          class="col-md-4 offset-md-4"
          style="display: flex; flex-direction: row; justify-content: space-around"
        >
          <v-btn
            style="margin: auto; width: 350px"
            color="secondary"
            large
            rounded
            depressed
            v-if="showDoneButton"
            class="mb-5"
            @click="doneWithTeam"
            >{{ team.length > 0 ? $t('team.doneWithTeam') : $t('team.completeStep')
            }}<v-icon class="pl-2">mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { FIG_APPID, MGL_ORGANIZATION_ID } from '../utils/constants';
import { setLoading, displayMessage, displayError } from '../utils/loading';
import { addDaysToDate, daysBetweenDates } from '../utils/datetime';
import { apiDispatch } from '../utils/dispatch';
import { getPastedData } from '../utils/clipboard';
import { apiGetTeams, apiGetTeam } from '../api/users';
import { postEvent } from '../api/unityAnalytics';

export default {
  name: 'Team',
  data() {
    return {
      viewId: '008',
      showDialog: false,
      tempEmail: '',
      team: [],
      teams: [],
      invites: [],
      inviteMessage: '',
      teamsMenuItems: [
        {
          title: this.$i18n.t('team.leave'),
          id: 'removeTeams',
          class: 'text-center px-5 red--text text--lighten-2',
          method: async (playtient) => {
            return this.leavePlaytientsTeam(playtient);
          },
        },
      ],
      inviteMenuItems: [
        {
          title: this.$i18n.t('generic.remove'),
          id: 'cancelInvitation',
          class: 'text-center px-5 red--text text--lighten-2',
          method: async (invite) => {
            return this.cancelInvitation(invite);
          },
        },
      ],
      teamMenuItems: [
        {
          title: this.$i18n.t('generic.remove'),
          id: 'remove',
          class: 'text-center px-5 red--text text--lighten-2',
          method: async (teamMember) => {
            return this.removeTeamMember(teamMember);
          },
        },
      ],
      rules: {
        email: (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
            v,
          ) || this.$i18n.t('validation.emailInvalid'),
      },
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.api.me !== null;
    },
    showDoneButton() {
      return !this.$store.state.api.me.onboarded;
    },
    numTeam() {
      return this.team.length;
    },
    userTypeToRole() {
      return this.$store.state.api.me.type !== 'Playtient' ? 'friend' : 'playtient';
    },
    isPlaytient() {
      const { type } = this.$store.state.api.me;
      return type === 'Playtient' || type === 'MGL';
    },
    isPartOfAnyTeams() {
      if (!this.isPlaytient) {
        return true;
      }
      return this.teams.length > 0;
    },
  },
  methods: {
    handlePaste(event) {
      const formattedText = getPastedData(event);
      if (formattedText !== null) {
        this.tempEmail = formattedText;
      }
      event.preventDefault();
    },
    getDaysDifference(expiry) {
      const daysDiff = daysBetweenDates(new Date(), new Date(expiry));
      if (daysDiff < 1) {
        return this.$i18n.t('invites.expiresToday');
      }
      return `${daysDiff} ${this.$i18n.t('generic.days')}`;
    },
    async removeTeamMember(teamMember) {
      const confirm = await this.$root.$confirm(
        this.$i18n.t('generic.areYouSure'),
        this.$i18n.t('team.removeTeamMemberConfirmationHtml', [
          teamMember.nickname,
          teamMember.email,
        ]),
        {
          asHtml: true,
          width: 400,
          disableCancel: false,
          actionButtonI18nKey: 'generic.yes',
        },
      );
      if (confirm) {
        const payload = {
          memberId: teamMember.id,
          viewId: this.viewId,
        };
        // Delete team member
        setLoading(this.$store, true, 'team.title', 'team.deleting');
        try {
          await apiDispatch(this.$store, this.$router, 'deleteTeamMember', payload);
          await postEvent(this.$store, 'TeamLeft', {
            teamId: this.$store.state.api.me.id,
          });
          displayMessage(this.$store, this.$i18n.t('team.removeTeamMemberSuccess'), 'success');
          this.team.splice(this.team.indexOf(teamMember), 1);
        } catch (error) {
          displayError(this.$store, error);
        }
      }
    },
    async inviteTeamMember() {
      if (this.rules.email(this.tempEmail) === true) {
        this.showDialog = false;
        setLoading(this.$store, true, 'invites.title', 'invites.sending');
        // Setting expiredate to 7 days from now
        const expireDate = addDaysToDate(new Date(), 7);
        const payload = {
          invite: {
            email: this.tempEmail,
            message: this.inviteMessage,
            organizationId: MGL_ORGANIZATION_ID, // MGL developers
            appId: FIG_APPID,
            role: this.userTypeToRole,
            expires: expireDate.toISOString(),
            viewId: this.viewId,
          },
          viewId: this.viewId,
        };

        // Send the invite
        try {
          const invite = await apiDispatch(this.$store, this.$router, 'postInvite', payload);
          this.invites.push(invite);
          // Send event to Unity Analytics on first invite sent
          if (
            this.$store.state.api.invites.length === 1 &&
            this.$store.state.api.me.onboarded === false
          ) {
            await postEvent(this.$store, 'OnboardingStepCompleted', {
              onboardingStep: 'team',
              stepSkipped: false,
            });
          }
          setLoading(this.$store, false);
          this.$refs.teamForm.reset();
        } catch (error) {
          displayError(this.$store, error);
        }
      }
    },
    async cancelInvitation(invite) {
      const confirm = await this.$root.$confirm(
        this.$i18n.t('team.cancelInvitationHeader'),
        this.$i18n.t('team.cancelInvitationConfirmationHtml', ['', invite.email]),
        {
          asHtml: true,
          width: 400,
          disableCancel: false,
          actionButtonI18nKey: 'generic.yes',
        },
      );
      if (confirm) {
        console.log('Cancelling invitation to playtient team...');
        setLoading(this.$store, true, 'team.title', 'team.deleting');
        try {
          await apiDispatch(this.$store, this.$router, 'deleteInvite', {
            inviteId: invite.id,
            viewId: this.viewId,
          });
          setLoading(this.$store, false);
          displayMessage(this.$store, this.$i18n.t('team.removeTeamMemberSuccess'), 'success');
          // Update invites array!
          this.invites.splice(this.invites.indexOf(invite), 1);
        } catch (error) {
          displayError(this.$store, error);
        }
      }
    },
    async leavePlaytientsTeam(playtient) {
      const confirm = await this.$root.$confirm(
        this.$i18n.t('team.leaveConfirmHeader'),
        this.$i18n.t('team.leaveConfirmBodyHtml'),
        {
          asHtml: true,
          disableCancel: false,
          actionButtonI18nKey: 'generic.yes',
        },
      );
      if (!confirm) {
        return;
      }
      console.log('Removing user from playtient team...');
      setLoading(this.$store, true, 'team.title', 'team.deletingTeam');
      try {
        await apiDispatch(this.$store, this.$router, 'deleteMeFromTeam', {
          playtientId: playtient.id,
          viewId: this.viewId,
        });
        await postEvent(this.$store, 'TeamLeft', {
          teamId: playtient.id,
        });
        setLoading(this.$store, false);
        displayMessage(
          this.$store,
          this.$i18n.t('team.removeMeSuccess', [playtient.nickname]),
          'success',
        );
        // Update teams array!
        this.teams = this.teams.filter((team) => {
          return team.id !== playtient.id;
        });
      } catch (error) {
        displayError(this.$store, error);
      }
    },
    async doneWithTeam() {
      this.$store.commit('setDoneWithTeam', true);
      displayMessage(this.$store, this.$i18n.t('team.done'), 'success');
      if (this.invites.length === 0 && this.$store.state.api.me.onboarded === false) {
        await postEvent(this.$store, 'OnboardingStepCompleted', {
          onboardingStep: 'team',
          stepSkipped: true,
        });
      }
      this.$router.push({ name: 'home' });
    },
    async acceptInvite(inviteItem) {
      console.log('Accepting invite...');
      setLoading(this.$store, true, 'team.title', 'team.accepting');
      try {
        // eslint-disable-next-line no-param-reassign
        inviteItem.viewId = this.viewId;
        await apiDispatch(this.$store, this.$router, 'acceptInvite', inviteItem);
        displayMessage(
          this.$store,
          this.$i18n.t('team.acceptSuccess', [inviteItem.nickname]),
          'success',
        );
        // Update item in list!
        this.teams = this.teams.map((team) => {
          if (team.issuerId === inviteItem.issuerId) {
            const { nickname, email, role, issuerId } = inviteItem;
            return { id: issuerId, nickname, email, role };
          }
          return team;
        });
        await postEvent(this.$store, 'TeamJoined', {
          teamId: inviteItem.issuerId,
        });
        setLoading(this.$store, false);
      } catch (error) {
        displayError(this.$store, error);
      }
    },
  },
  async mounted() {
    const payload = {
      organizationId: MGL_ORGANIZATION_ID, // MGL developers
      appId: FIG_APPID, // Hard setting to Fig: a playtient journey for now
      viewId: this.viewId,
    };

    if (this.isPlaytient) {
      setLoading(this.$store, true, 'team.title', 'team.checking');
      // TODO: Use cached team & invites objects here to avoid loading
      const entries = await apiGetTeam(this.$store, this.$router, payload);
      // @ts-ignore
      this.team = entries.filter((e) => e.publicKey);
      // @ts-ignore
      this.invites = entries.filter((e) => e.issuerId);
      setLoading(this.$store, false);
    }
    setLoading(this.$store, true, 'team.title', 'team.checkingTeams');
    this.teams = await apiGetTeams(this.$store, this.$router, payload, true);
    setLoading(this.$store, false);
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-30px);
}
</style>
