var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c(
        "v-overlay",
        [
          _c(
            "v-card",
            {
              staticClass:
                "d-flex flex-column align-center justify-space-between",
              attrs: {
                color: _vm.loadingTitle ? "primary" : "transparent",
                elevation: _vm.loadingTitle ? 10 : 0,
                rounded: "",
                "min-width": "200",
              },
            },
            [
              _c("v-card-title", { attrs: { "primary-title": "" } }, [
                _c("div", [
                  _c(
                    "h3",
                    { staticClass: "subtitle-1 ma-3 mgl-no-wordbreak" },
                    [_vm._v(_vm._s(_vm.loadingTitle))]
                  ),
                ]),
              ]),
              _c("v-progress-circular", {
                attrs: { color: "secondary", indeterminate: "", size: "64" },
              }),
              _vm.loadingTitle
                ? _c("v-card-text", { staticClass: "ma-3" }, [
                    _c("div", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.loadingText)),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }