var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      style: { background: _vm.$vuetify.theme.themes[_vm.theme].background },
      attrs: { id: "app" },
    },
    [
      _c("meta", {
        attrs: {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
        },
      }),
      _vm.isLoggedIn ? _c("TopNavBar") : _vm._e(),
      _c("v-main", [_c("router-view"), _c("SnackBar")], 1),
      _c("LoadingOverlay"),
      _c("Confirm", { ref: "confirm" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }