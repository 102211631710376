var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoggedIn
    ? _c(
        "div",
        [
          _c(
            "v-container",
            { staticClass: "pb-0", attrs: { fluid: "", "fill-height": "" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "text-center pb-0" }, [
                    _c("h2", [
                      _vm._v(
                        _vm._s(
                          _vm.isPlaytient
                            ? _vm.$t("team.header")
                            : _vm.$t("team.headerTeams")
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _vm.isPlaytient
                    ? _c("v-col", {
                        staticClass:
                          "text-center col-10 offset-1 col-md-4 offset-md-4",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("team.descriptionHtml")),
                        },
                      })
                    : _c("v-col", {
                        staticClass:
                          "text-center col-10 offset-1 col-md-4 offset-md-4",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("team.descriptionHtmlTeams")
                          ),
                        },
                      }),
                ],
                1
              ),
              _vm.isPlaytient
                ? _c(
                    "v-row",
                    [
                      _vm.team.length < 1
                        ? _c("v-col", { staticClass: "mb-2 text-center" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("team.noRelations")) + " "
                            ),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.team, function (user, i) {
                        return _c(
                          "v-col",
                          { key: i, staticClass: "col-md-4 offset-md-4 pb-8" },
                          [
                            _c(
                              "v-card",
                              {
                                attrs: {
                                  text: "",
                                  rounded: "",
                                  color: "primaryDark",
                                  flat: "",
                                },
                              },
                              [
                                _c(
                                  "v-toolbar",
                                  {
                                    key: user.id,
                                    staticClass: "mb-2",
                                    attrs: {
                                      rounded: "",
                                      flat: "",
                                      color: "primary",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-toolbar-title",
                                      {
                                        staticClass:
                                          "subtitle-1 font-weight-bold",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(user.nickname) + " "
                                        ),
                                      ]
                                    ),
                                    _c("v-spacer"),
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          bottom: "",
                                          "close-on-click": "",
                                          transition: "slide-y-transition",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        { attrs: { icon: "" } },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { large: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-dots-vertical"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          {
                                            staticClass: "mgl-menu-list py-0",
                                            attrs: {
                                              tile: "",
                                              color: "primary",
                                            },
                                          },
                                          [
                                            _vm._l(
                                              _vm.teamMenuItems,
                                              function (item, index) {
                                                return [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      key: index,
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return item.method(
                                                            user
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        { class: item.class },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.title)
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-card-text", [
                                  _c("span", [_vm._v(_vm._s(user.email))]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.isPlaytient
                ? _c(
                    "v-row",
                    _vm._l(_vm.invites, function (invite, i) {
                      return _c(
                        "v-col",
                        { key: i, staticClass: "col-md-4 offset-md-4 pb-8" },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                text: "",
                                rounded: "",
                                color: "primaryDark",
                                flat: "",
                              },
                            },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  key: invite.id,
                                  staticClass: "mb-2",
                                  attrs: {
                                    rounded: "",
                                    flat: "",
                                    color: "primary",
                                  },
                                },
                                [
                                  _c(
                                    "v-toolbar-title",
                                    { staticStyle: { color: "#c9a4ea" } },
                                    [
                                      _vm._v(
                                        "(" +
                                          _vm._s(_vm.$t("team.pending")) +
                                          ")"
                                      ),
                                    ]
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        bottom: "",
                                        "close-on-click": "",
                                        transition: "slide-y-transition",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { icon: "" } },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { large: "" } },
                                                      [
                                                        _vm._v(
                                                          "mdi-dots-vertical"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        {
                                          staticClass: "mgl-menu-list py-0",
                                          attrs: { tile: "", color: "primary" },
                                        },
                                        [
                                          _vm._l(
                                            _vm.inviteMenuItems,
                                            function (item, index) {
                                              return [
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    key: index,
                                                    on: {
                                                      click: function ($event) {
                                                        return item.method(
                                                          invite
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      { class: item.class },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.title)
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-card-text", [
                                _c("span", [_vm._v(_vm._s(invite.email))]),
                                _c("br"),
                                _c("span", [
                                  _vm._v(
                                    "Expires in: " +
                                      _vm._s(
                                        _vm.getDaysDifference(invite.expires)
                                      )
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm.isPlaytient
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "col-md-4 offset-md-4",
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            "justify-content": "space-around",
                          },
                        },
                        [
                          _c(
                            "v-dialog",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mb-5",
                                                staticStyle: { width: "350px" },
                                                attrs: {
                                                  rounded: "",
                                                  depressed: "",
                                                  "x-large": "",
                                                  color: "secondary",
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("team.inviteTeamMember")
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3652056133
                              ),
                              model: {
                                value: _vm.showDialog,
                                callback: function ($$v) {
                                  _vm.showDialog = $$v
                                },
                                expression: "showDialog",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "text-center pa-5",
                                  attrs: {
                                    text: "",
                                    color: "primaryDark",
                                    flat: "",
                                  },
                                },
                                [
                                  _c("h2", { staticClass: "py-2" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("team.inviteTeamMember"))
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          ref: "teamForm",
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              return _vm.inviteTeamMember(
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "team.teamMemberEmail"
                                              ),
                                              outlined: "",
                                              rounded: "",
                                              color: "secondary",
                                              type: "text",
                                              "validate-on-blur": "",
                                              rules: [_vm.rules.email],
                                            },
                                            on: {
                                              input: function ($event) {
                                                _vm.tempEmail =
                                                  _vm.tempEmail !== null
                                                    ? _vm.tempEmail
                                                        .replace(/\s+/g, "")
                                                        .toLowerCase()
                                                    : ""
                                              },
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "space",
                                                    32,
                                                    $event.key,
                                                    [" ", "Spacebar"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                              },
                                              paste: _vm.handlePaste,
                                            },
                                            model: {
                                              value: _vm.tempEmail,
                                              callback: function ($$v) {
                                                _vm.tempEmail = $$v
                                              },
                                              expression: "tempEmail",
                                            },
                                          }),
                                          _c("v-textarea", {
                                            attrs: {
                                              placeholder:
                                                _vm.$t("team.inviteMessage"),
                                              color: "secondary",
                                              filled: "",
                                              "validate-on-blur": "",
                                              counter: "100",
                                              maxLength: "100",
                                            },
                                            model: {
                                              value: _vm.inviteMessage,
                                              callback: function ($$v) {
                                                _vm.inviteMessage =
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                              },
                                              expression: "inviteMessage",
                                            },
                                          }),
                                          _c("p", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.$t(
                                                  "team.InivteEmailWarningHtml"
                                                )
                                              ),
                                            },
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mb-5 mt-5",
                                              staticStyle: {
                                                width: "300px",
                                                margin: "auto",
                                              },
                                              attrs: {
                                                rounded: "",
                                                depressed: "",
                                                "x-large": "",
                                                color: "secondary",
                                              },
                                              on: {
                                                click: _vm.inviteTeamMember,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("team.sendInvite")
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "col-md-4 offset-md-4 pb-0" }, [
                    _c("h3", { staticClass: "mb-2 text-center" }, [
                      _vm._v(" " + _vm._s(_vm.$t("team.relationsTeams")) + " "),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center col-md-4 offset-md-4 pb-0" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("team.relationsTeamsDescription")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _vm._l(_vm.teams, function (relation, i) {
                    return _c(
                      "v-col",
                      { key: i, staticClass: "col-md-4 offset-md-4 pb-8" },
                      [
                        _c(
                          "v-card",
                          {
                            attrs: {
                              text: "",
                              rounded: "",
                              color: "primaryDark",
                              flat: "",
                            },
                          },
                          [
                            _c(
                              "v-toolbar",
                              {
                                key: relation.id,
                                staticClass: "mb-2",
                                attrs: {
                                  rounded: "",
                                  flat: "",
                                  color: "primary",
                                },
                              },
                              [
                                _c(
                                  "v-toolbar-title",
                                  {
                                    staticClass: "subtitle-1 font-weight-bold",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("team.playtientsTeam", [
                                          relation.nickname,
                                        ])
                                      )
                                    ),
                                  ]
                                ),
                                relation.issuerId
                                  ? _c(
                                      "v-card-actions",
                                      { staticClass: "pa-5" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "secondary",
                                              rounded: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.acceptInvite(
                                                  relation
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("team.acceptInvite")
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("v-spacer"),
                                !relation.issuerId
                                  ? _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          bottom: "",
                                          "close-on-click": "",
                                          transition: "slide-y-transition",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        { attrs: { icon: "" } },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { large: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-dots-vertical"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          {
                                            staticClass: "mgl-menu-list py-0",
                                            attrs: {
                                              tile: "",
                                              color: "primary",
                                            },
                                          },
                                          [
                                            _vm._l(
                                              _vm.teamsMenuItems,
                                              function (item, index) {
                                                return [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      key: index,
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return item.method(
                                                            relation
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        { class: item.class },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.title)
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("v-card-text", [
                              _c("span", [_vm._v(_vm._s(relation.email))]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _vm.teams.length === 0
                    ? _c(
                        "v-col",
                        { staticClass: "mb-2 text-center grey--text" },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("team.noRelationsTeams")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm.isPlaytient
            ? _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "col-md-4 offset-md-4",
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            "justify-content": "space-around",
                          },
                        },
                        [
                          _vm.showDoneButton
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mb-5",
                                  staticStyle: {
                                    margin: "auto",
                                    width: "350px",
                                  },
                                  attrs: {
                                    color: "secondary",
                                    large: "",
                                    rounded: "",
                                    depressed: "",
                                  },
                                  on: { click: _vm.doneWithTeam },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.team.length > 0
                                        ? _vm.$t("team.doneWithTeam")
                                        : _vm.$t("team.completeStep")
                                    )
                                  ),
                                  _c("v-icon", { staticClass: "pl-2" }, [
                                    _vm._v("mdi-arrow-right"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }