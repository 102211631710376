import { hasDefinedProperty } from './objects';
import { dateStrInTimeZone, isValidIANATimeZone } from './datetime';

const DATA_FIELD = 'data';
const USER_JOURNAL_MODULE_ID = '2551a4b3159739248a0276cc9ec13545';
const JOURNAL_FIELD_START_PLAYTIME = 'start_playtime';

const GLOBAL_MODULE_ID = 'global';
const GLOBAL_FIELD_BREAKFAST_TIME = 'BREAKFAST_TIME';
const GLOBAL_FIELD_LUNCH_TIME = 'LUNCH_TIME';
const GLOBAL_FIELD_DINNER_TIME = 'DINNER_TIME';
const GLOBAL_FIELD_BEDTIME = 'BEDTIME';
const GLOBAL_FIELD_TIMEZONE = 'TIME_ZONE_ID';
const GLOBAL_FIELD_DAILY_STEP_GOAL = 'DAILY_STEP_GOAL';

const INVENTORY_MODULE_ID = '83bedf7bea0547b428c9a13c24fa1493';
const INVENTORY_ITEMS_FIELD = 'Items';
const SLEEP_ROUTINE_ITEM_ID = 'c15df5adea1a46c45ab7dab0fb1d3f58';
const FOOD_ROUTINE_ITEM_ID = 'eafda1796ff7f1c46b8c193e5d0a298b';
const EXERCISE_ROUTINE_ITEM_ID = 'a6a2fbd3257fea54f82c4b0fe5d463c7';

const ITEM_FIELD_ADDED = 'Added';

const DEFAULT_TIME_ZONE = 'Europe/Stockholm';

/**
 * @typedef {{sleepUnlockDate?: string, foodUnlockDate?: string, exerciseUnlockDate?: string}} InventoryData
 * @typedef {{mealBreakfastTime?: string, mealLunchTime?: string, mealDinnerTime?: string, sleepBedTime?: string, timeZone?: string, stepGoal?:number}} GlobalData
 * @typedef {{playStart: string|null, global: GlobalData, inventory: InventoryData}} BasicRoutineData
 */

/**
 * Read data from global module
 * @param {object} globalData
 * @returns {GlobalData}
 */
function readGlobalData(globalData) {
  const data = {};
  if (hasDefinedProperty(globalData, GLOBAL_FIELD_BEDTIME)) {
    data.sleepBedTime = globalData[GLOBAL_FIELD_BEDTIME];
  }
  if (hasDefinedProperty(globalData, GLOBAL_FIELD_BREAKFAST_TIME)) {
    data.mealBreakfastTime = globalData[GLOBAL_FIELD_BREAKFAST_TIME];
  }
  if (hasDefinedProperty(globalData, GLOBAL_FIELD_LUNCH_TIME)) {
    data.mealLunchTime = globalData[GLOBAL_FIELD_LUNCH_TIME];
  }
  if (hasDefinedProperty(globalData, GLOBAL_FIELD_DINNER_TIME)) {
    data.mealDinnerTime = globalData[GLOBAL_FIELD_DINNER_TIME];
  }
  if (hasDefinedProperty(globalData, GLOBAL_FIELD_TIMEZONE)) {
    data.timeZone = parseTimeZone(globalData[GLOBAL_FIELD_TIMEZONE]);
  }
  if (hasDefinedProperty(globalData, GLOBAL_FIELD_DAILY_STEP_GOAL)) {
    data.stepGoal = parseInt(globalData[GLOBAL_FIELD_DAILY_STEP_GOAL], 10);
  }
  return data;
}

/**
 * Read data from inventory module
 * @param {object} inventoryData
 * @param {string} userTimezone
 * @returns {InventoryData}
 */
function readInventroyData(inventoryData, userTimezone) {
  const data = {};
  if (!hasDefinedProperty(inventoryData, INVENTORY_ITEMS_FIELD)) {
    return data;
  }
  const itemsData = inventoryData[INVENTORY_ITEMS_FIELD];
  if (hasDefinedProperty(itemsData, SLEEP_ROUTINE_ITEM_ID)) {
    data.sleepUnlockDate = readItemAdditionDateStr(itemsData[SLEEP_ROUTINE_ITEM_ID], userTimezone);
  }
  if (hasDefinedProperty(itemsData, FOOD_ROUTINE_ITEM_ID)) {
    data.foodUnlockDate = readItemAdditionDateStr(itemsData[FOOD_ROUTINE_ITEM_ID], userTimezone);
  }
  if (hasDefinedProperty(itemsData, EXERCISE_ROUTINE_ITEM_ID)) {
    data.exerciseUnlockDate = readItemAdditionDateStr(
      itemsData[EXERCISE_ROUTINE_ITEM_ID],
      userTimezone,
    );
  }
  return data;
}

/**
 * Reads addition timestamp from item
 * @param {{Count: number, Added?: string, Removed?: string}} item
 * @param {string} userTimezone
 * @returns {string|undefined}
 */
function readItemAdditionDateStr(item, userTimezone) {
  if (!hasDefinedProperty(item, ITEM_FIELD_ADDED)) {
    return undefined;
  }
  return dateStrInTimeZone(item[ITEM_FIELD_ADDED], userTimezone);
}

/**
 * Parses timezone string from savefile (.NET format) to IANA timezone string
 * All .NET timezones names are listed here:
 * https://learn.microsoft.com/en-us/windows-hardware/manufacture/desktop/default-time-zones?view=windows-11
 * IANA timezone names are listed here:
 * https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 * @param {string} timeZoneStr time zone string from savefile
 * @returns {string}
 */
function parseTimeZone(timeZoneStr) {
  if (!isValidIANATimeZone(timeZoneStr)) {
    console.warn(
      `Could not parse timezone ${timeZoneStr}, using default timezone ${DEFAULT_TIME_ZONE}`,
    );
    return DEFAULT_TIME_ZONE;
  }
  return timeZoneStr;
}

/**
 * Reads the timezone from savefile
 * @param {object} saveFileArr
 * @returns {string}
 */
export function readTimeZone(saveFileArr) {
  if (!saveFileArr.length) {
    console.warn(`Found no savefile. Using default time zone ${DEFAULT_TIME_ZONE}`);
    return DEFAULT_TIME_ZONE;
  }
  const { data } = saveFileArr[0];
  if (hasDefinedProperty(data, GLOBAL_MODULE_ID)) {
    const global = readGlobalData(data[GLOBAL_MODULE_ID]);
    const { timeZone } = global;
    if (timeZone) {
      return timeZone;
    }
  }
  console.warn(`Found no timezone in savefile, using default timezone ${DEFAULT_TIME_ZONE}`);
  return DEFAULT_TIME_ZONE;
}

/**
 * Reads basic routine data from savefile
 * @param {object} saveFile
 * @param {string} userTimezone
 * @returns {BasicRoutineData|null}
 */
export function readSavefileBasicRoutineData(saveFile, userTimezone) {
  if (!hasDefinedProperty(saveFile, DATA_FIELD)) {
    console.warn('No data field in savefile!');
    return null;
  }
  const { data } = saveFile;
  if (!hasDefinedProperty(data, USER_JOURNAL_MODULE_ID)) {
    console.warn('No journal module data in savefile!');
    return null;
  }
  const journalModuleData = data[USER_JOURNAL_MODULE_ID];
  let playStart = null;
  if (hasDefinedProperty(journalModuleData, JOURNAL_FIELD_START_PLAYTIME)) {
    const playStartStr = journalModuleData[JOURNAL_FIELD_START_PLAYTIME];
    playStart = dateStrInTimeZone(playStartStr, userTimezone);
  }
  const basicRoutineData = {
    playStart,
    global: {},
    inventory: {},
  };
  if (hasDefinedProperty(data, GLOBAL_MODULE_ID)) {
    basicRoutineData.global = readGlobalData(data[GLOBAL_MODULE_ID]);
  }
  if (hasDefinedProperty(data, INVENTORY_MODULE_ID)) {
    basicRoutineData.inventory = readInventroyData(data[INVENTORY_MODULE_ID], userTimezone);
  }
  return basicRoutineData;
}
