var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-slide-y-transition",
    [
      _c(
        "v-btn-toggle",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.onLoad,
              expression: "onLoad",
            },
          ],
          attrs: {
            rounded: "",
            mandatory: "",
            dense: "",
            color: "secondary",
            "background-color": "primary",
          },
          model: {
            value: _vm.languageToggle,
            callback: function ($$v) {
              _vm.languageToggle = $$v
            },
            expression: "languageToggle",
          },
        },
        _vm._l(_vm.languages, function (language, index) {
          return _c("v-btn", { key: index, attrs: { small: "" } }, [
            _vm._v(" " + _vm._s(language.toUpperCase()) + " "),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }