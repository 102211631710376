/* eslint no-param-reassign: 0 */
import * as _ from 'lodash';
import { FIG_APPID } from '../../utils/constants';
import { clearRsaKeysFromLocalStorage, envelopeDecrypt, envelopeEncrypt } from '../../utils/crypto';
import i18n from '../../plugins/i18n';
import { dateWithoutTime, getDayIndex } from '../../utils/datetime';
import { getLicenseExpiryTimestamp } from '../../utils/licenses';
import {
  getAxiosDeletePromise,
  getAxiosGetPromise,
  getAxiosPostPromise,
  getAxiosPutPromise,
  getJwtToken,
} from '../../utils/requestHandler';
import { setLoadingText } from '../../utils/loading';
import { anonymousApiDispatch } from '../../utils/dispatch';
import { getV4Uuid } from '../../utils/uuid';

/**
 * @typedef {{appId: string, id: string, name: string, updatedAt: string}} SaveMetaData
 * @typedef {{appId: string, email: string, id: string, nickname: string, publicKey: JsonWebKey, role: string}} TeamMember
 * @typedef {{appId: string, email: string, id: string, issuerId: string, message: string, organizationId: string,
 * role: string, useDeviation: boolean}} TeamMemberInvite
 * @typedef {{appId: string, createdAt: string, groupId:null|string, id: string, organizationId: string, updatedAt: string,
 * userId: string, validDays: number}} UserLicenseInformation
 * @typedef {{anonymousUserCredentials: string, createdAt: string, email: string, emailVerified: boolean, id: string,
 * iv: string, language: string, licenseRequested: boolean, licenses: UserLicenseInformation[], nickname: string, onboarded: boolean, permissions: object[],
 * playstart: string, privateKey: string, publicKey: JsonWebKey, relations: object[], salt: string, type: string, saves: SaveMetaData[], sessionId: string,
 * sendAdherenceEmails: boolean, sendAbsenceEmails: boolean}} FullUserInformation
 * @typedef {{amount: number, medication: {id: string, amount: number, form: string, name: string, unit: string}}} AdherenceSensorRelation
 * @typedef {{sensorId:string, schedule:string, durationInMinutes: number, prevSensorId: string|null, relations: AdherenceSensorRelation[]}} DoseInfo
 * @typedef {{formId: string, formType: string, answers: number[], score: number, createdAt?: string, id?: string}} AssessmentForm
 * @typedef {{me : FullUserInformation|null, doses: DoseInfo[], phqData: AssessmentForm[], sensors: object[], hasFetchedPhqData: boolean, hasFetchedTeamsData: boolean,
 * hasFetchedSensors: boolean, data: object, storage: object,
 * invites: object[], team: (TeamMember|TeamMemberInvite)[], teams: TeamMember[], isDirty: boolean, isChangePasswordInProgress: boolean, licenseExpiryDate: string,
 * inviteFrom: string}} ApiState
 * @typedef {{commit: (string, any)=>void, state: ApiState}} DispatchArg1
 * @typedef {{store: import("vuex").Store, router: import("vue-router").default, data?: object}} DispatchArg2
 * @typedef {{language?: 'sv'|'en', onboarded?: boolean, playstart?: string, nickname?: string, sendAdherenceEmails?: boolean, sendAbsenceEmails?: boolean, viewId:string, medicationRequired?: boolean}} UserInfo
 */

/**
 *@returns {ApiState}
 */
const getDefaultState = () => {
  return {
    me: null,
    doses: [],
    sensors: [],
    phqData: [],
    hasFetchedPhqData: false,
    hasFetchedTeamsData: false,
    hasFetchedSensors: false,
    data: {},
    storage: {},
    invites: [],
    team: [],
    teams: [],
    isDirty: false,
    isChangePasswordInProgress: false,
    licenseExpiryDate: undefined,
    inviteFrom: null,
  };
};

/**
 * Get user id from State object
 * @param {ApiState} state
 * @returns {string}
 */
function getUserId(state) {
  return state.me.id;
}

/**
 * Decrypts multiple storage items in paralell
 * @param {{data:{data: string, id:string}[]}} res
 * @param {CryptoKey} privateKey
 * @param {string} [entryArrayKey] key to use if entry is an array
 * @returns {Promise<object[]>}
 */
async function decryptItems(res, privateKey, entryArrayKey) {
  const promises = [];
  const resData = res.data;
  for (let i = 0; i < resData.length; i += 1) {
    promises.push(envelopeDecrypt(privateKey, resData[i].data));
  }
  const decryptedData = await Promise.all(promises);
  const items = [];
  for (let i = 0; i < resData.length; i += 1) {
    let decryptedEntry = JSON.parse(decryptedData[i]);
    if (decryptedEntry.id === null) {
      decryptedEntry = _.omit(decryptedEntry, 'id');
    }
    if (entryArrayKey) {
      const arrayObj = {};
      arrayObj[entryArrayKey] = decryptedEntry;
      decryptedEntry = arrayObj;
    }
    items.push(
      _.omit(
        {
          ...res.data[i],
          ...decryptedEntry,
        },
        ['data', 'userId'],
      ),
    );
  }
  return items;
}

/**
 * Encrypts and returns a storage item
 * @param {object} obj
 * @param {CryptoKey} publicKey
 * @param {string} key
 * @param {string} userId
 * @param {string} appId
 * @returns {Promise<{key: string, data: string, appId: string, userId: string}>}
 */
async function getStorageItem(obj, publicKey, key, userId, appId) {
  const encrypted = await envelopeEncrypt(publicKey, JSON.stringify(obj));
  return {
    key,
    data: encrypted,
    appId,
    userId,
  };
}

/**
 *
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {any[]} doses doses array
 * @param {string} appId app id
 * @param {number} journalDay journal day index
 * @param {string} viewId view id
 * @returns {Promise<?>}
 */
async function addSensorsForDoses(store, router, doses, appId, journalDay, viewId) {
  const promises = [];
  for (const dose of doses) {
    if (!dose.sensorId) {
      promises.push(
        anonymousApiDispatch(store, router, 'addSensor', {
          appId,
          dose,
          journalDay,
          viewId,
        }),
      );
    }
  }
  setLoadingText(store, i18n.t('loading.medication.settingUpReminder'));
  return Promise.all(promises);
}

/**
 * Updates the license expiry on state object
 * @param {ApiState} state
 */
function setLicenseExpiryDate(state) {
  const { licenses } = state.me;
  let expiryDate;
  for (const license of licenses) {
    const { appId } = license;
    if (appId === FIG_APPID) {
      const thisExpiryDate = getLicenseExpiryTimestamp(license);
      if (!expiryDate || thisExpiryDate > expiryDate) {
        expiryDate = thisExpiryDate;
      }
    }
  }
  if (expiryDate) {
    state.licenseExpiryDate = expiryDate.toISOString();
  }
}

export default {
  namespaced: true,
  name: 'api',
  state: getDefaultState(),
  actions: {
    /**
     * Accept user invite
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async acceptInvite(
      { commit, state },
      { store, data: invite }, // id: string,
    ) {
      const jwtToken = await getJwtToken();
      const { viewId } = invite;
      return getAxiosPostPromise(
        store,
        `invites/${invite.id}/accept`,
        jwtToken,
        {
          userId: getUserId(state),
        },
        (res) => {
          commit('setAcceptedInvite', {
            invite,
          });
          return res.data;
        },
        null,
        (error) => {
          const errorResponse = error.response;
          if (errorResponse && errorResponse.data.includes('duplicate key')) {
            return 'duplicate key';
          }
          return error;
        },
        null,
        viewId,
      );
    },
    /**
     * Post invite
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async postInvite(
      { commit, state },
      { store, data: payload }, // invite: { // email: string, // message: string, // organizationId: string, // appId: string, // role: string ("playtient"|"friend"), // expires: string, // }
    ) {
      const jwtToken = await getJwtToken();
      const invite = {
        ...payload.invite,
        issuerId: getUserId(state),
      };
      const { viewId } = invite;
      delete invite.viewId;
      return getAxiosPostPromise(
        store,
        'invites',
        jwtToken,
        invite,
        (res) => {
          commit('setInvite', payload);
          return res.data;
        },
        null,
        null,
        null,
        viewId,
      );
    },
    /**
     * Delete invite
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async deleteInvite(
      { commit },
      { store, data: payload }, // inviteId: string,
    ) {
      const jwtToken = await getJwtToken();
      return getAxiosDeletePromise(
        store,
        `invites/${payload.inviteId}`,
        jwtToken,
        (res) => {
          commit('setDeletedInvite', {
            payload,
          });
          return res.data;
        },
        null,
        null,
        payload.viewId,
      );
    },
    /**
     * Delete team member
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async deleteTeamMember(
      { commit, state },
      { store, data: payload }, // memberId: string,
    ) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      return getAxiosDeletePromise(
        store,
        `users/${userId}/team/${payload.memberId}`,
        jwtToken,
        (res) => {
          commit('voidMutation');
          return res.data;
        },
        null,
        null,
        payload.viewId,
      );
    },
    /**
     * Delete user from team
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async deleteMeFromTeam(
      { commit, state },
      { store, data: payload }, // playtientId: string,
    ) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      const { playtientId, viewId } = payload;
      return getAxiosDeletePromise(
        store,
        `users/${playtientId}/team/${userId}`,
        jwtToken,
        (res) => {
          commit('setRemovedFromTeam', playtientId);
          return res.data;
        },
        null,
        null,
        viewId,
      );
    },
    /**
     * Get invites to user
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async getInvitesToMe({ commit }, { store }) {
      const jwtToken = await getJwtToken();
      return getAxiosGetPromise(
        store,
        `invites/my?expired=false`,
        jwtToken,
        (res) => {
          commit('voidMutation');
          return res.data;
        },
        null,
      );
    },
    /**
     * Get playtient invites sent
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async getPlaytientInvites(
      { commit, state },
      { store, data: payload }, // organizationId : string,
    ) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      return getAxiosGetPromise(
        store,
        `invites?organizationId=${payload.organizationId}&issuerId=${userId}&expired=false`,
        jwtToken,
        (res) => {
          commit('voidMutation');
          return res.data;
        },
        null,
      );
    },
    /**
     * Get user information
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async getMe({ commit }, { store, data: payload }) {
      const jwtToken = await getJwtToken();
      let url = 'me?client=portal';
      if (payload && payload.getMasterKey) {
        url = `${url}&key=master`;
      }
      const { viewId } = payload;
      delete payload.viewId;
      return getAxiosGetPromise(
        store,
        url,
        jwtToken,
        (res) => {
          commit('setMe', res.data);
          return res.data;
        },
        null,
        null,
        viewId,
      );
    },
    /**
     * Get user progress
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async getUserProgress({ commit, state }, { store, data: payload }) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      const { name, viewId } = payload;
      const url = `users/${userId}/progress/${name}`;
      return getAxiosGetPromise(
        store,
        url,
        jwtToken,
        (res) => {
          commit('voidMutation');
          return res.data;
        },
        null,
        null,
        viewId,
      );
    },
    /**
     * Get sensor data paginated
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async getDataPaginated({ commit, state }, { store, data: payload }) {
      // appId: string,
      // privateKey: CryptoKey,
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      const allRes = [];
      let page = 0;
      let res;
      do {
        page += 1;
        // TODO: fix this!
        // eslint-disable-next-line no-await-in-loop
        res = await getAxiosGetPromise(
          store,
          `sensors/dataPaginated?appId=${payload.appId}&userId=${userId}&page=${page}`,
          jwtToken,
          undefined,
          null,
          null,
          payload.viewId,
        );
        // TODO: fix this also!
        // eslint-disable-next-line no-await-in-loop
        const items = await decryptItems(res, payload.privateKey);
        allRes.concat(items);
      } while (res.data.pagination.to !== res.data.pagination.from);
      commit('setData', allRes);
      return allRes;
    },
    /**
     * Get storage data for today paginated
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async getAllSensorData({ state }, { store, data: payload }) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      const { appId, fromTimeStamp, privateKey } = payload;
      let url = `sensors/data?appId=${appId}&userId=${userId}`;
      if (fromTimeStamp) {
        url = `${url}&fromTimestamp=${fromTimeStamp.toISOString()}`;
      }
      const res = await getAxiosGetPromise(
        store,
        url,
        jwtToken,
        undefined,
        null,
        null,
        payload.viewId,
      );
      return decryptItems(res, privateKey);
    },
    /**
     * Get todays sensor data
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async getTodaysSensorData({ commit, state }, { store, data: payload }) {
      // appId: string,
      // privateKey: CryptoKey,
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      const dateNow = new Date();
      const timeStampStr = dateWithoutTime(dateNow, false).toISOString();
      return getAxiosGetPromise(
        store,
        `sensors/data?appId=${payload.appId}&userId=${userId}&fromTimestamp=${timeStampStr}`,
        jwtToken,
        async (res) => {
          if (!res.data) {
            return [];
          }
          const items = await decryptItems(res, payload.privateKey);
          commit('voidMutation');
          return items;
        },
        null,
        null,
        payload.viewId,
      );
    },
    /**
     * Get all storage data
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async getAllStorage({ commit, state }, { store, data: payload }) {
      // privateKey: CryptoKey,
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      return getAxiosGetPromise(
        store,
        `storage?userId=${userId}&limit=-1`,
        jwtToken,
        async (res) => {
          if (!res.data) {
            return [];
          }
          const items = await decryptItems(res, payload.privateKey);
          commit('setStorage', items);
          return items;
        },
        null,
        null,
        payload.viewId,
      );
    },
    /**
     * Get team information
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async getTeam({ commit, state }, { store, data: payload }) {
      // appId: string,
      // organizationId: string
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      return getAxiosGetPromise(
        store,
        `users/${userId}/team?appId=${payload.appId}&organizationId=${payload.organizationId}&skipExpired=true`,
        jwtToken,
        (res) => {
          commit('setTeam', res.data);
          return res.data;
        },
        null,
        null,
        payload.viewId,
      );
    },
    /**
     * Get team information for team member
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async getTeams({ commit, state }, { store, data: payload }) {
      // appId: string,
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      return getAxiosGetPromise(
        store,
        `users/${userId}/teams?appId=${payload.appId}&addInvites=true`,
        jwtToken,
        (res) => {
          commit('setTeams', res.data);
          return res.data;
        },
        null,
        null,
        payload.viewId,
      );
    },
    /**
     * Post new RSA key
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async postNewRsaKey({ commit, state }, { store, data: payload }) {
      const data = {
        iv: payload.iv,
        salt: payload.salt,
        privateKey: payload.wrappedKey,
        publicKey: payload.exportedPublicKey,
        publicKeyId: payload.publicKeyId,
        restoreKey: payload.restoreKey,
      };
      const { viewId } = payload;
      delete payload.viewId;
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      return getAxiosPostPromise(
        store,
        `users/${userId}/register-encryption-key`,
        jwtToken,
        data,
        (res) => {
          commit('setRsaData', {
            payload,
          });
          return res.data;
        },
        null,
        null,
        null,
        viewId,
      );
    },
    /**
     * Stage new rsa key
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async stageRsaKey({ commit, state }, { store, data: payload }) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      return getAxiosPostPromise(
        store,
        `users/${userId}/stage-encryption-key`,
        jwtToken,
        {
          iv: payload.iv,
          privateKey: payload.privateKey,
        },
        (res) => {
          commit('setChangePasswordInProgress', payload);
          return res.data;
        },
        null,
        null,
        null,
        payload.viewId,
      );
    },
    /**
     * Commit new RSA key
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async commitRsaKey({ commit, state }, { store, data: payload }) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      return getAxiosPostPromise(
        store,
        `users/${userId}/commit-encryption-key`,
        jwtToken,
        {
          iv: payload.iv,
          privateKey: payload.privateKey,
        },
        (res) => {
          commit('setIv', {
            payload,
          });
          return res.data;
        },
        null,
        null,
        null,
        payload.viewId,
      );
    },
    /**
     * Update user information
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async setUserInfo({ commit, state }, { store, data: payload }) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      const { viewId } = payload;
      delete payload.viewId;
      return getAxiosPutPromise(
        store,
        `users/${userId}/info`,
        jwtToken,
        payload,
        (res) => {
          commit('setUserInfo', payload);
          return res.data;
        },
        null,
        null,
        null,
        viewId,
      );
    },
    /**
     * Add doses
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async addDoses(
      { commit, state },
      { store, router, data: payload }, // publicKey: await importRsaKey(state.me.publicKey), // appId: e.g FIG_APP_ID, // doses: doses,
    ) {
      const { appId, doses, publicKey, viewId } = payload;
      const journalDayIndex = store.getters['api/getJournalDayIndex'];
      const sensorIds = await addSensorsForDoses(
        store,
        router,
        doses,
        appId,
        journalDayIndex,
        viewId,
      );
      let i = 0;
      for (const dose of doses) {
        if (!dose.sensorId) {
          dose.sensorId = sensorIds[i];
          i += 1;
        }
      }

      // Post encrypted data to storage
      const userId = getUserId(state);
      const storageData = await getStorageItem(
        doses.filter((d) => d.deleted_at === undefined),
        publicKey,
        'doses',
        userId,
        appId,
      );
      const jwtToken = await getJwtToken();
      setLoadingText(store, i18n.t('loading.almostDone'));
      return getAxiosPostPromise(
        store,
        'storage',
        jwtToken,
        storageData,
        (res) => {
          commit('addDose', {
            doses,
          });
          return res.data;
        },
        null,
        null,
        null,
        viewId,
      );
    },
    /**
     * Get doses
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async getDoses(
      { commit, state },
      { store, data: payload }, // privateKey: CryptoKey,
    ) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      const { viewId } = payload;
      return getAxiosGetPromise(
        store,
        `storage?sort=-createdAt&key=doses&userId=${userId}&appId=${FIG_APPID}`,
        jwtToken,
        async (res) => {
          const { data } = res;
          if (!data.length) {
            commit('setDoses', { doses: [] });
            return [];
          }
          // Get the first entry for now.
          const decrypted = await envelopeDecrypt(payload.privateKey, data[0].data);
          const doses = JSON.parse(decrypted);
          commit('setDoses', {
            doses,
          });
          return data;
        },
        null,
        null,
        viewId,
      );
    },
    /**
     * Get sensors
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async getSensors(
      { commit, state },
      { store, data: payload }, // privateKey: CryptoKey,
    ) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      let url = `storage?sort=-createdAt&key=sensor&userId=${userId}&appId=${FIG_APPID}&limit=-1`;
      const { minDate, viewId } = payload;
      if (minDate) {
        url = `${url}&minCreatedAt=${minDate}`;
      }
      return getAxiosGetPromise(
        store,
        url,
        jwtToken,
        async (res) => {
          const { data } = res;
          if (!data.length) {
            commit('setSensors', { sensors: [] });
            return [];
          }
          // Get the first entry for now.
          let sensors = await decryptItems(res, payload.privateKey);
          const { sensorTypeId } = payload;
          if (sensorTypeId) {
            sensors = sensors.filter((s) => s.sensorTypeId === sensorTypeId);
          }
          commit('setSensors', {
            sensors,
          });
          return sensors;
        },
        null,
        null,
        viewId,
      );
    },
    /**
     * Add license
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async addLicense({ commit, state }, { store, data: payload }) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      return getAxiosPostPromise(
        store,
        `users/${userId}/add-license`,
        jwtToken,
        {
          licenseKey: payload.licenseKey,
        },
        (res) => {
          commit('setLicense', res.data);
          return res.data;
        },
        null,
        null,
        null,
        payload.viewId,
      );
    },
    /**
     * Create anonymous user
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async createAnonymousUser({ commit, state }, { store, data: payload }) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      return getAxiosPostPromise(
        store,
        `users/${userId}/create-anonymous-user`,
        jwtToken,
        {},
        (res) => {
          // commit('setAnonymousUser', res.data);
          commit('voidMutation');
          return res.data;
        },
        'almostDone',
        null,
        null,
        payload.viewId,
      );
    },
    /**
     * Register anonymous user
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async registerAnonymousUser({ commit, state }, { store, data: payload }) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      return getAxiosPostPromise(
        store,
        `users/${userId}/register-anonymous-user`,
        jwtToken,
        {
          credentials: payload.encryptedCredentials,
        },
        (res) => {
          // commit('setAnonymousUserCredentials', payload.encryptedCredentials);
          commit('voidMutation');
          return res.data;
        },
        null,
        null,
        null,
        payload.viewId,
      );
    },
    /**
     * Resend email verification
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async resendEmailVerification({ commit, state }, { store, data: payload }) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      return getAxiosPostPromise(
        store,
        `users/${userId}/resend-verification-email`,
        jwtToken,
        {},
        (res) => {
          commit('voidMutation');
          return res.data;
        },
        null,
        null,
        null,
        payload.viewId,
      );
    },
    /**
     * Get invite public information
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    getInvitePublic({ commit }, { store, data: payload }) {
      return getAxiosGetPromise(
        store,
        `invites/${payload.inviteId}/public`,
        null,
        (res) => {
          commit('voidMutation');
          return res.data;
        },
        null,
        null,
        payload.viewId,
      );
    },
    /**
     * Set invited by details
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     */
    setInvitedByDetails({ commit }, { data: payload }) {
      commit('setInvitedBy', payload);
    },
    /**
     * Get user phq form data
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     */
    async getPhqFormData({ commit, state }, { store, data }) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      return getAxiosGetPromise(
        store,
        `storage/?userId=${userId}&key=phq9&appId=${FIG_APPID}&sort=-createdAt`,
        jwtToken,
        async (res) => {
          if (!res.data) {
            commit('setPhqFormData', { phqData: [] });
            return [];
          }
          const phqData = await decryptItems(res, data.privateKey);
          commit('setPhqFormData', { phqData });
          return phqData;
        },
        null,
        null,
        data.viewId,
      );
    },
    async postPhqFormData({ commit, state }, { store, data }) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      const storageData = await getStorageItem(
        data.formData,
        data.publicKey,
        'phq9',
        userId,
        FIG_APPID,
      );
      return getAxiosPostPromise(
        store,
        'storage',
        jwtToken,
        storageData,
        async (res) => {
          if (!res.data) {
            return undefined;
          }
          const dataArray = await decryptItems(res, data.privateKey);
          commit('addPhqFormData', { phqData: dataArray });
          return dataArray[0];
        },
        null,
        null,
        null,
        data.viewId,
      );
    },
    /**
     * Get user sensors
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     */
    async getSensorsLimited({ commit, state }, { store, data }) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      let url = `storage/?userId=${userId}&key=sensor&appId=${FIG_APPID}&sort=-createdAt&limit=100`;
      const { minDate } = data;
      if (minDate) {
        url = `${url}&minCreatedAt=${minDate}`;
      }
      return getAxiosGetPromise(
        store,
        url,
        jwtToken,
        async (res) => {
          if (!res.data) {
            commit('voidMutation');
            return [];
          }
          const sensors = await decryptItems(res, data.privateKey);
          commit('voidMutation');
          return sensors;
        },
        null,
        null,
        data.viewId,
      );
    },
    /**
     * Get user dose configs
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     */
    async getAllDoseConfigs({ commit, state }, { store, data }) {
      const jwtToken = await getJwtToken();
      const userId = getUserId(state);
      let url = `storage/?userId=${userId}&key=doses&appId=${FIG_APPID}&sort=-createdAt&limit=-1`;
      const { minDate } = data;
      if (minDate) {
        url = `${url}&minCreatedAt=${minDate}`;
      }
      return getAxiosGetPromise(
        store,
        url,
        jwtToken,
        async (res) => {
          commit('voidMutation');
          if (!res.data) {
            return [];
          }
          const doseConfigs = await decryptItems(res, data.privateKey, 'doses');
          return doseConfigs;
        },
        null,
        null,
        data.viewId,
      );
    },
    /**
     * Set state to not dirty
     * @param {DispatchArg1} param0
     */
    setNotDirty({ commit }) {
      commit('setDirty', false);
    },
  },
  mutations: {
    resetState(state) {
      clearRsaKeysFromLocalStorage();
      console.log('Resetting state!');
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState());
    },
    setAcceptedInvite(state, payload) {
      console.log('Not yet implemented', payload);
      if (state.me) {
        state.me.emailVerified = true;
        state.hasFetchedTeamsData = false;
      }
    },
    voidMutation() {
      // For actions that does not affect the state
    },
    setRemovedFromTeam(state, playtientId) {
      // Just remove the related teams entry
      state.teams = state.teams.filter((e) => e.id !== playtientId);
    },
    setDeletedInvite(state, invite) {
      console.log('Deleted invite');
      state.me.invites.splice(state.me.invites.indexOf(invite), 1);
    },
    /**
     * Set me object in state
     * @param {ApiState} state
     * @param {FullUserInformation} payload
     */
    setMe(state, payload) {
      state.me = { ...payload, sessionId: getV4Uuid() };
      setLicenseExpiryDate(state);
    },
    setInvite(state, payload) {
      state.invites.push(payload.invite);
    },
    setInvitedBy(state, payload) {
      state.inviteFrom = payload;
    },
    setData(state, payload) {
      state.data = payload;
    },
    setStorage(state, payload) {
      state.storage = payload;
    },
    setUserInfo(state, payload) {
      state.me = { ...state.me, ...payload };
    },
    setLicense(state, payload) {
      if (!state.me.licenses) {
        state.me.licenses = [payload];
      } else {
        state.me.licenses.push(payload);
      }
      setLicenseExpiryDate(state);
    },
    setTeam(state, payload) {
      // todo: handle multiple teams
      state.team = payload;
    },
    setTeams(state, payload) {
      // todo: handle multiple teams
      state.teams = payload;
      state.hasFetchedTeamsData = true;
    },
    setDoses(state, payload) {
      state.doses = payload.doses;
    },
    setPhqFormData(state, payload) {
      state.phqData = payload.phqData;
      state.hasFetchedPhqData = true;
    },
    setSensors(state, payload) {
      state.sensors = payload.sensors;
      state.hasFetchedSensors = true;
    },
    addPhqFormData(state, payload) {
      state.phqData = payload.phqData.concat(state.phqData);
    },
    addDose(state, payload) {
      state.doses = payload.doses;
      // If user is onboarded, set api state to dirty
      if (state.me.onboarded) {
        state.isDirty = true;
      }
    },
    deleteDose(state, dose) {
      const { doses } = state;
      const index = doses.findIndex((d) => d.sensorId === dose.sensorId);
      const newObj = doses[index];
      newObj.deleted_at = new Date().toISOString();
      // eslint-disable-next-line no-underscore-dangle
      this._vm.$set(state.doses, index, newObj);
      // If user is onboarded, set api state to dirty
      if (state.me.onboarded) {
        state.isDirty = true;
      }
    },
    setDirty(state, dirty) {
      state.isDirty = dirty;
    },
    setRsaData(state, payload) {
      state.me.privateKey = payload.privateKey;
      state.me.publicKey = payload.publicKey;
      state.me.salt = payload.salt;
      state.me.iv = payload.iv;
    },
    setIv(state, payload) {
      state.me.iv = payload.iv;
      state.isChangePasswordInProgress = false;
    },
    setChangePasswordInProgress(state) {
      state.isChangePasswordInProgress = true;
    },
  },
  getters: {
    me: (state) => {
      return state.me;
    },
    doses: (state) => {
      return state.doses;
    },
    sensors: (state) => {
      return state.sensors;
    },
    phqData: (state) => {
      return state.phqData;
    },
    teams: (state) => {
      return state.teams;
    },
    hasFetchedPhqData: (state) => {
      return state.hasFetchedPhqData;
    },
    hasFetchedTeamsData: (state) => {
      return state.hasFetchedTeamsData;
    },
    hasFetchedSensors: (state) => {
      return state.hasFetchedSensors;
    },
    hasValidLicense: (state) => {
      const expiryDate = state.licenseExpiryDate;
      return expiryDate && new Date(expiryDate) > new Date();
    },
    hasEvergreenLicense: (state) => {
      const { licenses } = state.me;
      for (const license of licenses) {
        if (license.validDays >= 36500 && license.appId === FIG_APPID) {
          return true;
        }
      }
      return false;
    },
    hasSaveFile: (state) => {
      return state.me.saves.length > 0;
    },
    getInviteObject: (state) => {
      return state.inviteFrom;
    },
    getJournalDayIndex: (state) => {
      const meObj = state.me;
      if (!meObj) {
        return undefined;
      }
      const playStartStr = meObj.playstart;
      if (!playStartStr) {
        return undefined;
      }
      return getDayIndex(playStartStr);
    },
    getUserSessionId: (state) => {
      const meObj = state.me;
      if (!meObj) {
        return undefined;
      }
      return meObj.sessionId;
    },
  },
};
