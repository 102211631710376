<template>
  <v-slide-y-transition>
    <v-btn-toggle
      v-model="languageToggle"
      rounded
      mandatory
      dense
      color="secondary"
      background-color="primary"
      v-show="onLoad"
    >
      <v-btn v-for="(language, index) in languages" :key="index" small>
        {{ language.toUpperCase() }}
      </v-btn>
    </v-btn-toggle>
  </v-slide-y-transition>
</template>

<script>
import { setUserInfo } from '../api/users';
import { getStringConfig } from '../utils/config';

const allLanguages = ['en', 'sv', 'de', 'fr'];

export default {
  name: 'LocalePicker',
  props: {
    menu: Boolean,
  },
  data() {
    return {
      viewId: '010',
      onLoad: false,
      languages: allLanguages,
      languageToggle: [],
    };
  },
  watch: {
    async menu(newValue) {
      if (newValue) {
        this.updateAvailableLanguages();
      }
    },
    async languageToggle(newValue) {
      if (!this.onLoad) {
        return;
      }
      this.$i18n.locale = this.languages[newValue];
      localStorage.setItem('USER_LANG', this.languages[newValue]);
      // Check if possible to store language in backend
      const { me } = this.$store.state.api;
      if (me) {
        await setUserInfo(this.$store, this.$router, {
          language: this.$i18n.locale,
          viewId: this.viewId,
        });
      }
    },
  },
  mounted() {
    this.setToggle();
    setTimeout(() => {
      this.setToggle();
      this.onLoad = true;
    }, 500);
  },
  async beforeMount() {
    await this.updateAvailableLanguages();
  },
  methods: {
    getUserLanguage() {
      const { lang: queryLang } = this.$route.query;
      if (queryLang) {
        return queryLang;
      }
      const { me } = this.$store.state.api;
      if (me !== null) {
        return me.language;
      }
      return localStorage.getItem('USER_LANG');
    },
    getCurrentLanguage() {
      const userLanguage = this.getUserLanguage();
      if (userLanguage && this.languages.includes(userLanguage)) {
        this.$i18n.locale = userLanguage;
      }
      return this.$i18n.locale;
    },
    setToggle() {
      this.languageToggle = this.languages.indexOf(this.getCurrentLanguage());
    },
    async updateAvailableLanguages() {
      const avaialbleLanguages = await getStringConfig(this.$store, 'AVAILABLE_LANGUAGES');
      if (avaialbleLanguages !== null) {
        const langArr = avaialbleLanguages.split(',');
        if (langArr.length > 0 && langArr.every((lang) => allLanguages.includes(lang))) {
          this.languages = langArr;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mgl-simple-locale {
  position: absolute;
  right: 15px;
  top: 15px;
}
</style>
