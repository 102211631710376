/* eslint no-param-reassign: 0 */
const getDefaultState = () => {
  return { snack: {} };
};

export default {
  namespaced: true,
  name: 'snackbar',
  state: getDefaultState(),
  mutations: {
    setSnack(state, showSnack) {
      state.snack = { ...showSnack };
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },
};
