<template>
  <div>
    <v-container fluid fill-height class="pb-0">
      <v-row>
        <v-col class="text-center pb-0">
          <h2>{{ $t('reports.header') }}</h2>
        </v-col>
      </v-row>
      <!-- Description -->
      <v-row>
        <v-col class="text-center col-10 offset-1 col-md-4 offset-md-4">
          {{ $t('reports.description') }}
        </v-col>
      </v-row>
      <!-- datalist selection of reports -->
      <v-select
        style="max-width: 500px; margin: auto"
        flat
        :items="reportOptions"
        item-text="label"
        v-model="selectedReport"
        :placeholder="$t('reports.reportlist')"
        dense
        filled
        background-color="primary"
        color="primary"
        class="pa-5"
        solo
        return-object
        @change="reportSelected"
      ></v-select>
    </v-container>
    <!-- Medication section -->
    <ReportMedication
      v-if="adherenceUnlocked"
      :selectedReport="selectedReport"
      :key="getKey('adherence')"
      id="medication"
    />
    <!-- Mood section -->
    <ReportMood
      v-if="moodUnlocked"
      :selectedReport="selectedReport"
      :key="getKey('mood')"
      id="mood"
    />
    <!-- Meal section -->
    <ReportMeal
      v-if="mealUnlocked"
      :selectedReport="selectedReport"
      :key="getKey('meal')"
      id="meal"
    />
    <!-- Sleep section -->
    <ReportSleep
      v-if="sleepUnlocked"
      :selectedReport="selectedReport"
      :key="getKey('sleep')"
      id="sleep"
    />
    <!-- Steps section -->
    <ReportSteps
      v-if="stepsUnlocked"
      :selectedReport="selectedReport"
      :key="getKey('exercise')"
      id="steps"
    />
    <!-- PDF button -->
    <v-row class="pa-5" v-if="downloadAvailable">
      <v-col class="col-md-4 offset-md-4 text-center">
        <v-btn
          color="secondary"
          v-on:click="PDFdownloadAsync"
          rounded
          depressed
          x-large
          class="px-15 uppercase"
        >
          {{ $t('reports.downloadPDF') }}
        </v-btn>
      </v-col>
    </v-row>
    <div class="pdfclass" id="pdfreport" style="display: none">
      <div id="pdfpage1"></div>
      <div id="pdfpage2"></div>
      <div id="pdfpage3"></div>
      <div id="pdfpage4"></div>
      <div id="pdfpage5"></div>
    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ReportMedication from './components/ReportMedication.vue';
import ReportMood from './components/ReportMood.vue';
import ReportMeal from './components/ReportMeal.vue';
import ReportSleep from './components/ReportSleep.vue';
import ReportSteps from './components/ReportSteps.vue';
import { displayError, setLoading } from '../utils/loading';
import { getPlayStart, getRoutineSetup, getTimeZone } from '../progress/routines';
import {
  addDaysToDateTime,
  currentDateInTimeZone,
  datetimeForDateStrInTimeZone,
} from '../utils/datetime';

export default {
  name: 'Reports',
  components: {
    ReportMedication,
    ReportMood,
    ReportMeal,
    ReportSleep,
    ReportSteps,
  },
  data() {
    return {
      viewId: '014',
      reportOptions: [],
      selectedReport: {
        week: 0,
        xAxis: [],
        condition: null,
        userTimezone: null,
        stepGoal: 0,
        sleepBedTime: '',
      },
      adherenceUnlocked: false,
      moodUnlocked: false,
      mealUnlocked: false,
      sleepUnlocked: false,
      stepsUnlocked: false,
      downloadAvailable: false,
      routines: null,
      userTimezone: null,
    };
  },
  mounted() {
    this.fetchReports();
  },
  methods: {
    getKey(type) {
      return `${type}-${this.selectedReport.week}`;
    },
    reportSelected(selectedItem) {
      if (this.routines === null) {
        return;
      }
      this.selectedReport.userTimezone = this.userTimezone;
      this.selectedReport.xAxis = this.getXAxis(selectedItem.start);
      this.adherenceUnlocked = false;
      this.moodUnlocked = false;
      this.mealUnlocked = false;
      this.sleepUnlocked = false;
      this.stepsUnlocked = false;
      this.downloadAvailable = false;
      this.routines.forEach((routine) => {
        if (routine.name === 'Wellbeing') {
          this.moodUnlocked = true;
          if (this.moodUnlocked) {
            this.selectedReport.condition = routine.sensors.find(
              (sensor) => sensor.name === 'mood-bipolar',
            )
              ? 'bipolar'
              : 'depression';
          }
        } else if (routine.name === 'Sleep') {
          this.sleepUnlocked = true;
          this.selectedReport.sleepBedTime = routine.sleepBedTime;
        } else if (routine.name === 'Exercise') {
          this.stepsUnlocked = true;
          this.selectedReport.stepGoal = routine.stepGoal;
        } else if (routine.name === 'Food') {
          this.mealUnlocked = true;
        } else if (routine.name === 'Medication') {
          this.adherenceUnlocked = true;
        }
      });
      this.downloadAvailable = true;
    },
    getMonthName(month) {
      return this.$i18n.t(`generic.months.short.${month}`);
    },
    getWeekdayName(weekday) {
      return this.$i18n.t(`generic.weekdays.short.${weekday}`);
    },
    getWeeks(playStart) {
      const dateStart = datetimeForDateStrInTimeZone(playStart, this.userTimezone);
      const today = currentDateInTimeZone(this.userTimezone);
      const weeks = [];
      const diffDays = today.diff(dateStart, 'days').days;
      const weeksNumber = Math.ceil(diffDays / 7);

      for (let i = 0; i < weeksNumber; i += 1) {
        const start = addDaysToDateTime(dateStart, i * 7);
        // end of the period is last millisecond of the last day
        const end = addDaysToDateTime(start, 7).minus({ milliseconds: 1 });

        const startMonth = start.month;
        const endMonth = end.month;
        const startDay = start.day;
        const endDay = end.day;
        const startYear = start.year;
        const endYear = end.year;

        let label =
          startYear === endYear
            ? `${this.getMonthName(startMonth)} ${startDay} - ${this.getMonthName(
                endMonth,
              )} ${endDay}, ${startYear}`
            : `${this.getMonthName(startMonth)} ${startDay}, ${startYear} - ${this.getMonthName(
                endMonth,
              )} ${endDay}, ${endYear}`;

        label += ` (${this.$i18n.t(`generic.week`)} ${i + 1})`;
        weeks.push({
          week: i,
          start,
          end,
          label,
        });
      }

      return weeks;
    },
    getXAxis(startDate) {
      const weekDates = [];

      for (let i = 0; i < 7; i += 1) {
        const date = startDate.plus({ days: i });
        weekDates.push({
          day: this.getWeekdayName(date.weekday),
          date: date.toFormat('dd/MM'),
        });
      }
      return weekDates;
    },
    async fetchReports() {
      try {
        setLoading(this.$store, true);
        this.userTimezone = await getTimeZone(this.$store, this.$router, this.viewId);
        const playStart = await getPlayStart(this.$store, this.$router, this.viewId);
        if (playStart !== null) {
          this.reportOptions = this.getWeeks(playStart);
          this.routines = await getRoutineSetup(this.$store, this.$router, this.viewId);
        }
      } catch (error) {
        displayError(this.$store, error);
      }
      setLoading(this.$store, false);
    },
    onElementClone(el) {
      const hiddenDiv = el.getElementById('pdfreport');
      hiddenDiv.style.display = 'block';
    },
    cloneNode(elementId, pageId) {
      const element = document.getElementById(elementId);
      const clonedElement = element.cloneNode(true);
      /** @type {HTMLElement} */
      // @ts-ignore
      const card = clonedElement.firstChild;
      card.classList.remove('primary');
      card.style.backgroundColor = 'white';
      card.style.color = 'black';
      card.style['max-width'] = '600px';
      card.style['border-color'] = 'lightgrey';
      card.style['border-style'] = 'solid';
      if (elementId === 'medication') {
        this.fixMedicationPdfStyle(card);
      } else if (elementId === 'mood') {
        this.fixMoodPdfStyle(card);
      } else if (elementId === 'meal') {
        this.fixMealPdfStyle(card);
      } else if (elementId === 'sleep') {
        this.fixSleepPdfStyle(card);
      }
      const pdfPage = document.getElementById(pageId);
      pdfPage.innerHTML = '';
      pdfPage.appendChild(clonedElement);
      return pdfPage;
    },
    fixMedicationPdfStyle(card) {
      const yaxis = card.children[2].firstChild.children[0];
      const contents = card.children[2].firstChild.children[1];
      const yvalues = Array.from(yaxis.children).filter((child) => child.tagName === 'DIV');
      this.alternateTableColors(yvalues);
      const cvalues = Array.from(contents.children).filter((child) => child.tagName === 'DIV');
      this.alternateTableColors(cvalues);
      const checks = contents.getElementsByClassName('check');
      Array.from(checks).forEach((check) => {
        const circle = check.firstChild;
        if (circle.classList.length === 0) {
          circle.style.background = '#C1C1C1';
        }
      });
    },
    fixMoodPdfStyle(card) {
      const yaxis = card.children[2].firstChild;
      const yvalues = Array.from(yaxis.children);
      this.alternateTableColors(yvalues);
      const lines = card.getElementsByClassName('lines');
      const lineValues = Array.from(lines);
      lineValues.forEach((div) => {
        Array.from(div.children).forEach((line) => {
          // eslint-disable-next-line no-param-reassign
          line.style.background = '#E4E4E4';
        });
      });
    },
    fixMealPdfStyle(card) {
      const yaxis = card.children[2].firstChild.children[0];
      const contents = card.children[2].firstChild.children[1];
      const yvalues = Array.from(yaxis.children).filter((child) => child.tagName === 'DIV');
      this.alternateTableColors(yvalues);
      const cvalues = Array.from(contents.children).filter((child) => child.tagName === 'DIV');
      this.alternateTableColors(cvalues);
      const checks = card.getElementsByClassName('check');
      Array.from(checks).forEach((check) => {
        if (check.children.length === 0) {
          // eslint-disable-next-line no-param-reassign
          check.style.background = '#C1C1C1';
        }
      });
    },
    fixSleepPdfStyle(card) {
      const goal = card.getElementsByClassName('bedtime-goal')[0];
      goal.style.background = 'transparent';
    },
    alternateTableColors(values) {
      values.forEach((div, index) => {
        if (index % 2 === 0) {
          // eslint-disable-next-line no-param-reassign
          div.style.background = '#F4F4F4'; // Even divs
        }
      });
    },
    newPdfPage(doc) {
      doc.addPage();
      doc.addImage('/fig-pdf-logo.png', 'png', 78, 10, 60, 23);
    },
    async PDFdownloadAsync() {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF();
      doc.addImage('/fig-pdf-logo.png', 'png', 78, 8, 60, 23);
      doc.setFontSize(20);
      // @ts-ignore
      doc.text(86, 40, this.$t('reports.pdfTitle'));
      doc.setFontSize(16);
      // @ts-ignore
      doc.text(69, 47, this.selectedReport.label);

      // Define a promisified function for html2canvas
      const html2canvasAsync = (element) => {
        return new Promise((resolve) => {
          html2canvas(element, {
            onclone: this.onElementClone,
          }).then((canvas) => {
            resolve(canvas.toDataURL('image/png'));
          });
        });
      };

      const cloneAndAddImage = async (elementName, pageName) => {
        const pdfPage = this.cloneNode(elementName, pageName);
        const imgData = await html2canvasAsync(pdfPage);
        if (pageName !== 'pdfpage1') {
          this.newPdfPage(doc);
          doc.addImage(imgData, 'PNG', -45, 35, 300, 0);
        } else {
          doc.addImage(imgData, 'PNG', -45, 50, 300, 0);
        }
      };

      await cloneAndAddImage('medication', 'pdfpage1');
      await cloneAndAddImage('mood', 'pdfpage2');
      if (this.mealUnlocked) {
        await cloneAndAddImage('meal', 'pdfpage3');
      }
      if (this.sleepUnlocked) {
        await cloneAndAddImage('sleep', 'pdfpage4');
      }
      if (this.stepsUnlocked) {
        await cloneAndAddImage('steps', 'pdfpage5');
      }

      // Save the PDF
      doc.save('report.pdf');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item--link::before {
  background-color: red;
}
.theme--light.v-list-item:hover::before {
  opacity: 0.64;
}
.pdfclass {
  background-color: white !important;
}
</style>
