var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center mb-3" }, [
                _c("h2", { staticStyle: { padding: "20px" } }, [
                  _vm._v(_vm._s(_vm.$t("PHQ9.title"))),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "col-md-4 offset-md-4 text-center" }, [
                _c("p", { staticClass: "font-weight-light" }, [
                  _vm._v(_vm._s(_vm.$t("PHQ9.introduction"))),
                ]),
                _c(
                  "p",
                  { staticClass: "font-weight-light" },
                  [_c("Phq9Popup")],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("StartPHQ9"),
          _c(
            "v-row",
            { staticStyle: { "padding-top": "50px" } },
            [
              _c("v-col", { staticClass: "col-md-4 offset-md-4 text-center" }, [
                _c("h2", [_vm._v(_vm._s(_vm.$t("PHQ9.pastResults")))]),
              ]),
            ],
            1
          ),
          _c("DonePHQ9s", { attrs: { resultPage: false } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }