var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          staticClass: "mgl-hero d-flex align-center justify-center",
          attrs: { flat: "", color: "primary" },
        },
        [_c("Logo")],
        1
      ),
      _c("SimpleLocalePicker", { staticClass: "mgl-simple-locale" }),
      _c("SignupForm", {
        ref: "signupForm",
        attrs: {
          inviteEmail: _vm.inviteEmail,
          stepId: _vm.stepId,
          betaClosedError: _vm.betaClosedError,
        },
        on: { signup: _vm.signUp },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }