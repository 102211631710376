import * as cronParser from 'cron-parser';
import { DateTime } from 'luxon';
import { addDaysToDate, fromISODateString, datetimeForDateStrInTimeZone } from './datetime';

/**
 * Get cron expression from time and days
 * @param {string} timeStr time like HH:mm
 * @param {string[]} daysArr array with valid days
 * @returns {string} cron expression
 */
export function getCronExpr(timeStr, daysArr) {
  const cronTime = timeStr.split(':');
  return `${parseInt(cronTime[1], 10)} ${parseInt(cronTime[0], 10)} * * ${daysArr.join()}`;
}

/**
 * Gets the triggertime on this date, or null if not applicable
 * @param {string} expr
 * @param {string} dateStr
 * @param {string} [timeZone]
 * @returns {DateTime|null}
 */
export function getTriggerDateTime(expr, dateStr, timeZone) {
  let startDate;
  if (timeZone) {
    startDate = datetimeForDateStrInTimeZone(dateStr, timeZone).toJSDate();
  } else {
    startDate = fromISODateString(dateStr, true);
  }
  // Start date is exclusive, so we need to subtract 1 millisecond
  startDate = new Date(startDate.getTime() - 1);
  const options = {
    currentDate: startDate,
    endDate: addDaysToDate(startDate, 1),
  };
  if (timeZone) {
    options.tz = timeZone;
  }
  try {
    const interval = cronParser.parseExpression(expr, options);
    // @ts-ignore
    const jsDate = interval.next().toDate();
    const luxonOptions = timeZone ? { zone: timeZone } : {};
    return DateTime.fromJSDate(jsDate, luxonOptions);
  } catch (err) {
    console.warn(`Cron expr: ${expr} is not valid for date: ${dateStr}`);
    return null;
  }
}

/**
 * Checks if cron expressions overlap
 * @param {string} expr1 cron expression
 * @param {string} expr2 cron expression
 * @returns {boolean} true if cron expression has an overlap
 */
export function hasDayOverlap(expr1, expr2) {
  const cronParts1 = expr1.split(' ');
  const cronParts2 = expr2.split(' ');
  if (cronParts1.length !== 5) {
    console.log(`invalid cron expr: ${expr1}`);
    return false;
  }
  if (cronParts2.length !== 5) {
    console.log(`invalid cron expr: ${expr2}`);
    return false;
  }
  if (cronParts1[0] !== cronParts2[0] || cronParts1[1] !== cronParts2[1]) {
    return false;
  }
  const daysStr1 = cronParts1[4];
  const daysStr2 = cronParts2[4];
  if (daysStr1 === daysStr2) {
    return true;
  }
  if (daysStr1 === '*' || daysStr2 === '*') {
    return true;
  }

  const days1 = daysStr1.split(',');
  const days2 = daysStr2.split(',');

  for (const day of days2) {
    if (days1.includes(day)) {
      return true;
    }
  }
  return false;
}
