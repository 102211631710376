var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "520px", "content-class": "elevation-0" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "p",
                    _vm._g(
                      {
                        staticClass: "p-0 m-0",
                        staticStyle: {
                          "text-decoration": "underline",
                          "text-decoration-color": "#c6c4c8",
                          "background-color": "transparent",
                          "text-decoration-thickness": "1px",
                          cursor: "pointer",
                        },
                      },
                      on
                    ),
                    [_vm._v(" " + _vm._s(_vm.$t("PHQ9.readmore")) + " ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialogOpen,
            callback: function ($$v) {
              _vm.dialogOpen = $$v
            },
            expression: "dialogOpen",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                padding: "40px 10px 20px 10px",
                "background-color": "transparent",
                overflow: "hidden",
              },
            },
            [
              _c(
                "v-card",
                {
                  staticStyle: {
                    "border-radius": "20px",
                    "text-align": "center",
                    "vertical-align": "top",
                    display: "inline-block",
                    width: "100%",
                    "background-color": "transparent",
                  },
                  attrs: { color: "primary" },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          _vm.dialogOpen = false
                        },
                      },
                    },
                    [_vm._v("X")]
                  ),
                  _c(
                    "v-card-title",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center",
                        padding: "0 30px 0 30px",
                        "text-align": "left",
                      },
                    },
                    [
                      _c(
                        "h3",
                        {
                          staticStyle: {
                            "padding-bottom": "20px",
                            "padding-top": "20px",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("PHQ9.about")))]
                      ),
                      _c("p", { staticClass: "indepthinfo" }, [
                        _vm._v(" " + _vm._s(_vm.$t("PHQ9.inDepthInfo1")) + " "),
                      ]),
                      _c("p", { staticClass: "grayreference" }, [
                        _vm._v(" " + _vm._s(_vm.$t("PHQ9.reference1")) + " "),
                      ]),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "line-height": "150%",
                            color: "grey",
                            "font-size": "12px",
                            "font-weight": "400",
                            "max-width": "500px",
                            "word-break": "break-word",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("PHQ9.reference2")) + " ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }