var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pb-0", attrs: { "fill-height": "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center pb-0" }, [
                _c("h2", [_vm._v(_vm._s(_vm.$t("fig.medication.title")))]),
                this.$store.state.api.isDirty
                  ? _c("p", [_c("MedicationPopup")], 1)
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-3" },
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "text-center col-10 offset-1 col-md-4 offset-md-4",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("fig.medication.description")) + " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center pb-0" }, [
                _c("h3", { staticClass: "mb-2" }, [
                  _vm._v(_vm._s(_vm.$t("fig.medication.yourSchedule"))),
                ]),
                _c("p", { staticClass: "mb-2 body-2 font-weight-light" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("fig.medication.yourScheduleDescription")) +
                      " "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm.hasNoLicense
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-5",
                          attrs: { color: "error", flat: "", rounded: "" },
                        },
                        [
                          _c("h3", { staticClass: "mb-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("fig.medication.invalidLicenseHeader")
                              )
                            ),
                          ]),
                          _c(
                            "p",
                            { staticClass: "mb-2 body-2 font-weight-light" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("fig.medication.invalidLicenseBody")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.hasReachedDoseLimit
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-5",
                          attrs: { color: "warning", flat: "", rounded: "" },
                        },
                        [
                          _c("h3", { staticClass: "mb-2" }, [
                            _vm._v(
                              _vm._s(_vm.$t("fig.medication.doseLimitHeader"))
                            ),
                          ]),
                          _c(
                            "p",
                            { staticClass: "mb-2 body-2 font-weight-light" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("fig.medication.reachedDoseLimit")
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showScheduleNewDoseButton
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-5",
                          staticStyle: { "max-width": "500px", margin: "auto" },
                          attrs: { color: "primary", flat: "", rounded: "" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mgl-fab mb-5 mt-2",
                              attrs: {
                                large: "",
                                depressed: "",
                                rounded: "",
                                color: "secondary",
                                disabled: _vm.hasReachedDoseLimit,
                                fab: "",
                              },
                              on: { click: _vm.showDisclaimerBeforePush },
                            },
                            [
                              _c("v-icon", { attrs: { "x-large": "" } }, [
                                _vm._v("mdi-plus"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            { staticClass: "text-uppercase caption ma-0" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("fig.medication.schedule")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.doses.length > 0
            ? _c(
                "v-row",
                _vm._l(_vm.doses, function (dose, i) {
                  return _c(
                    "v-col",
                    { key: i, attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticStyle: { "max-width": "500px", margin: "auto" },
                          attrs: {
                            text: "",
                            rounded: "",
                            color: "primaryDark",
                            flat: "",
                            disabled: _vm.hasNoLicense,
                          },
                        },
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { flat: "", color: "primary" } },
                            [
                              _c(
                                "v-toolbar-title",
                                { staticClass: "subtitle-1 font-weight-bold" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("cron2date")(
                                          dose.schedule,
                                          "hoursMinutes"
                                        )
                                      )
                                  ),
                                ]
                              ),
                              _c(
                                "ul",
                                { staticClass: "mgl-weekdays-list ml-4" },
                                _vm._l(_vm.weekDays, function (weekDay, i) {
                                  return _c("li", { key: i }, [
                                    _vm.doseIncludesWeekDay(
                                      dose.schedule,
                                      weekDay
                                    )
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "White--text font-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t(weekDay.label)) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "secondary--text" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t(weekDay.label))
                                            ),
                                          ]
                                        ),
                                  ])
                                }),
                                0
                              ),
                              _c("v-spacer"),
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    bottom: "",
                                    "close-on-click": "",
                                    transition: "slide-y-transition",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  { attrs: { icon: "" } },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { large: "" } },
                                                  [_vm._v("mdi-dots-vertical")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    {
                                      staticClass: "mgl-menu-list py-0",
                                      attrs: { tile: "", color: "primary" },
                                    },
                                    [
                                      _vm._l(
                                        _vm.medicationMenuItems,
                                        function (item, index) {
                                          return [
                                            !(
                                              item.id == "copy" &&
                                              _vm.hasReachedDoseLimit
                                            ) &&
                                            !(
                                              item.id == "remove" &&
                                              _vm.deleteDisabled
                                            )
                                              ? _c(
                                                  "v-list-item",
                                                  {
                                                    key: index,
                                                    on: {
                                                      click: function ($event) {
                                                        return item.method(dose)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      { class: item.class },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.title)
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-card-text", [
                            _c(
                              "ul",
                              _vm._l(dose.relations, function (relation, i) {
                                return _c("li", { key: i }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(relation.medication.name) +
                                      ", " +
                                      _vm._s(relation.medication.amount) +
                                      " " +
                                      _vm._s(relation.medication.unit) +
                                      ". "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: relation.amount > 0,
                                          expression: "relation.amount > 0",
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(relation.amount) +
                                          " " +
                                          _vm._s(
                                            relation.medication.form
                                              ? _vm.$t(
                                                  "fig.medication.unit." +
                                                    relation.medication.form
                                                )
                                              : _vm.$t(
                                                  "fig.medication.unit.pills"
                                                )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.showOptOutButtonValue
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      staticStyle: { "padding-left": "45px" },
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-5",
                          staticStyle: {
                            "max-width": "465px",
                            margin: "auto",
                            "padding-left": "25px !important",
                            "padding-bottom": "12px !important",
                            "padding-top": "0px !important",
                          },
                          attrs: {
                            color:
                              "primary v-timeline mgl-timeline v-timeline--align-top v-timeline--dense theme--dark",
                            flat: "",
                            rounded: "",
                          },
                          on: { click: _vm.optOutMedication },
                        },
                        [
                          _c(
                            "v-card-text",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "v-timeline-item__divider",
                                  staticStyle: { "margin-left": "-70px" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "v-timeline-item__dot" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "v-timeline-item__inner-dot primary",
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "v-icon notranslate mdi mdi-chevron-double-right theme--dark",
                                            attrs: { "aria-hidden": "true" },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    margin: "auto",
                                    "padding-left": "30px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("fig.medication.optOutMessage")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showDoneButton
            ? _c(
                "v-btn",
                {
                  staticClass: "my-5",
                  attrs: {
                    color: "secondary",
                    block: "",
                    large: "",
                    rounded: "",
                    depressed: "",
                  },
                  on: { click: _vm.doneWithScheduling },
                },
                [
                  _vm._v(_vm._s(_vm.$t("fig.medication.doneWithScheduling"))),
                  _c("v-icon", { staticClass: "pl-2" }, [
                    _vm._v("mdi-arrow-right"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }