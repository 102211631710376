var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "fill-height": "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "col-md-4 offset-md-4" },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "text-center pa-5 mt-12",
                          attrs: { text: "", color: "primaryDark", flat: "" },
                        },
                        [
                          _c("h2", { staticClass: "py-2" }, [
                            _vm._v(_vm._s(_vm.$t("addLicense.license"))),
                          ]),
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("addLicense.description"))),
                          ]),
                          _c("p", [_vm._v(_vm._s(_vm.$t("addLicense.label")))]),
                          _c(
                            "div",
                            {
                              class: _vm.isLicenseValid
                                ? "mx-5"
                                : "mx-5 mgl-expired",
                            },
                            [
                              _c("v-text-field", {
                                ref: "license",
                                staticClass: "mgl-append-green",
                                attrs: {
                                  placeholder: _vm.$t("addLicense.header"),
                                  outlined: "",
                                  rounded: "",
                                  color: "secondary",
                                  type: "text",
                                  disabled: _vm.isLicenseValid,
                                  "append-icon": _vm.isLicenseValid
                                    ? "mdi-check"
                                    : "",
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.length11,
                                    _vm.rules.format,
                                  ],
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.tempLicense = _vm.tempLicense.replace(
                                      /\s+/g,
                                      ""
                                    )
                                  },
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.preventDefault()
                                  },
                                  paste: _vm.handlePaste,
                                },
                                model: {
                                  value: _vm.tempLicense,
                                  callback: function ($$v) {
                                    _vm.tempLicense = $$v
                                  },
                                  expression: "tempLicense",
                                },
                              }),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-5",
                                  attrs: {
                                    rounded: "",
                                    depressed: "",
                                    block: "",
                                    "x-large": "",
                                    disabled:
                                      !_vm.allowLicense || !_vm.isFormValid,
                                    color: "secondary",
                                  },
                                  on: { click: _vm.addLicense },
                                },
                                [_vm._v(_vm._s(_vm.$t("addLicense.activate")))]
                              ),
                            ],
                            1
                          ),
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("addLicense.inEmail"))),
                          ]),
                          _c("BetaSignupLinks", {
                            attrs: { show: _vm.hasUser && !_vm.isLicenseValid },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.isLicenseValid
                ? _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c(
                          "a",
                          {
                            staticClass:
                              "float-right white--text text-decoration-underline",
                            attrs: {
                              color: "primary",
                              href: "mailto:support@mindforcegamelab.com",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("addLicense.askFor")))]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }