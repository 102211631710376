<template>
  <div>
    <v-container fluid fill-height>
      <v-row>
        <v-col class="text-center">
          <h2>{{ $t('fig.medication.scheduleTitle') }}</h2>
        </v-col>
      </v-row>
    </v-container>
    <v-stepper class="mgl-stepper" v-model="stepIndex" vertical>
      <!-- Reminder day and time -->
      <v-stepper-step :complete="stepIndex > 1" step="1" color="secondary" class="px-2 py-0">
        {{ $t('fig.medication.timeAndDay') }}
      </v-stepper-step>
      <v-expand-transition>
        <v-container class="ml-5 pl-3 pr-8 mgl-step-summary" v-show="stepIndex > 1">
          <v-card color="primaryDark" class="pa-2 mb-0" flat rounded @click="stepIndex = 1">
            <div class="body-2 d-flex justify-space-around font-weight-bold">
              <div class="pr-4">{{ reminderTime }}</div>
              <div>
                <ul class="mgl-weekdays-list ml-4">
                  <li v-for="(weekDay, i) in weekDays" :key="i">
                    <span
                      v-if="reminderDays.includes(weekDay.name)"
                      class="White--text font-weight-bold"
                    >
                      {{ weekDay.label }}
                    </span>
                    <span v-else class="secondary--text">{{ weekDay.label }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-expand-transition>

      <v-stepper-content
        step="1"
        :class="stepIndex > 1 ? 'ml-5 pl-3 pr-12 pt-12' : 'ml-5 pl-3 pr-12'"
      >
        <v-card color="primaryDark" flat class="mb-5 text-center col-md-6 offset-md-3">
          <v-card-text class="subtitle-1 white--text">
            {{ $t('fig.medication.timeDescription') }}
          </v-card-text>
          <div class="d-flex flex-column align-center">
            <v-dialog
              ref="dialog"
              v-model="timeModal"
              :return-value.sync="reminderTime"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="reminderTime"
                  placeholder="--:--"
                  readonly
                  color="accent"
                  class="mgl-time-select"
                  dense
                  outlined
                  rounded
                  hide-details=""
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="timeModal"
                color="secondary"
                class="mgl-time-picker"
                :allowed-minutes="allowedMinutes"
                format="24hr"
                flat
                v-model="reminderTime"
                full-width
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  large
                  @click="timeModal = !timeModal"
                  class="initial text-decoration-underline"
                >
                  {{ $t('generic.cancel') }}
                </v-btn>
                <v-btn
                  color="secondary"
                  rounded
                  depressed
                  large
                  class="px-5 mx-2"
                  @click="$refs.dialog.save(reminderTime)"
                >
                  {{ $t('generic.done') }}
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </div>
          <v-card-text class="pb-0 body-1 white--text">
            {{ $t('fig.medication.reminderWindow') }}
            <v-dialog v-model="dialog" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="mgl-reminder-btn">
                  <v-chip class="pa-1" x-small color="secondary">?</v-chip>
                </v-btn>
              </template>
              <v-card color="primary" class="pt-5">
                <h3 class="text-center mb-5">
                  {{ $t('fig.medication.reminders') }}
                </h3>

                <v-card-text class="body-2 white--text">
                  <span v-html="$t('fig.medication.reminderWindowHelpHtmlText')"></span>
                </v-card-text>
                <v-card-actions class="d-flex justify-center pb-5">
                  <v-btn
                    @click="dialog = false"
                    rounded
                    color="secondary"
                    class="px-15"
                    large
                    depressed
                    >{{ $t('generic.gotIt') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>

          <v-slider
            min="10"
            max="60"
            step="5"
            class="mx-5 mt-6 mb-3 mgl-range"
            thumb-label="always"
            v-model="reminderWindow"
            color="secondary"
            hide-details
          >
            <template v-slot:thumb-label="{ value }">
              <div class="mgl-thumb-label text-center">{{ value }} min</div>
            </template>
          </v-slider>

          <v-card-text class="subtitle-1 white--text">
            {{ $t('fig.medication.dayDescription') }}
          </v-card-text>
          <v-select
            :items="dayOptions"
            :placeholder="$t('fig.medication.selectDays')"
            dense
            outlined
            rounded
            color="accent"
            item-color="accent"
            class="mx-5"
            hide-details
            v-model="dayOption"
          ></v-select>
          <v-container>
            <v-row>
              <v-col v-if="dayOption === 'choose'" class="px-0">
                <v-chip-group multiple class="px-2 mgl-reminder-chips" v-model="reminderDays">
                  <v-chip
                    v-for="day in weekDays"
                    :key="day.name"
                    :value="day.name"
                    active-class="secondary"
                    class="ma-0"
                  >
                    {{ day.label }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-col style="display: flex; flex-direction: row; justify-content: space-around">
          <v-btn
            style="width: 400px; margin: auto; min-width: 280px"
            color="secondary"
            rounded
            depressed
            large
            :disabled="reminderDays.length === 0 || reminderTime == '' || reminderTimeInValid"
            @click="stepIndex = 2"
            >{{ $t('generic.next') }}</v-btn
          >
        </v-col>
      </v-stepper-content>

      <!-- Medications -->
      <v-stepper-step :complete="stepIndex > 2" step="2" color="secondary" class="px-2 t-12">
        {{ $t('fig.medication.addMedication') }}
      </v-stepper-step>

      <v-dialog v-model="dialogConfirm" max-width="290">
        <v-card>
          <v-card-title>
            {{ $t('fig.medication.removeMedication') }}
          </v-card-title>

          <v-card-text>
            {{ $t('fig.medication.removeMedicationConfirmation') }}
          </v-card-text>

          <v-card-actions class="pa-5">
            <v-spacer></v-spacer>

            <v-btn color="secondary" rounded depressed dense @click="removeMedication">
              {{ $t('generic.yes') }}
            </v-btn>
            <v-btn color="grey darken-3" rounded depressed dense @click="dialogConfirm = false">
              {{ $t('generic.no') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-stepper-content step="2" class="ml-5 pl-3 pt-0 pr-12">
        <v-form ref="medicationsForm">
          <transition-group name="mgl-medications-list" tag="div">
            <v-card
              v-for="(medication, i) in medications"
              :key="i + 1"
              color="primaryDark"
              flat
              rounded
              class="pa-0 text-center mgl-medications-card col-md-6 offset-md-3"
            >
              <div class="position-relative ma-3 rounded">
                <v-btn icon @click="confirmRemove(i)" class="mgl-delete-medication">
                  <v-chip class="px-0 py-3 mgl-delete-btn" x-small color="secondary"
                    ><v-icon small>mdi-close</v-icon></v-chip
                  >
                </v-btn>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                        :placeholder="$t('fig.medication.medicationPlaceholder')"
                        outlined
                        rounded
                        dense
                        hide-details
                        color="accent"
                        background-color="primary"
                        type="text"
                        class="pb-3"
                        :rules="[rules.notEmpty, rules.maxChars45]"
                        v-model="medication.name"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row no-gutters class="mgl-medication-selects d-flex justify-space-between">
                    <v-col cols="6" class="d-flex">
                      <v-text-field
                        outlined
                        dense
                        placeholder="0"
                        color="accent"
                        type="number"
                        class="mgl-shaped-left"
                        background-color="primary"
                        hide-details
                        :rules="[rules.min1, rules.max99999, rules.notEmpty]"
                        v-model="medication.weight"
                      ></v-text-field>
                      <v-select
                        outlined
                        :items="weightUnits"
                        dense
                        color="accent"
                        item-color="accent"
                        background-color="primary"
                        class="mgl-truncated mgl-shaped-right mr-1"
                        hide-details
                        :value="medication.weightUnit"
                        v-model="medication.weightUnit"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" class="d-flex">
                      <v-text-field
                        outlined
                        dense
                        placeholder="1"
                        color="accent"
                        type="number"
                        background-color="primary"
                        :class="
                          medication.quantityUnit == ''
                            ? 'mgl-shaped-left ml-1 mgl-invisible'
                            : 'mgl-shaped-left ml-1'
                        "
                        hide-details
                        :rules="[rules.max100, rules.min0, rules.notEmpty]"
                        v-model="medication.quantity"
                        :disabled="medication.quantityUnit == ''"
                      ></v-text-field>
                      <v-select
                        outlined
                        :items="quantityUnits"
                        dense
                        color="accent"
                        item-color="accent"
                        background-color="primary"
                        hide-details
                        class="mgl-truncated mgl-shaped-right"
                        v-model="medication.quantityUnit"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-card>
          </transition-group>
        </v-form>
        <v-card
          v-if="hasReachedMedicationLimit"
          color="warning"
          flat
          rounded
          class="pa-2 mb-5 col-md-6 offset-md-3"
        >
          <v-row>
            <v-flex class="pa-3 col-md-2">
              <v-img
                style="opacity: 0.5"
                class="mx-1"
                src="/warningCentered.png"
                max-height="50"
                contain
              ></v-img>
            </v-flex>
            <v-flex class="pa-3 col-md-10">
              <v-card-text style="color: #eee" class="text-center">
                <h3>{{ $t('fig.medication.reachedMedicationLimit') }}</h3>
              </v-card-text>
            </v-flex>
          </v-row>
        </v-card>
        <v-card
          color="primaryDark"
          flat
          rounded
          class="pa-0 text-center mgl-medications-card col-md-6 offset-md-3"
          v-else
        >
          <v-container style="display: flex; flex-direction: row; justify-content: space-around">
            <v-row>
              <v-col class="text-center">
                <v-btn
                  rounded
                  depressed
                  fab
                  class="secondary mgl-fab"
                  small
                  @click="addMedication"
                  icon
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <p class="text-uppercase caption ma-0 pt-3 d-flex align-center justify-center">
                  <template v-if="medications.length === 0">
                    {{ $t('fig.medication.addAMedication') }}
                  </template>
                  <template v-else>
                    {{ $t('fig.medication.addAnotherMedication') }}
                  </template>
                  <v-chip outlined small color="secondary" class="ml-1">
                    <span class="white--text">
                      {{ reminderTime }}
                    </span>
                  </v-chip>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-col style="display: flex; flex-direction: row; justify-content: space-around">
          <v-btn
            style="width: 400px; margin: auto; min-width: 200px"
            color="secondary"
            rounded
            large
            depressed
            @click="saveMedications"
            :disabled="medications.length === 0"
            class="mb-3"
          >
            {{ $t('fig.medication.saveDose') }}
          </v-btn>
        </v-col>
        <v-btn
          color="red lighten-2"
          text
          class="float-right initial text-decoration-underline"
          @click="confirmCancel"
          >{{ $t('generic.cancel') }}</v-btn
        >
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
import { fetchRsaKeysFromLocalStorage } from '../utils/crypto';
import { getCronExpr, hasDayOverlap } from '../utils/cron';
import { FIG_APPID } from '../utils/constants';
import { displayError, setLoading, displayMessage } from '../utils/loading';
import { apiDispatch } from '../utils/dispatch';
import { postEvent } from '../api/unityAnalytics';
import { getCurrentAdherence, getJournalDayIndex } from '../api/me';

/**
 * @typedef {{medicationId: string, name: string, weight: string, weightUnit: string, quantity: string, quantityUnit: string}} MedicationData
 */

export default {
  name: 'Schedule',
  data() {
    return {
      viewId: '006',
      activeMedicationIndex: null,
      bypassWatcher: false,
      dayOption: null,
      dialog: false,
      dialogConfirm: false,
      doseIndexToEditOnSave: null,
      savingDoses: false,
      indexReminderDays: [],
      lastReminderWindow: [],
      /** @type {MedicationData[]} */
      medications: [],
      relations: [],
      reminderDays: [],
      reminderTime: '',
      reminderWindow: 35,
      stepIndex: 1,
      timeModal: false,
      allowedMinutes: Array.from({ length: 60 }, (_, i) => i),
      weekDayButtons: false,
      rules: {
        max100: (v) => v <= 100 || this.$i18n.t('validation.max100'),
        max99999: (v) => v <= 99999 || this.$i18n.t('validation.tooHigh'),
        maxChars45: (v) => v.length <= 45 || this.$i18n.t('validation.maxChars'),
        min0: (v) => v >= 0 || this.$i18n.t('validation.min0'),
        min1: (v) => v >= 1 || this.$i18n.t('validation.min1'),
        notEmpty: (v) => !!v || this.$i18n.t('validation.fieldNotEmpty'),
      },
    };
  },
  created() {
    if (this.$route.query.edit !== undefined) {
      const sensorId = this.$route.query.edit;
      const isDuplicate = this.$route.query.duplicate !== undefined;
      this.editStateDose(sensorId, isDuplicate);
    }
    if (
      // Redirect if user has reached dose limit
      this.$store.state.api.doses.filter((dose) => dose.deleted_at === undefined).length >= 4 &&
      this.$route.query.edit === undefined
    ) {
      displayMessage(this.$store, this.$i18n.t('fig.medication.reachedDoseLimit'), 'warning');
      this.$router.push({ name: 'medications' });
    }
    this.savingDoses = false;
  },
  computed: {
    hasReachedMedicationLimit() {
      return this.medications.length >= 4;
    },
    weightUnits() {
      return ['g', 'mg', 'µg'];
    },
    quantityUnits() {
      return [
        { text: '---', value: '' },
        { text: this.$i18n.t('fig.medication.unit.pills'), value: 'pills' },
      ];
    },
    weekDays() {
      return [
        { name: 'mon', label: this.$i18n.t('generic.weekdays.short.1'), index: 1 },
        { name: 'tue', label: this.$i18n.t('generic.weekdays.short.2'), index: 2 },
        { name: 'wed', label: this.$i18n.t('generic.weekdays.short.3'), index: 3 },
        { name: 'thu', label: this.$i18n.t('generic.weekdays.short.4'), index: 4 },
        { name: 'fri', label: this.$i18n.t('generic.weekdays.short.5'), index: 5 },
        { name: 'sat', label: this.$i18n.t('generic.weekdays.short.6'), index: 6 },
        { name: 'sun', label: this.$i18n.t('generic.weekdays.short.7'), index: 0 },
      ];
    },
    dayOptions() {
      return [
        {
          text: this.$i18n.t('fig.medication.everyDay'),
          value: 'everyDay',
        },
        {
          text: this.$i18n.t('fig.medication.weekdays'),
          value: 'weekdays',
        },
        {
          text: this.$i18n.t('fig.medication.weekends'),
          value: 'weekends',
        },
        {
          text: this.$i18n.t('fig.medication.choose'),
          value: 'choose',
        },
      ];
    },
    // A dose is an API object containing the scheduled medication
    dose() {
      return {
        schedule: getCronExpr(this.reminderTime, this.indexReminderDays),
        durationInMinutes: this.reminderWindow,
        relations: this.relations,
        prevSensorId: null,
      };
    },
    reminderTimeInValid() {
      const remTime = this.reminderTime;
      if (!remTime) {
        return false;
      }
      const existingDoses = this.$store.state.api.doses;
      if (existingDoses.length === 0) {
        return false;
      }
      const cronExpr = getCronExpr(remTime, this.indexReminderDays);
      const editingIndex = this.doseIndexToEditOnSave;
      for (let i = 0; i < existingDoses.length; i += 1) {
        const existingDose = existingDoses[i];
        if (
          existingDose.deleted_at === undefined &&
          (editingIndex === null || editingIndex !== i)
        ) {
          const { schedule } = existingDose;
          if (hasDayOverlap(schedule, cronExpr)) {
            console.log(`Dose ${cronExpr}  has overlap with existing schedule: ${schedule}`);
            // displayError(this.$store, this.$i18n.t('validation.doseTimeOverlap'));
            return true;
          }
        }
      }
      return false;
    },
  },
  methods: {
    confirmCancel() {
      this.$root
        .$confirm(
          this.$i18n.t('generic.areYouSure'),
          this.$i18n.t('fig.medication.confirmCancel'),
          {
            disableCancel: false,
            actionButtonI18nKey: 'generic.yes',
          },
        )
        .then((confirm) => {
          if (confirm) {
            this.$router.push({ name: 'medications' });
          }
        });
    },
    editStateDose(sensorId, isDuplicate) {
      // Lookup dose
      const index = this.$store.state.api.doses.findIndex((d) => d.sensorId === sensorId);
      if (index >= 0) {
        // Get dose
        const dose = this.$store.state.api.doses[index];
        // Set dose to this view data
        this.reminderTime = this.$options.filters.cron2date(dose.schedule, 'hoursMinutes');
        this.reminderWindow = dose.durationInMinutes;
        this.reminderDays = this.$options.filters.cron2date(dose.schedule, 'weekDays');
        this.dayOption = 'choose';
        // Extract medications from dose relations
        dose.relations.forEach((relation) => {
          const relationQuantity = parseFloat(parseFloat(relation.amount).toFixed(2));
          this.medications.push({
            quantity: relationQuantity === 0 ? 1 : relationQuantity,
            quantityUnit: relationQuantity === 0 ? '' : relation.medication.form,
            id: relation.medication.id,
            name: relation.medication.name.trim(),
            weightUnit: relation.medication.unit,
            weight: parseInt(relation.medication.amount, 10),
          });
        });

        if (isDuplicate) {
          displayMessage(this.$store, this.$i18n.t('fig.medication.duplicatingDose'));
        } else {
          this.doseIndexToEditOnSave = index;
          displayMessage(this.$store, this.$i18n.t('fig.medication.editingDose'));
        }
      } else {
        displayMessage(this.$store, this.$i18n.t('fig.medication.noSuchDose'), 'success');
        this.$router.push({ name: 'medications' });
      }
    },
    addMedication() {
      this.medications.push({
        medicationId: '', // Medication id is set by API when new medication is posted
        name: '',
        weight: '',
        weightUnit: 'mg',
        quantity: '1',
        quantityUnit: 'pills',
      });
    },
    confirmRemove(index) {
      this.activeMedicationIndex = index;
      this.dialogConfirm = true;
    },
    removeMedication() {
      this.dialogConfirm = false;
      this.medications.splice(this.activeMedicationIndex, 1);
    },
    orderByScheduled(a, b) {
      const aTime = this.$options.filters.cron2date(a.schedule, 'hoursMinutes');
      const bTime = this.$options.filters.cron2date(b.schedule, 'hoursMinutes');
      if (aTime < bTime) {
        return -1;
      }
      if (aTime > bTime) {
        return 1;
      }
      return 0;
    },
    async sendAdherenceAnalyticsEvent() {
      const journalDay = getJournalDayIndex(this.$store);
      if (journalDay === undefined) {
        return;
      }
      const adherence = await getCurrentAdherence(this.$store, this.$$router, this.viewId);
      await postEvent(this.$store, 'MedicationLogged', {
        adherence,
        journalDay,
      });
    },
    async saveMedications() {
      if (!this.$refs.medicationsForm.validate()) {
        displayError(this.$store, new Error(this.$i18n.t('validation.invalidMedication')));
        return false;
      }
      this.savingDoses = true;
      setLoading(this.$store, true, 'medication.title', 'medication.encryptingData');
      try {
        // If dose is in edit mode, remove the old dose from state first
        const newDose = this.dose;
        if (this.doseIndexToEditOnSave !== null) {
          const [editedDose] = this.$store.state.api.doses.splice(this.doseIndexToEditOnSave, 1);
          newDose.prevSensorId = editedDose.sensorId;
        }

        const stateDoses = this.$store.getters['api/doses'];
        const doses = [...stateDoses, ...[newDose]];
        // Important: save doses ordered by reminder time, will cause errors in app if unordered
        doses.sort(this.orderByScheduled);

        const rsaKeys = await fetchRsaKeysFromLocalStorage(this.$store);
        await apiDispatch(this.$store, this.$router, 'addDoses', {
          publicKey: rsaKeys.publicKey,
          userId: this.$store.state.api.me.id,
          appId: FIG_APPID,
          doses,
          viewId: this.viewId,
        });

        // Send event to Unity Analytics on first added medication
        if (
          this.$store.state.api.doses.length === 1 &&
          this.$store.state.api.me.onboarded === false
        ) {
          await postEvent(this.$store, 'OnboardingStepCompleted', {
            onboardingStep: 'medication',
          });
        }
        await this.sendAdherenceAnalyticsEvent();
        displayMessage(this.$store, this.$i18n.t('fig.medication.medicationsSaved'), 'success');
        this.$router.push({ name: 'medications' });
      } catch (error) {
        displayError(this.$store, error);
        this.savingDoses = false;
      }
      this.savingDoses = false;
      return true;
    },
  },
  watch: {
    reminderTimeInValid(newValue) {
      if (newValue && this.savingDoses === false) {
        displayError(this.$store, new Error(this.$i18n.t('validation.doseTimeOverlap')));
      }
    },
    stepIndex(newValue) {
      // Add at least one medication
      if (newValue === 2 && this.medications.length === 0) {
        this.addMedication();
      }
    },
    medications: {
      deep: true,
      /**
       *@param {MedicationData[]} currentMedications
       */
      handler(currentMedications) {
        this.relations = [];
        currentMedications.forEach((medication) => {
          this.relations.push({
            amount:
              medication.quantityUnit === ''
                ? 0
                : parseFloat(parseFloat(medication.quantity).toFixed(2)),
            medication: {
              // id: medication.medicationId,
              amount: parseInt(medication.weight, 10),
              unit: medication.weightUnit,
              name: medication.name.trim(),
              form: medication.quantityUnit === '' ? 'pills' : medication.quantityUnit,
            },
          });
        });
      },
    },
    dayOption(newValue) {
      if (newValue === 'everyDay') {
        this.reminderDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
      }
      if (newValue === 'weekdays') {
        this.reminderDays = ['mon', 'tue', 'wed', 'thu', 'fri'];
      }
      if (newValue === 'weekends') {
        this.reminderDays = ['sat', 'sun'];
      }
    },
    reminderDays(newValue) {
      this.indexReminderDays = [];
      if (newValue.length >= 7) {
        this.indexReminderDays.push('*');
        return;
      }
      newValue.forEach((day) => {
        this.weekDays.forEach((weekDay) => {
          if (weekDay.name === day) {
            this.indexReminderDays.push(weekDay.index);
          }
        });
      });
    },
  },
};
</script>
