/**
 * Returns true if object has defined property with name
 * @param {{[key:string]: any}} obj object
 * @param {string} name property name
 * @returns {boolean}
 */
function hasDefinedProperty(obj, name) {
  return Object.prototype.hasOwnProperty.call(obj, name);
}

module.exports = {
  hasDefinedProperty,
};
