/**
 * Get clipboard data from event
 * @param {*} event
 * @param {boolean} [toLowerCase] - if true, return data in lower case, default is true
 * @returns
 */
export function getPastedData(event, toLowerCase = true) {
  const { clipboardData } = event;
  if (clipboardData === null) {
    return null;
  }
  const pastedText = clipboardData.getData('text/plain');
  const formattedText = pastedText.replace(/\s+/g, '');
  if (toLowerCase) {
    return formattedText.toLowerCase();
  }
  return formattedText;
}
