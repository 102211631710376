var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pb-0", attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center pb-0" }, [
                _c("h2", [_vm._v(_vm._s(_vm.$t("reports.header")))]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "text-center col-10 offset-1 col-md-4 offset-md-4",
                },
                [_vm._v(" " + _vm._s(_vm.$t("reports.description")) + " ")]
              ),
            ],
            1
          ),
          _c("v-select", {
            staticClass: "pa-5",
            staticStyle: { "max-width": "500px", margin: "auto" },
            attrs: {
              flat: "",
              items: _vm.reportOptions,
              "item-text": "label",
              placeholder: _vm.$t("reports.reportlist"),
              dense: "",
              filled: "",
              "background-color": "primary",
              color: "primary",
              solo: "",
              "return-object": "",
            },
            on: { change: _vm.reportSelected },
            model: {
              value: _vm.selectedReport,
              callback: function ($$v) {
                _vm.selectedReport = $$v
              },
              expression: "selectedReport",
            },
          }),
        ],
        1
      ),
      _vm.adherenceUnlocked
        ? _c("ReportMedication", {
            key: _vm.getKey("adherence"),
            attrs: { selectedReport: _vm.selectedReport, id: "medication" },
          })
        : _vm._e(),
      _vm.moodUnlocked
        ? _c("ReportMood", {
            key: _vm.getKey("mood"),
            attrs: { selectedReport: _vm.selectedReport, id: "mood" },
          })
        : _vm._e(),
      _vm.mealUnlocked
        ? _c("ReportMeal", {
            key: _vm.getKey("meal"),
            attrs: { selectedReport: _vm.selectedReport, id: "meal" },
          })
        : _vm._e(),
      _vm.sleepUnlocked
        ? _c("ReportSleep", {
            key: _vm.getKey("sleep"),
            attrs: { selectedReport: _vm.selectedReport, id: "sleep" },
          })
        : _vm._e(),
      _vm.stepsUnlocked
        ? _c("ReportSteps", {
            key: _vm.getKey("exercise"),
            attrs: { selectedReport: _vm.selectedReport, id: "steps" },
          })
        : _vm._e(),
      _vm.downloadAvailable
        ? _c(
            "v-row",
            { staticClass: "pa-5" },
            [
              _c(
                "v-col",
                { staticClass: "col-md-4 offset-md-4 text-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "px-15 uppercase",
                      attrs: {
                        color: "secondary",
                        rounded: "",
                        depressed: "",
                        "x-large": "",
                      },
                      on: { click: _vm.PDFdownloadAsync },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("reports.downloadPDF")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "pdfclass",
        staticStyle: { display: "none" },
        attrs: { id: "pdfreport" },
      },
      [
        _c("div", { attrs: { id: "pdfpage1" } }),
        _c("div", { attrs: { id: "pdfpage2" } }),
        _c("div", { attrs: { id: "pdfpage3" } }),
        _c("div", { attrs: { id: "pdfpage4" } }),
        _c("div", { attrs: { id: "pdfpage5" } }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }