var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          staticClass: "mgl-hero d-flex align-center justify-center",
          attrs: { flat: "", color: "primary" },
        },
        [_c("Logo")],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center mb-3" }, [
                _c("h2", [_vm._v(_vm._s(_vm.$t("verifyEmail.title")))]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center mb-3" }, [
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("verifyEmail.subTextHtml", { email: this.email })
                    ),
                  },
                }),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-5",
                      attrs: {
                        text: "",
                        rounded: "",
                        color: "primary",
                        flat: "",
                      },
                    },
                    [
                      _c("v-card-text", { staticClass: "text-center" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("verifyEmail.checkButtonHeader")) +
                            " "
                        ),
                      ]),
                      _c(
                        "v-card-actions",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-center initial truncated",
                              staticStyle: { width: "400px", margin: "auto" },
                              attrs: {
                                disabled: _vm.requestInProgress,
                                "x-large": "",
                                depressed: "",
                                rounded: "",
                                color: "secondary",
                              },
                              on: { click: _vm.checkAgain },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("verifyEmail.checkButtonText"))
                              ),
                            ]
                          ),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center mt-5 mb-3" }, [
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("verifyEmail.resendButtonHeaderHtml")
                    ),
                  },
                }),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center mb-3" }, [
                _c(
                  "a",
                  {
                    staticClass: "secondary--text text--lighten-5",
                    on: { click: _vm.resendValidationLink },
                  },
                  [_vm._v(_vm._s(_vm.$t("verifyEmail.resendButtonText")))]
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-12" },
            [
              _c("v-col", { staticClass: "col-md-6 text-right" }, [
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("verifyEmail.needMoreHelp")),
                  },
                }),
              ]),
              _c("v-col", { staticClass: "col-md-6" }, [
                _c(
                  "a",
                  {
                    staticClass: "secondary--text",
                    attrs: { href: "mailto:support@mindforcegamelab.com" },
                  },
                  [_vm._v(_vm._s(_vm.$t("verifyEmail.contactSupportText")))]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }