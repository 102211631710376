var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center" }, [
                _c("h2", [_vm._v(_vm._s(_vm.$t("start.welcome")))]),
              ]),
            ],
            1
          ),
          _vm.onboarded && _vm.hasShortcuts
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "col-md-6 offset-md-3 mt-6" },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "text-center pa-5",
                          staticStyle: { "max-width": "500px", margin: "auto" },
                          attrs: {
                            text: "",
                            rounded: "",
                            color: "primary",
                            flat: "",
                          },
                        },
                        [
                          _c("p", { staticClass: "pb-5" }, [
                            _vm._v(_vm._s(_vm.$t("steps.usefulShortcuts"))),
                          ]),
                          _vm.hasLicense && _vm.medicationRequired
                            ? _c(
                                "div",
                                { staticClass: "mt-5" },
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/medications" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "initial truncated",
                                          staticStyle: { width: "400px" },
                                          attrs: {
                                            color: "secondary",
                                            depressed: "",
                                            rounded: "",
                                            "x-large": "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "steps.changeMedicationSchedule"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.showLicense
                            ? _c(
                                "div",
                                { staticClass: "mt-5" },
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/licenses" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "initial truncated",
                                          staticStyle: { width: "400px" },
                                          attrs: {
                                            rounded: "",
                                            depressed: "",
                                            "x-large": "",
                                            color: "secondary",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("steps.updateLicense")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isPlaytient && this.phqEnabled
                            ? _c(
                                "div",
                                { staticClass: "mt-5" },
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/phq9-start" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "initial truncated",
                                          staticStyle: { width: "400px" },
                                          attrs: {
                                            rounded: "",
                                            depressed: "",
                                            "x-large": "",
                                            color: "secondary",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("steps.selfassessment")
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.hasLicense || !this.isPlaytient
                            ? _c(
                                "div",
                                { staticClass: "mt-5" },
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: "/team" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "initial truncated",
                                          staticStyle: { width: "400px" },
                                          attrs: {
                                            rounded: "",
                                            depressed: "",
                                            "x-large": "",
                                            color: "secondary",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("steps.teams")))]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.onboarded
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "col-md-6 offset-md-3" },
                    [
                      _c(
                        "v-timeline",
                        {
                          staticClass: "mgl-timeline",
                          attrs: { "align-top": "", dense: "" },
                        },
                        _vm._l(_vm.steps, function (step, i) {
                          return _c(
                            "v-timeline-item",
                            {
                              key: i,
                              attrs: {
                                icon:
                                  _vm.currentStep - 1 > i ? "mdi-check" : "",
                                color: _vm.stepColor(i),
                                "fill-dot": "",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "d-flex justify-center flex-column",
                                  attrs: {
                                    rounded: "",
                                    flat: "",
                                    color: "primary",
                                    "min-height": "78px",
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "h2",
                                        {
                                          style: {
                                            "margin-right": "70px",
                                            "margin-left": "-130px",
                                            color: "white",
                                            "padding-top":
                                              _vm.currentStep - 1 <= i
                                                ? "10px"
                                                : "0",
                                          },
                                        },
                                        [_vm._v(" Step " + _vm._s(i + 1) + " ")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.currentStep - 1 > i
                                                ? step.textCompleted
                                                : step.text
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm.currentStep - 2 < i
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mx-5 pb-5 d-flex justify-space-between",
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "initial truncated",
                                              attrs: {
                                                rounded: "",
                                                depressed: "",
                                                block: !step.skippable,
                                                color:
                                                  _vm.currentStep > i
                                                    ? "secondary"
                                                    : "",
                                                disabled: _vm.currentStep <= i,
                                              },
                                              on: { click: step.method },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(step.buttonText) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          step.skippable
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "initial truncated",
                                                  attrs: {
                                                    rounded: "",
                                                    depressed: "",
                                                    color:
                                                      _vm.currentStep > i
                                                        ? "secondary"
                                                        : "",
                                                    disabled:
                                                      _vm.currentStep <= i,
                                                  },
                                                  on: { click: step.skip },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("steps.skip")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }