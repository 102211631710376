<template>
  <v-container>
    <v-card
      style="max-width: 500px; margin: auto"
      class="primary pt-8 pb-4 px-5"
      elevation="0"
      rounded="xl"
    >
      <h2 class="ma-0 text-center">
        {{ $t('reports.medication') }}
      </h2>

      <p class="pa-5 text-center subtitle" v-html="medicationsDescription" />

      <section class="graph">
        <div class="medications">
          <div class="yaxis">
            <div v-for="(d, i) in reportData" :key="`d${i}`">{{ d.userTime }}</div>
          </div>
          <div class="checks-content">
            <div v-for="(c, i) in reportData" :key="`c${i}`">
              <div class="check" v-for="(m, i) in c.values" :key="`cc${i}`">
                <div v-if="m === undefined" class="checked-transparent"></div>
                <div v-else :class="{ checked: !!m, unchecked: m === false }">
                  <v-icon v-if="m == true" dark>mdi-check</v-icon>
                  <v-icon v-else-if="m == false" dark>mdi-close</v-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="xaxis">
          <div v-for="(d, i) in this.selectedReport.xAxis" :key="`d${i}`">
            <p>
              {{ d.day }} <br />
              {{ d.date }}
            </p>
          </div>
        </div>
      </section>
    </v-card>
  </v-container>
</template>
<script>
import { getWeeklySensorData } from '../../progress/routines';
import {
  shortTimeStrFromISODateStr,
  shortTimeStrFromISODateStrInTimeZone,
} from '../../utils/datetime';

export default {
  name: 'ReportMedication',
  props: {
    selectedReport: Object, // declare the medicationData prop as an object
  },
  data() {
    return {
      viewId: '016',
      loaded: false,
      yAxis: [],
      reportData: [],
    };
  },
  computed: {
    medicationsDescription() {
      if (this.loaded === false) {
        return this.$i18n.t('reports.loading');
      }
      const avgDoses = this.calculateAverageTakenDoses(this.reportData);
      if (avgDoses === null) {
        return this.$i18n.t('reports.noData');
      }
      return this.$t('reports.loggedDosesHtml', {
        medicationPercentage: avgDoses,
      });
    },
  },
  methods: {
    calculateAverageTakenDoses(arr) {
      if (!arr) return null;
      const filteredValues = this.reportData.flatMap((obj) =>
        obj.values.filter((value) => value !== undefined),
      );
      const trueValues = filteredValues.filter((value) => value === true);
      const average = trueValues.length > 0 ? trueValues.length / filteredValues.length : 0;
      return average ? Math.round(average * 100) : 0;
    },
  },
  async mounted() {
    const adherenceData = await getWeeklySensorData(
      this.$store,
      this.$router,
      this.viewId,
      `adherence`,
      this.selectedReport.week,
    );
    const adherence = adherenceData
      .flatMap((arr) => arr) // Flatten the array of arrays into a single array
      .reduce((acc, { targetAt }) => {
        if (targetAt === null) {
          return acc; // Skip objects with null targetAt
        }
        const originalTime = shortTimeStrFromISODateStr(targetAt);
        if (!acc.some((obj) => obj.originalTime === originalTime)) {
          const userTime = shortTimeStrFromISODateStrInTimeZone(
            targetAt,
            this.selectedReport.userTimezone,
          );
          acc.push({ originalTime, userTime });
        }
        return acc;
      }, []);

    this.reportData = adherence.map((med) => {
      const { originalTime, userTime } = med;
      const values = adherenceData.flatMap((data) => {
        const match = data.find(
          (target) =>
            target.targetAt != null && shortTimeStrFromISODateStr(target.targetAt) === originalTime,
        );
        if (!match) {
          return undefined;
        }
        if (match.value !== null && match.value !== undefined) {
          return match.value === 1;
        }
        return null;
      });
      return { originalTime, userTime, values };
    });
    this.loaded = true;
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  font-size: larger;
}

.graph {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.medications {
  display: flex;
  flex: 1;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    margin-bottom: 10px;
    > div:nth-child(odd) {
      background: #56369a;
    }
  }

  .yaxis {
    min-width: 80px;
    > div {
      flex: 1;
      display: flex;
      align-items: center;
      padding-left: 10px;
    }
  }

  .checks-content {
    display: flex;
    flex: 6;
    > div {
      padding: 10px 0;
      display: flex;
    }
    .check {
      margin: auto;
      width: 100%;
      max-width: 38px;
      height: 38px;
      margin: auto;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: #301d59;
      }
      @media (max-width: 600px) {
        max-width: 25px;
        height: 25px;
      }
      .checked-transparent {
        background-color: transparent;
      }
    }
    .checked {
      background: #5db6bf !important;
    }
    .unchecked {
      background: #9b6ed4 !important;
    }
  }
}

.xaxis {
  display: flex;
  text-align: center;
  padding-left: 80px;
  width: 100%;
  > div {
    position: relative;
    flex: 1;
  }
  p {
    margin-bottom: 0;
  }
}
</style>
