import { apiDispatch } from '../utils/dispatch';
import { fetchRsaKeysFromLocalStorage } from '../utils/crypto';
import { FIG_APPID } from '../utils/constants';

/**
 * Get user progress
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {string} viewId
 * @param {Date} [fromTimeStamp]
 * @returns {Promise<object>}
 */
export async function getAllSensorData(store, router, viewId, fromTimeStamp) {
  const rsaKeys = await fetchRsaKeysFromLocalStorage(store);
  const { privateKey } = rsaKeys;
  const payload = { privateKey, appId: FIG_APPID, viewId };
  if (fromTimeStamp) {
    payload.fromTimeStamp = fromTimeStamp;
  }
  return apiDispatch(store, router, 'getAllSensorData', payload);
}
