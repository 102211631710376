var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "col-md-4 offset-md-4 text-center" },
        [
          _c(
            "v-btn",
            {
              staticClass: "px-15 uppercase",
              attrs: {
                color: "secondary",
                rounded: "",
                depressed: "",
                "x-large": "",
              },
              on: { click: _vm.reroutePHQ9 },
            },
            [_c("b", [_vm._v(" " + _vm._s(_vm.$t("PHQ9.start")) + " ")])]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }