var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          staticClass: "mgl-hero d-flex align-center justify-center",
          attrs: { flat: "", color: "primary" },
        },
        [_c("Logo")],
        1
      ),
      _c("SimpleLocalePicker", { staticClass: "mgl-simple-locale" }),
      _c(
        "v-main",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          key: "1",
        },
        [
          _c(
            "v-form",
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "fill-height": "" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "col-md-6 offset-md-3 mt-6" },
                        [
                          _c(
                            "v-card",
                            { attrs: { color: "primaryDark" } },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass:
                                    "pa-5 d-flex align-center justify-center",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("resetPassword.reset")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "pa-5 d-flex align-center justify-center text-center",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("resetPassword.information")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-form",
                                {
                                  ref: "emailform",
                                  staticClass: "pa-5",
                                  attrs: {
                                    color: "primary",
                                    flat: "",
                                    rounded: "",
                                  },
                                  model: {
                                    value: _vm.isEmailValid,
                                    callback: function ($$v) {
                                      _vm.isEmailValid = $$v
                                    },
                                    expression: "isEmailValid",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    ref: "emailinfo",
                                    staticClass:
                                      "mgl-prepend-absolute mgl-noborder",
                                    attrs: {
                                      name: "email",
                                      placeholder: _vm.$t("login.email"),
                                      outlined: "",
                                      rounded: "",
                                      color: "accent",
                                      "prepend-inner-icon": "mdi-email-outline",
                                      type: "text",
                                      rules: [_vm.rules.email],
                                      "validate-on-blur": "",
                                      "background-color": "primary",
                                    },
                                    on: {
                                      input: _vm.updateEmail,
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "space",
                                            32,
                                            $event.key,
                                            [" ", "Spacebar"]
                                          )
                                        ) {
                                          return null
                                        }
                                        $event.preventDefault()
                                      },
                                      paste: _vm.handlePaste,
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function ($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email",
                                    },
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "px-10 dark",
                                      attrs: {
                                        color: "secondary",
                                        rounded: "",
                                        depressed: "",
                                        disabled: !_vm.isEmailValid,
                                        block: "",
                                        "x-large": "",
                                      },
                                      on: { click: _vm.emailSent },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("resetPassword.sendEmail")
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-footer",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show,
                  expression: "show",
                },
              ],
              key: "2",
              staticClass: "text-center",
              attrs: { color: "background" },
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-15" },
                    [
                      _c("v-col", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("resetPassword.needHelp")) + " "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "pl-2",
                            attrs: {
                              href: "mailto:support@mindforcegamelab.com",
                            },
                          },
                          [
                            _c("u", [
                              _vm._v(_vm._s(_vm.$t("resetPassword.contact"))),
                            ]),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }