/**
 * Make an api request using a store api module
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {string} moduleName
 * @param {string} functionName
 * @param {object} data
 * @param {string} data.viewId - The view ID (optional).
 * @param {string} [data.inviteId] - The invite ID (optional).
 * @param {boolean} [data.getMasterKey] - Whether to get the master key (optional).
 * @param {CryptoKey|string} [data.privateKey] - The private key (optional).
 * @param {CryptoKey|string} [data.publicKey] - The public key (optional).
 * @param {string} [data.appId] - The application ID (optional).
 * @param {string} [data.playtientId] - The Playtient ID (optional).
 * @param {string} [data.platform] - The platform (optional).
 * @param {string} [data.encryptedCredentials] - The encrypted credentials (optional).
 * @param {object} [data.anonymousUserInfo] - The anonymous user info (optional).
 * @param {string} [data.wrappedKey] - The wrapped key (optional).
 * @param {string} [data.licenseKey] - The license key (optional).
 * @param {string} [data.groupIds] - The group IDs (optional).
 * @param {object} [data.doses] - The list of doses.
 * @param {object} [data.dose] - The dose.
 * @param {object} [data.invite] - The invite (optional).
 * @param {object} [data.formData] - The form data (optional).
 * @param {object} [data.userInfo] - The user info (optional).
 * @param {string} [data.name] - The name (optional).
 * @param {object} [data.medication] - The medication (optional).
 * @param {string} [data.iv] - The iv (optional).
 * @param {string} [data.userId] - The userId (optional).
 * @param {number} [data.journalDay] - The journalDay (optional).
 * @param {string} [data.anonymousUserId] - The anonymous user id (optional).
 * @param {string} [data.publicKeyId] - The public key id (optional).
 * @param {string} [data.startDate] - The start date (optional).
 * @param {JsonWebKey} [data.exportedPublicKey] - The exported public key (optional).
 * @param {string} [data.salt] - The salt (optional).
 * @param {string} [data.restoreKey] - The restore key (optional).
 * @param {string} [data.nickname] - The nickname (optional, too comply with UserInfo).
 * @returns {Promise<any>}
 */
async function dispatch(store, router, moduleName, functionName, data) {
  const payload = { store, router };
  if (data) {
    payload.data = data;
  }
  return store.dispatch(`${moduleName}/${functionName}`, payload);
}

/**
 * Make an api request using store user api module
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {string} functionName
 * @param {object} data
 * @param {string} data.viewId - The view ID (optional).
 * @param {string} [data.inviteId] - The invite ID (optional).
 * @param {boolean} [data.getMasterKey] - Whether to get the master key (optional).
 * @param {CryptoKey|string} [data.privateKey] - The private key (optional).
 * @param {CryptoKey|string} [data.publicKey] - The public key (optional).
 * @param {string} [data.appId] - The application ID (optional).
 * @param {string} [data.playtientId] - The Playtient ID (optional).
 * @param {string} [data.platform] - The platform (optional).
 * @param {string} [data.encryptedCredentials] - The encrypted credentials (optional).
 * @param {object} [data.anonymousUserInfo] - The anonymous user info (optional).
 * @param {string} [data.wrappedKey] - The wrapped key (optional).
 * @param {string} [data.licenseKey] - The license key (optional).
 * @param {string} [data.groupIds] - The group IDs (optional).
 * @param {object} [data.doses] - The list of doses.
 * @param {object} [data.dose] - The dose.
 * @param {object} [data.invite] - The invite (optional).
 * @param {object} [data.formData] - The form data (optional).
 * @param {object} [data.userInfo] - The user info (optional).
 * @param {string} [data.name] - The name (optional).
 * @param {object} [data.medication] - The name (optional).
 * @param {string} [data.iv] - The iv (optional).
 * @param {string} [data.userId] - The userId (optional).
 * @param {number} [data.journalDay] - The journalDay (optional).
 * @param {string} [data.anonymousUserId] - The anonymous user id (optional).
 * @param {string} [data.publicKeyId] - The public key id (optional).
 * @param {string} [data.startDate] - The start date (optional).
 * @param {any} [data.exportedPublicKey] - The exported public key (optional).
 * @param {string} [data.salt] - The salt (optional).
 * @param {string} [data.restoreKey] - The restore key (optional).
 * @param {string} [data.nickname] - The nickname (optional, too comply with UserInfo).
 * @returns {Promise<any>}
 */
export async function apiDispatch(store, router, functionName, data) {
  return dispatch(store, router, 'api', functionName, data);
}

/**
 * Make an api request using store anonymous api module
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {string} functionName
 * @param {object} data
 * @param {string} data.viewId - The view ID (optional).
 * @param {string} [data.inviteId] - The invite ID (optional).
 * @param {boolean} [data.getMasterKey] - Whether to get the master key (optional).
 * @param {CryptoKey|string} [data.privateKey] - The private key (optional).
 * @param {CryptoKey|string} [data.publicKey] - The public key (optional).
 * @param {string} [data.appId] - The application ID (optional).
 * @param {string} [data.playtientId] - The Playtient ID (optional).
 * @param {string} [data.platform] - The platform (optional).
 * @param {string} [data.encryptedCredentials] - The encrypted credentials (optional).
 * @param {object} [data.anonymousUserInfo] - The anonymous user info (optional).
 * @param {string} [data.wrappedKey] - The wrapped key (optional).
 * @param {string} [data.licenseKey] - The license key (optional).
 * @param {string} [data.groupIds] - The group IDs (optional).
 * @param {object} [data.doses] - The list of doses (optional).
 * @param {object} [data.dose] - The dose (optional).
 * @param {object} [data.invite] - The invite (optional).
 * @param {object} [data.formData] - The form data (optional).
 * @param {object} [data.userInfo] - The user info (optional).
 * @param {string} [data.name] - The name (optional).
 * @param {object} [data.medication] - The name (optional).
 * @param {string} [data.iv] - The iv (optional).
 * @param {string} [data.userId] - The userId (optional).
 * @param {number} [data.journalDay] - The journalDay (optional).
 * @param {string} [data.anonymousUserId] - The anonymous user id (optional).
 * @param {string} [data.publicKeyId] - The public key id (optional).
 * @param {string} [data.startDate] - The start date (optional).
 * @param {any} [data.exportedPublicKey] - The exported public key (optional).
 * @param {string} [data.salt] - The salt (optional).
 * @param {string} [data.restoreKey] - The restore key (optional).
 * @param {string} [data.nickname] - The nickname (optional, too comply with UserInfo).
 * @returns {Promise<any>}
 */
export async function anonymousApiDispatch(store, router, functionName, data) {
  return dispatch(store, router, 'anonymousApi', functionName, data);
}

/**
 * Make a request using progress module
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {string} viewId
 * @param {string} functionName
 * @param {object} [data]
 * @returns {Promise<any>}
 */
export async function progressDispatch(store, router, viewId, functionName, data) {
  // eslint-disable-next-line no-param-reassign
  data = { ...data, viewId };
  return dispatch(store, router, 'progress', functionName, data);
}
