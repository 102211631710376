<template>
  <v-row style="padding-bottom: 100px">
    <v-col class="col-md-4 offset-md-4 pb-0">
      <v-card style="background-color: #41257e" rounded flat class="text-center px-1 pb-1 md-4">
        <div v-if="pastScores.length === 0" style="padding: 15px 0px 0px 0px">
          {{ $t('PHQ9.noPastResults') }}
        </div>
        <template v-for="(result, i) in pastScores">
          <v-form :key="i">
            <div class="mx-5">
              <router-link
                :to="{ name: 'phq9-result', query: { id: result.id } }"
                style="color: white"
              >
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-end;
                  "
                >
                  <p style="padding-top: 60px; display: flex">
                    {{ $t('PHQ9.result') }}{{ result.score }}
                  </p>
                  <p class="font-weight-light">
                    {{ formatDate(result.createdAt) }}
                  </p>
                  <p
                    id="box"
                    v-if="i === 0 && resultPage"
                    style="
                      height: 15px;
                      width: 15px;
                      background-color: #46c55a;
                      border-radius: 50%;
                      margin-bottom: 20px;
                    "
                  />
                  <!-- Invisible placeholder -->
                  <p
                    v-else
                    style="
                      height: 15px;
                      width: 15px;
                      background-color: #41257e;
                      border-radius: 50%;
                      margin-bottom: 20px;
                    "
                  />
                  <p style="display: flex">
                    <v-icon style="flex: 1 1 0" thin>mdi-arrow-right</v-icon>
                  </p>
                </div>
              </router-link>
              <div
                v-if="i === pastScores.length - 1"
                style="width: 100%; border-bottom: solid 1px #41257e"
              ></div>
              <div v-else style="width: 100%; border-bottom: solid 1px white"></div>
            </div>
          </v-form>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { apiGetPhqFormData } from '../api/storage';

export default {
  name: 'DonePHQ9s',
  props: {
    resultPage: Boolean,
  },
  data() {
    return {
      viewId: '012',
      pastScores: [],
    };
  },
  methods: {
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      // @ts-ignore
      return new Date(date).toLocaleDateString('en-GB', options);
    },
  },
  async mounted() {
    this.pastScores = await apiGetPhqFormData(this.$store, this.$router, this.viewId, true);
  },
  beforeUpdate() {
    // Make the green dot dissapear after 5 seconds
    if (this.resultPage) {
      setTimeout(() => {
        const boxObj = document.getElementById('box');
        if (boxObj) {
          boxObj.style.backgroundColor = '#41257e';
        }
      }, 5000);
    }
  },
};
</script>
