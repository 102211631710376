<template>
  <v-form ref="registrationForm" @submit.prevent="emitSignUp">
    <v-slide-x-transition group>
      <v-main key="1" v-show="step == 1">
        <h4 class="text-center text-uppercase mt-6">
          {{ $t('signup.createAccount') }}
        </h4>
        <v-alert
          color="warning"
          tile
          v-show="anonymousUserInfo.type != 'Playtient'"
          class="mt-4 mb-0 text-center font-weight-bold px-6"
          v-if="signupType"
        >
          {{ $t(`signup.types.${signupType}`) }}
        </v-alert>
        <v-alert
          color="info"
          tile
          class="mt-4 mb-0 text-center font-weight-bold px-6"
          v-if="betaClosedError"
        >
          {{ $t('signup.betaClosed') }}
        </v-alert>
        <v-container fluid fill-heigh class="col-10 offset-1 col-md-4 offset-md-4">
          <v-row>
            <v-col>
              <v-text-field
                name="email"
                :placeholder="$t('signup.email')"
                outlined
                rounded
                color="accent"
                prepend-inner-icon="mdi-email-outline"
                type="text"
                v-model="email"
                :rules="[rules.required, rules.email]"
                validate-on-blur
                required
                class="mgl-prepend-absolute mgl-noborder"
                background-color="primary"
                :readonly="inviteEmail !== ''"
                @input="email = email.replace(/\s+/g, '').toLowerCase()"
                @keydown.space.prevent=""
                @paste="handlePaste"
              />
              <v-tooltip v-model="showTooltip" top color="secondary" allow-overflow>
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :placeholder="$t('signup.password')"
                    name="password"
                    outlined
                    rounded
                    prepend-inner-icon="mdi-lock-outline"
                    class="mgl-noborder"
                    color="accent"
                    validate-on-blur
                    :rules="[
                      rules.required,
                      rules.minLength,
                      rules.uppercase,
                      rules.lowercase,
                      rules.symbol,
                      rules.number,
                      rules.writableSymbols,
                    ]"
                    required
                    type="password"
                    v-on="on"
                    v-model.trim="password"
                    background-color="primary"
                  >
                  </v-text-field>
                </template>
                <ul class="mgl-pwd-list my-2">
                  <li
                    v-for="criteria in passwordCriterias"
                    :key="criteria.id"
                    class="d-flex align-center mb-1"
                  >
                    <v-chip
                      small
                      :color="criteria.fulfilled ? 'success' : 'white'"
                      :outlined="!criteria.fulfilled"
                      class="mr-2"
                      ><v-icon v-show="criteria.fulfilled" small>mdi-check</v-icon></v-chip
                    >
                    {{ criteria.text }}
                  </li>
                </ul>
              </v-tooltip>
              <v-text-field
                :placeholder="$t('signup.confirmPassword')"
                name="password2"
                outlined
                rounded
                prepend-inner-icon="mdi-lock-outline"
                color="accent"
                type="password"
                :rules="[rules.required, rules.matchPassword]"
                required
                validate-on-blur
                class="mgl-append-green mgl-noborder"
                v-model.trim="password2"
                :append-icon="passwordMatch ? 'mdi-check' : ''"
                background-color="primary"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                x-large
                rounded
                block
                color="secondary"
                class="uppercase-text"
                :disabled="!step1Complete"
                @click="nextStep"
                >{{ $t('generic.next') }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
        <v-footer class="text-center pt-0" color="transparent">
          <v-container>
            <v-row>
              <v-col>
                {{ $t('signup.already') }}
                <router-link
                  class="pl-2 text-uppercase"
                  :to="{ name: 'login', query: this.$route.query }"
                >
                  {{ $t('login.login') }}
                </router-link>
              </v-col>
            </v-row>
          </v-container>
        </v-footer>
      </v-main>
    </v-slide-x-transition>
    <v-slide-x-transition>
      <v-main key="1" v-show="step == 2">
        <v-container fluid fill-height class="col-12 col-md-4 offset-md-4 px-4">
          <v-row class="align-center mt-4 mx-3">
            <v-col class="pt-0">
              <h5 class="text-center mb-2">
                {{ $t('signup.step2Header') }}
              </h5>
              <p class="text-center caption font-italic grey--text text--lighten-2 mb-0">
                {{ $t('signup.step2Details') }}
              </p>
            </v-col>
          </v-row>
          <!-- Nickname -->
          <v-row>
            <v-col>
              <v-card color="primaryDark" flat rounded class="pa-5">
                <h5 class="mb-3">{{ $t('signup.nickname') }}</h5>
                <v-text-field
                  v-model.trim="userInfo.nickname"
                  outlined
                  rounded
                  dense
                  color="accent"
                  item-color="accent"
                  maxLength="20"
                />
                <p
                  class="text-center caption error--text mb-0 mt-2"
                  v-show="!anonymousUserInfo.birthYear"
                ></p>
              </v-card>
            </v-col>
          </v-row>
          <!-- Year of birth -->
          <v-row>
            <v-col>
              <v-card color="primaryDark" flat rounded class="pa-5">
                <h5 class="mb-3">{{ $t('signup.yearOfBirth') }}</h5>
                <v-select
                  :items="yearOptions"
                  v-model="anonymousUserInfo.birthYear"
                  outlined
                  rounded
                  dense
                  color="accent"
                  item-color="accent"
                  placeholder="----"
                ></v-select>
                <p
                  class="text-center caption error--text mb-0 mt-2"
                  v-show="!anonymousUserInfo.birthYear"
                >
                  {{ $t('signup.minimumAge') }}
                </p>
              </v-card>
            </v-col>
          </v-row>
          <!-- Gender -->
          <v-row>
            <v-col>
              <v-card color="primaryDark" flat rounded class="pa-5">
                <h5 class="mb-3">{{ $t('signup.gender') }}</h5>
                <v-select
                  :items="genderOptions"
                  v-model="anonymousUserInfo.gender"
                  outlined
                  rounded
                  dense
                  color="accent"
                  item-color="accent"
                  placeholder="----"
                ></v-select>
              </v-card>
            </v-col>
          </v-row>
          <!-- Diagnosis (Only playtient) -->
          <v-row v-if="isPlaytient">
            <v-col>
              <v-card color="primaryDark" flat rounded class="pa-5">
                <h5 class="mb-3">{{ $t('signup.diagnosis.label') }}</h5>
                <v-select
                  :items="diagnosisOptions"
                  v-model="anonymousUserInfo.diagnosis"
                  outlined
                  rounded
                  dense
                  placeholder="----"
                  color="accent"
                  item-color="accent"
                  @change="onDiagnosisChange"
                />
                <v-slide-y-transition>
                  <div key="3" v-show="isDiagnosed">
                    <h5 class="mb-3">{{ $t('signup.diagnosedWhen') }}</h5>
                    <v-date-picker
                      type="month"
                      full-width
                      :locale="$i18n.locale"
                      flat
                      ref="diagnosisDatePicker"
                      color="secondary"
                      :max="maxDiagnoseDateString"
                      :min="minDiagnoseDateString"
                      v-model="anonymousUserInfo.diagnosisDate"
                      @click:month="formatDiagnosisDate()"
                      reactive
                      class="mgl-picker"
                    >
                    </v-date-picker>
                  </div>
                </v-slide-y-transition>
              </v-card>
            </v-col>
          </v-row>
          <!-- Terms and conditions checkboxes -->
          <v-row>
            <v-col class="caption mx-4 d-flex align-center justify-center">
              <div>
                <v-chip
                  :color="haveAgreed ? 'success' : 'secondary'"
                  class="mgl-chip-checkbox mr-3"
                  :outlined="!haveAgreed"
                  @click="haveAgreed = !haveAgreed"
                  ><v-icon v-show="haveAgreed">mdi-check</v-icon>
                </v-chip>
              </div>
              <div>
                {{ $t('fig.registration.pleaseRead') }}
                <a
                  :href="privacyPolicyUrl"
                  target="_blank"
                  class="white--text text-decoration-underline"
                >
                  {{ $t('fig.registration.privacyPolicy') }}</a
                >
                {{ $t('fig.registration.and') }}
                <a :href="termsUrl" target="_blank" class="white--text text-decoration-underline">
                  {{ $t('fig.registration.terms') }}
                </a>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mx-3">
              <v-btn
                x-large
                rounded
                block
                color="secondary"
                type="submit"
                class="uppercase-text"
                :disabled="!isFormFilled"
                >{{ $t('signup.signup') }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-slide-x-transition>
  </v-form>
</template>

<script>
import { ALLOWED_CHARACTERS_REGEX } from '../../utils/constants';
import { getPastedData } from '../../utils/clipboard';

/**
 * @typedef {{type: 'Playtient'|'MGL'|'Team', birthYear: number, gender: 'Female'|'Male'|'Other'|'',
 * diagnosis: 'Depression'|'Bipolar'|'Both'|'Not diagnosed'|'', diagnosisDate?: string,groupIds?: string}} AnonymousUserFormInfo
 */

const MAX_AGE = 100;
const MIN_ALLOWED_AGE = 18;
const NO_DIAGNOSIS = 'Not diagnosed';
const TYPE_PLAYTIENT = 'Playtient';
const TYPE_TEAM = 'Team';
const TYPE_MGL = 'MGL';

export default {
  props: {
    inviteEmail: String,
    stepId: String,
    betaClosedError: Boolean,
  },
  emits: ['signup'],
  data() {
    return {
      step: 1,
      email: '',
      password: '',
      password2: '',
      haveAgreed: false,
      hasDiagnosisDate: false,
      recreation: false,
      /** @type {AnonymousUserFormInfo} */
      anonymousUserInfo: {
        type: TYPE_PLAYTIENT,
        birthYear: 0,
        gender: '',
        diagnosis: '',
      },
      userInfo: {
        nickname: '',
      },
      signupType: '',
      showTooltip: false,
      rules: {
        required: (v) => !!v || this.$i18n.t('validation.fieldNotEmpty'),
        email: (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
            v,
          ) || this.$i18n.t('validation.emailInvalid'),
        minLength: (v) => v.length >= 8 || this.$i18n.t('validation.minCharacters'),
        uppercase: (v) => /[A-Z]/.test(v) || this.$i18n.t('validation.uppercase'),
        lowercase: (v) => /[a-z]/.test(v) || this.$i18n.t('validation.lowercase'),
        symbol: (v) =>
          /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(v) || this.$i18n.t('validation.symbol'),
        number: (v) => /\d/.test(v) || this.$i18n.t('validation.number'),
        matchPassword: () =>
          this.password === this.password2 || this.$i18n.t('validation.passwordNotMatch'),
        writableSymbols: (v) =>
          !ALLOWED_CHARACTERS_REGEX.test(v) || this.$i18n.t('validation.writableSymbols'),
      },
    };
  },
  created() {
    // Handle user signup type
    const queryType = this.$route.query.type;
    if (queryType !== undefined) {
      this.signupType = queryType.toString();
      switch (this.signupType) {
        case 'mgl':
          this.anonymousUserInfo.type = TYPE_MGL;
          break;
        case 'team':
          this.anonymousUserInfo.type = TYPE_TEAM;
          break;
        default:
          this.anonymousUserInfo.type = TYPE_PLAYTIENT;
          break;
      }
    }
    const queryStepId = this.$route.query.stepId;
    if (queryStepId !== undefined) {
      this.step = queryStepId;
      this.recreation = true;
    }
  },
  watch: {
    inviteEmail(val) {
      if (val) {
        this.email = this.inviteEmail;
        this.anonymousUserInfo.type = TYPE_TEAM;
        this.anonymousUserInfo.diagnosis = NO_DIAGNOSIS;
      }
    },
  },
  computed: {
    isFormFilled() {
      return (
        this.haveAgreed &&
        this.userInfo.nickname !== '' &&
        this.anonymousUserInfo.gender !== '' &&
        this.anonymousUserInfo.birthYear > 0 &&
        (!this.isPlaytient || (this.anonymousUserInfo.diagnosis !== '' && this.isValidDiagnosis))
      );
    },
    step1Complete() {
      return (
        this.rules.minLength(this.password) === true &&
        this.rules.symbol(this.password) === true &&
        this.rules.uppercase(this.password) === true &&
        this.rules.lowercase(this.password) === true &&
        this.rules.number(this.password) === true &&
        this.passwordMatch &&
        this.rules.email(this.email) === true &&
        this.rules.writableSymbols(this.password) === true
      );
    },
    yearOptions() {
      const yearOptions = [];
      const currentYear = new Date().getFullYear();
      for (let i = MIN_ALLOWED_AGE; i < MAX_AGE; i += 1) {
        yearOptions.push(currentYear - i);
      }
      return yearOptions;
    },
    privacyPolicyUrl() {
      return `https://mindforcegamelab.com/privacy-policy-${this.$i18n.locale}/`;
    },
    termsUrl() {
      return `https://mindforcegamelab.com/terms-and-conditions-${this.$i18n.locale}/`;
    },
    isPlaytient() {
      const { type } = this.anonymousUserInfo;
      return type === TYPE_PLAYTIENT || type === TYPE_MGL;
    },
    isValidDiagnosis() {
      const { diagnosis, diagnosisDate, birthYear } = this.anonymousUserInfo;
      if (diagnosis === NO_DIAGNOSIS) {
        return true;
      }
      if (diagnosisDate === undefined || !this.hasDiagnosisDate) {
        return false;
      }
      return !birthYear || new Date(diagnosisDate).getFullYear() >= birthYear;
    },
    isDiagnosed() {
      return (
        this.anonymousUserInfo.diagnosis !== NO_DIAGNOSIS && this.anonymousUserInfo.diagnosis !== ''
      );
    },
    diagnosisOptions() {
      return [
        {
          text: this.$i18n.t('signup.diagnosis.depression'),
          value: 'Depression',
        },
        { text: this.$i18n.t('signup.diagnosis.bipolar'), value: 'Bipolar' },
        { text: this.$i18n.t('signup.diagnosis.both'), value: 'Both' },
        {
          text: this.$i18n.t('signup.diagnosis.notDiagnosed'),
          value: NO_DIAGNOSIS,
        },
      ];
    },
    maxDiagnoseDateString() {
      return new Date().toISOString().slice(0, 10);
    },
    minDiagnoseDateString() {
      return (this.anonymousUserInfo.birthYear || new Date().getFullYear() - MAX_AGE).toString();
    },
    genderOptions() {
      return [
        { text: this.$i18n.t('signup.genders.male'), value: 'Male' },
        { text: this.$i18n.t('signup.genders.female'), value: 'Female' },
        { text: this.$i18n.t('signup.genders.other'), value: 'Other' },
      ];
    },
    passwordMatch() {
      return this.password2.length > 1 && this.password === this.password2;
    },
    passwordCriterias() {
      return [
        {
          text: this.$i18n.t('signup.passwordCriterias.uppercase'),
          fulfilled: /[A-Z]/.test(this.password),
        },
        {
          text: this.$i18n.t('signup.passwordCriterias.lowercase'),
          fulfilled: /[a-z]/.test(this.password),
        },
        {
          text: this.$i18n.t('signup.passwordCriterias.number'),
          fulfilled: /\d/.test(this.password),
        },
        {
          text: this.$i18n.t('signup.passwordCriterias.symbol'),
          fulfilled: /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(this.password),
        },
        {
          text: this.$i18n.t('signup.passwordCriterias.minLength'),
          fulfilled: this.password.length >= 8,
        },
        {
          text: this.$i18n.t('signup.passwordCriterias.writableCharacters'),
          fulfilled: !ALLOWED_CHARACTERS_REGEX.test(this.password),
        },
      ];
    },
  },
  methods: {
    handlePaste(event) {
      const formattedText = getPastedData(event);
      if (formattedText !== null) {
        this.email = formattedText;
      }
      event.preventDefault();
    },
    onDiagnosisChange(diagnosis) {
      if (diagnosis === NO_DIAGNOSIS) {
        this.$delete(this.anonymousUserInfo, 'diagnosisDate');
        this.hasDiagnosisDate = false;
      }
    },
    // We do this since the user only picks year and month for diagnosis
    formatDiagnosisDate() {
      this.anonymousUserInfo.diagnosisDate += '-01';
      this.hasDiagnosisDate = true;
    },
    nextStep() {
      const nextStep = this.step + 1;
      this.step = 0;
      setTimeout(() => {
        this.step = nextStep;
      }, 300);
    },
    emitSignUp() {
      if (
        (!this.recreation && !this.$refs.registrationForm.validate()) ||
        (this.recreation && !this.isFormFilled)
      ) {
        return false;
      }
      this.$emit('signup', this.stepId);
      return true;
    },
  },
};
</script>
