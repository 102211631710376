import { apiDispatch } from './dispatch';

const { base64ToArraybuffer, arraybufferTobase64 } = require('./base64');

export const constants = {
  algorithms: {
    aesGcm: 'AES-GCM',
    rsaOaep: 'RSA-OAEP',
    pbkdf2: 'PBKDF2',
    rsa1_5: 'RSASSA-PKCS1-v1_5',
  },
  format: {
    /** @type {'raw'} */
    raw: 'raw',
    /** @type {'jwk'} */
    jwk: 'jwk',
  },
  hash: {
    sha256: 'SHA-256',
  },
};
const encryptedDekBytesLength = 256;
const aesWrapKeyBitsLength = 256;
const pbkdf2Iterations = 100000;
const ivByteLength = 12;
const LOCALSTORAGE_KEY = 'key';
const LOCALSTORAGE_IV = 'iv';

/**
 * Get some key material to use as input to the deriveKey method.
  The key material is a password supplied by the user.
 * @param {string} password 
 * @returns {Promise<CryptoKey>}
 */
async function getKeyMaterial(password) {
  const enc = new TextEncoder();
  return crypto.subtle.importKey(
    constants.format.raw,
    enc.encode(password),
    {
      name: constants.algorithms.pbkdf2,
    },
    false,
    ['deriveKey'],
  );
}

/**
 * Derives AES GCM key from password and salt
 * @param {string} password
 * @param {string} [optionalSalt]
 * @returns {Promise<{salt: string, aesKey: CryptoKey}>}
 */
export async function deriveAesGcmKey(password, optionalSalt) {
  const key = await getKeyMaterial(password);
  const salt = optionalSalt
    ? base64ToArraybuffer(optionalSalt)
    : crypto.getRandomValues(new Uint8Array(16));
  const aesKey = await crypto.subtle.deriveKey(
    {
      name: constants.algorithms.pbkdf2,
      salt,
      iterations: pbkdf2Iterations,
      hash: { name: constants.hash.sha256 },
    },
    key,
    {
      name: constants.algorithms.aesGcm,
      length: aesWrapKeyBitsLength,
    },
    true,
    ['wrapKey', 'unwrapKey'],
  );
  return {
    salt: arraybufferTobase64(salt),
    aesKey,
  };
}

/**
 * Creates a new RSA key
 * @returns {Promise<CryptoKeyPair>}
 */
export async function createRsaKey() {
  return crypto.subtle.generateKey(
    {
      name: constants.algorithms.rsaOaep,
      modulusLength: 2048,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      hash: { name: constants.hash.sha256 },
    },
    true,
    ['encrypt', 'decrypt'],
  );
}

/**
 * Creates a restore key using private key
 * @param {import("vuex").Store} store
 * @param {CryptoKey} privateKey
 * @returns {Promise<string>}
 */
async function createRestoreKey(store, privateKey) {
  const privateKeyAsJWK = await crypto.subtle.exportKey('jwk', privateKey);
  const { masterKey } = store.state.api.me;
  const encryptAlgo = {
    name: constants.algorithms.rsaOaep,
    hash: { name: 'SHA-256' },
  };

  const masterCryptoKey = await crypto.subtle.importKey(
    'jwk',
    JSON.parse(masterKey),
    encryptAlgo,
    false,
    ['encrypt'],
  );

  const privateKeyString = JSON.stringify(privateKeyAsJWK);
  return envelopeEncrypt(masterCryptoKey, privateKeyString);
}

/**
 * Exports key to JWK format
 * @param {CryptoKey} key
 * @returns {Promise<JsonWebKey>}
 */
export async function exportKey(key) {
  return crypto.subtle.exportKey(constants.format.jwk, key);
}

/**
 * Imports a JWK AES key
 * @param {JsonWebKey} jwk
 * @returns {Promise<CryptoKey>}
 */
export function importAesKey(jwk) {
  return crypto.subtle.importKey(
    constants.format.jwk,
    jwk,
    {
      name: constants.algorithms.aesGcm,
    },
    true,
    ['wrapKey', 'unwrapKey'],
  );
}

/**
 * Imports a JWK RSA key
 * @param {JsonWebKey} jwk
 * @returns {Promise<CryptoKey>}
 */
export async function importRsaKey(jwk) {
  return crypto.subtle.importKey(
    constants.format.jwk,
    jwk,
    {
      name: constants.algorithms.rsaOaep,
      hash: { name: constants.hash.sha256 },
    },
    true,
    ['encrypt'],
  );
}

/**
 * Returns hash of message using SHA-256
 * @param {string} message
 * @returns {Promise<string>}
 */
export async function hash(message) {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const hashDigest = await crypto.subtle.digest(constants.hash.sha256, data);
  return arraybufferTobase64(hashDigest);
}

/**
 * Wraps key using AES key
 * @param {CryptoKey} key
 * @param {CryptoKey} aesKey
 * @returns {Promise<{wrappedKey: string, iv: string}>}
 */
export async function wrapKey(key, aesKey) {
  const iv = crypto.getRandomValues(new Uint8Array(ivByteLength));
  const wrapAlgo = {
    name: constants.algorithms.aesGcm,
    iv,
  };
  const wrappedKey = await crypto.subtle.wrapKey(constants.format.jwk, key, aesKey, wrapAlgo);
  return {
    wrappedKey: arraybufferTobase64(wrappedKey),
    iv: arraybufferTobase64(iv),
  };
}

/**
 * Unwraps key using an AES key
 * @param {string} wrappedKey
 * @param {CryptoKey} aesKey
 * @param {string} iv base 64 encoded iv
 * @returns {Promise<CryptoKey>}
 */
export async function unwrapKey(wrappedKey, aesKey, iv) {
  const wrapAlgo = {
    name: constants.algorithms.aesGcm,
    iv: base64ToArraybuffer(iv),
  };

  const wrapKeyAlgo = {
    name: constants.algorithms.rsaOaep,
    hash: { name: constants.hash.sha256 },
  };

  return crypto.subtle.unwrapKey(
    constants.format.jwk,
    base64ToArraybuffer(wrappedKey),
    aesKey,
    wrapAlgo,
    wrapKeyAlgo,
    true,
    ['decrypt'],
  );
}

/**
 * Decrypt message encrypted with envelope encryption
 * @param {CryptoKey} kek key encryption key
 * @param {string} message
 * @returns {Promise<string>}
 */
export async function envelopeDecrypt(kek, message) {
  const bytes = base64ToArraybuffer(message);

  const encryptedDek = bytes.slice(bytes.byteLength - encryptedDekBytesLength, bytes.byteLength);
  const iv = bytes.slice(
    bytes.byteLength - encryptedDekBytesLength - ivByteLength,
    bytes.byteLength - encryptedDekBytesLength,
  );
  const encrypted = bytes.slice(0, bytes.byteLength - encryptedDekBytesLength - ivByteLength);
  let decryptedDek;
  try {
    decryptedDek = await crypto.subtle.decrypt(
      {
        name: constants.algorithms.rsaOaep,
      },
      kek,
      encryptedDek,
    );
  } catch (err) {
    console.error('Failed to decrypt dek:', err);
    throw err;
  }

  const dek = await crypto.subtle.importKey(
    constants.format.raw,
    decryptedDek,
    {
      name: constants.algorithms.aesGcm,
    },
    false,
    ['decrypt'],
  );
  let data;
  try {
    data = await crypto.subtle.decrypt(
      {
        name: constants.algorithms.aesGcm,
        iv,
      },
      dek,
      encrypted,
    );
  } catch (err) {
    console.error('Failed to decrypt data:', err);
    throw err;
  }

  const textDecoder = new TextDecoder();
  return textDecoder.decode(data);
}

/**
 * Encrypt message using envelope encryption
 * @param {CryptoKey} kek key encryption key
 * @param {string} message
 * @returns {Promise<string>}
 */
export async function envelopeEncrypt(kek, message) {
  const textEncoder = new TextEncoder();
  const data = textEncoder.encode(message);

  const dek = await crypto.subtle.generateKey(
    {
      name: constants.algorithms.aesGcm,
      length: 256,
    },
    true,
    ['encrypt', 'decrypt'],
  );

  const exportedDek = await crypto.subtle.exportKey(constants.format.raw, dek);
  const encryptedDek = await crypto.subtle.encrypt(
    {
      name: constants.algorithms.rsaOaep,
    },
    kek,
    exportedDek,
  );

  const iv = crypto.getRandomValues(new Uint8Array(ivByteLength));
  const encrypted = await crypto.subtle.encrypt(
    {
      name: constants.algorithms.aesGcm,
      iv,
    },
    dek,
    data,
  );

  const bytes = new Uint8Array(encrypted.byteLength + iv.byteLength + encryptedDek.byteLength);
  bytes.set(new Uint8Array(encrypted), 0);
  bytes.set(iv, encrypted.byteLength);
  bytes.set(new Uint8Array(encryptedDek), encrypted.byteLength + iv.byteLength);
  return arraybufferTobase64(bytes);
}

/**
 * Get Sha256 hash as hex string for string
 * @param {string} message data as string
 * @param {string} [hashFunction] hash function, or SHA-2 if not specified
 * @returns {Promise<ArrayBuffer>}
 */
export async function getHashDigest(message, hashFunction) {
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  return crypto.subtle.digest(hashFunction || constants.hash.sha256, data);
}

/**
 * Updates the rsa key using provided AES key and salt
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {CryptoKey} aesKey
 * @param {string} salt
 * @param {string} viewId
 * @returns {Promise<CryptoKeyPair>}
 */
async function updateRsaKey(store, router, aesKey, salt, viewId) {
  const rsaKey = await createRsaKey();
  const { wrappedKey, iv } = await wrapKey(rsaKey.privateKey, aesKey);
  const exportedPublicKey = await exportKey(rsaKey.publicKey);
  const publicKeyId = await hash(JSON.stringify(exportedPublicKey));
  const restoreKey = await createRestoreKey(store, rsaKey.privateKey);

  await apiDispatch(store, router, 'postNewRsaKey', {
    wrappedKey,
    publicKeyId,
    exportedPublicKey,
    salt,
    iv,
    restoreKey,
    viewId,
  });

  // Store new iv key
  localStorage.setItem(LOCALSTORAGE_IV, iv);
  // Set new RSA data
  store.commit('api/setRsaData', {
    privateKey: wrappedKey,
    publicKey: exportedPublicKey,
    salt,
    iv,
  });
  return rsaKey;
}

/**
 * Initializes RSA key for user
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {string} password
 * @param {string} privateKey
 * @param {JsonWebKey} publicKey
 * @param {string} viewId
 * @param {string} [optionalSalt]
 * @param {string|null} [iv]
 * @param {boolean} [allowCreate]
 * @returns {Promise<CryptoKeyPair|null>}
 */
export async function initRsaKey(
  store,
  router,
  password,
  privateKey,
  publicKey,
  viewId,
  optionalSalt,
  iv,
  allowCreate,
) {
  const { aesKey, salt } = await deriveAesGcmKey(password, optionalSalt);
  let rsaKey = await extractRsaKey(privateKey, publicKey, aesKey, iv);

  if (!rsaKey && allowCreate) {
    rsaKey = await updateRsaKey(store, router, aesKey, salt, viewId);
  }

  // If still no RSA key, throw error
  if (!rsaKey) {
    return null;
  }

  localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(await exportKey(aesKey)));

  if (iv !== null) {
    // Store existing iv key
    localStorage.setItem(LOCALSTORAGE_IV, iv);
    // Store existing RSA data
    store.commit('api/setRsaData', {
      privateKey,
      publicKey,
      salt,
      iv,
    });
  }

  return rsaKey;
}

/**
 * Extract RSA key using wrapped private key and AWS key
 * @param {string|undefined} privateKey wrapped private key
 * @param {JsonWebKey|undefined} publicKey
 * @param {CryptoKey|undefined} aesKey
 * @param {string|undefined} iv
 * @returns {Promise<CryptoKeyPair|null>}
 */
export async function extractRsaKey(privateKey, publicKey, aesKey, iv) {
  if (!privateKey || !aesKey || !iv) {
    return null;
  }
  try {
    const unwrappedKey = await unwrapKey(privateKey, aesKey, iv);
    let importedKey = null;
    if (publicKey) {
      importedKey = await importRsaKey(publicKey);
    }

    const keyPair = {
      privateKey: unwrappedKey,
      publicKey: importedKey,
    };

    return keyPair;
  } catch (err) {
    console.log(`Invalid: ${err.message}`);
    throw new Error('Problem with encryption key. Please contact support@mindforcegamelab.com.');
  }
}

/**
 * Fetch RSA keys from storage, or null if there are none
 * @param {import("vuex").Store} store
 * @returns {Promise<CryptoKeyPair|null>}
 */
export async function fetchRsaKeysFromLocalStorage(store) {
  const { me } = store.state.api;
  const { privateKey, publicKey } = me;
  const aesKeyString = localStorage.getItem(LOCALSTORAGE_KEY);
  const iv = localStorage.getItem(LOCALSTORAGE_IV);
  if (!aesKeyString || !iv) {
    return null;
  }
  const aesKey = await importAesKey(JSON.parse(aesKeyString));
  return extractRsaKey(privateKey, publicKey, aesKey, iv);
}

/**
 * Clears RSA keys from local storage
 */
export function clearRsaKeysFromLocalStorage() {
  localStorage.removeItem(LOCALSTORAGE_KEY);
  localStorage.removeItem(LOCALSTORAGE_IV);
}
