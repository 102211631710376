import { apiDispatch } from '../utils/dispatch';
import { fetchRsaKeysFromLocalStorage } from '../utils/crypto';

/**
 * Api method to get Phq form data
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {string} viewId
 * @param {boolean} [allowCached] if true state may be used instead of api
 * @returns {Promise<import("../store/modules/api").AssessmentForm[]>}
 */
export async function apiGetPhqFormData(store, router, viewId, allowCached) {
  if (allowCached && store.getters['api/hasFetchedPhqData']) {
    return store.getters['api/phqData'];
  }
  const rsaKeys = await fetchRsaKeysFromLocalStorage(store);
  return apiDispatch(store, router, 'getPhqFormData', {
    privateKey: rsaKeys.privateKey,
    viewId,
  });
}

/**
 * Api method to post Phq form data
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {string} viewId
 * @param {import("../store/modules/api").AssessmentForm} formData
 * @returns {Promise<import("../store/modules/api").AssessmentForm>}
 */
export async function apiPostPhqFormData(store, router, viewId, formData) {
  const rsaKeys = await fetchRsaKeysFromLocalStorage(store);
  return apiDispatch(store, router, 'postPhqFormData', {
    publicKey: rsaKeys.publicKey,
    formData,
    privateKey: rsaKeys.privateKey,
    viewId,
  });
}

/**
 * Api method to get user sensors from storage
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {string} viewId
 * @param {boolean} [allowCached] if true state may be used instead of api
 * @param {string} [minDateStr] if set, this is the minimum date to use as string
 * @returns {Promise<object[]>}
 */
export async function apiGetSensors(store, router, viewId, allowCached, minDateStr) {
  if (allowCached && store.getters['api/hasFetchedSensors']) {
    return store.getters['api/sensors'];
  }
  const rsaKeys = await fetchRsaKeysFromLocalStorage(store);
  const payload = {
    privateKey: rsaKeys.privateKey,
    viewId,
  };
  if (minDateStr) {
    payload.minDate = minDateStr;
  }
  return apiDispatch(store, router, 'getSensors', payload);
}

/**
 * Api method to get user dose configs from storage
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {string} viewId
 * @param {string} [minDateStr] if set, this is the minimum date to use as string
 * @returns {Promise<object[]>}
 */
export async function apiGetAllDoseConfigs(store, router, viewId, minDateStr) {
  const rsaKeys = await fetchRsaKeysFromLocalStorage(store);
  const payload = {
    privateKey: rsaKeys.privateKey,
    viewId,
  };
  if (minDateStr) {
    payload.minDate = minDateStr;
  }
  return apiDispatch(store, router, 'getAllDoseConfigs', payload);
}
