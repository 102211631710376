var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          staticClass: "mgl-hero d-flex align-center justify-center",
          attrs: { flat: "", color: "primary" },
        },
        [_c("Logo")],
        1
      ),
      _c("SimpleLocalePicker", { staticClass: "mgl-simple-locale" }),
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-row",
            { staticClass: "mt-1" },
            [
              _c(
                "v-col",
                { staticClass: "col-md-6 offset-md-3 mt-6" },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pb-10 text-center",
                      attrs: { color: "primaryDark" },
                    },
                    [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "pa-5 d-flex align-center justify-center",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("resetPassword.passwordReset")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "pa-5 d-flex align-center justify-center text-center",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("resetPassword.successfulReset")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "col-md-4 offset-md-4 text-center" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "px-15 uppercase",
                                  attrs: {
                                    color: "secondary",
                                    rounded: "",
                                    depressed: "",
                                    "x-large": "",
                                  },
                                  on: { click: _vm.goToLogin },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("login.login")) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-footer",
        { staticClass: "text-center mt-15", attrs: { color: "background" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("resetPassword.needHelp")) + " "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "pl-2",
                        attrs: { href: "mailto:support@mindforcegamelab.com" },
                      },
                      [
                        _c("u", [
                          _vm._v(_vm._s(_vm.$t("resetPassword.contact"))),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }