var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          staticClass: "mgl-hero d-flex align-center justify-center",
          attrs: { flat: "", color: "primary" },
        },
        [_c("Logo")],
        1
      ),
      _c("SimpleLocalePicker", { staticClass: "mgl-simple-locale" }),
      _c(
        "v-slide-x-transition",
        { attrs: { group: "" } },
        [
          _c(
            "v-main",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show,
                  expression: "show",
                },
              ],
              key: "1",
            },
            [
              _c(
                "v-form",
                {
                  ref: "loginForm",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.logIn($event)
                    },
                  },
                },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "fill-height": "" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-10" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "col-10 offset-1 col-md-4 offset-md-4",
                            },
                            [
                              _c("v-text-field", {
                                staticClass:
                                  "mgl-prepend-absolute mgl-noborder",
                                attrs: {
                                  name: "email",
                                  placeholder: _vm.$t("login.email"),
                                  outlined: "",
                                  rounded: "",
                                  color: "accent",
                                  "prepend-inner-icon": "mdi-email-outline",
                                  type: "text",
                                  rules: [_vm.rules.email],
                                  "validate-on-blur": "",
                                  "background-color": "primary",
                                },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.preventDefault()
                                  },
                                  paste: _vm.handlePaste,
                                  input: function ($event) {
                                    _vm.email = _vm.email
                                      .replace(/\s+/g, "")
                                      .toLowerCase()
                                  },
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function ($$v) {
                                    _vm.email = $$v
                                  },
                                  expression: "email",
                                },
                              }),
                              _c("v-text-field", {
                                staticClass:
                                  "mb-4 mgl-prepend-absolute mgl-noborder",
                                attrs: {
                                  name: "password",
                                  placeholder: _vm.$t("login.password"),
                                  outlined: "",
                                  rounded: "",
                                  "prepend-inner-icon": "mdi-lock-outline",
                                  color: "accent",
                                  type: "password",
                                  "hide-details": "",
                                  rules: [_vm.rules.notEmpty],
                                  "background-color": "primary",
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "password",
                                },
                              }),
                              _c(
                                "router-link",
                                { attrs: { to: "/resetpassword" } },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "float-right white--text text-decoration-underline",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("login.forgotPassword")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-10" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "col-10 offset-1 col-md-4 offset-md-4",
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-large": "",
                                    rounded: "",
                                    block: "",
                                    color: "secondary",
                                    type: "submit",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("login.login")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-footer",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show,
                  expression: "show",
                },
              ],
              key: "2",
              staticClass: "text-center",
              attrs: { color: "background" },
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("login.notRegistered")) + " "
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "pl-2 text-uppercase",
                              attrs: {
                                to: {
                                  name: "signup",
                                  query: this.computedQuery,
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("login.createAccount")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }