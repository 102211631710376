import { Auth } from 'aws-amplify';
import Vue from 'vue';
import VueRouter from 'vue-router';
import * as _ from 'lodash';
import Account from '../views/Account.vue';
import Home from '../views/Home.vue';
import Download from '../views/Download.vue';
import VerifyEmail from '../views/VerifyEmail.vue';
import EmailVerified from '../views/EmailVerified.vue';
import Invites from '../views/Invites.vue';
import License from '../views/License.vue';
import Login from '../views/Login.vue';
import Medications from '../views/Medications.vue';
import PathNotFound from '../views/PathNotFound.vue';
import Schedule from '../views/Schedule.vue';
import Signup from '../views/Signup.vue';
import Team from '../views/Team.vue';
import Reports from '../views/Reports.vue';
import i18n from '../plugins/i18n';
import store from '../store';
import signOut from '../utils/auth';
import { displayMessage } from '../utils/loading';
import SelfAssessment from '../views/SelfAssessment.vue';
import PHQ9 from '../views/PHQ9.vue';
import PHQ9Finish from '../views/PHQ9Finish.vue';
import PHQ9Result from '../views/PHQ9Result.vue';
import ResetPassword from '../views/ResetPassword.vue';
import NewPassword from '../views/NewPassword.vue';
import LoginSmall from '../views/LoginSmall.vue';
import { getDownloadPageUrl } from '../utils/licenses';
import { isFeatureEnabled } from '../utils/config';

// Catch redundant navigation errors
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresVerification: true,
    },
  },
  {
    path: '/download',
    name: 'download',
    component: Download,
    meta: {
      requiresVerification: true,
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.isInvalid || !store.state.api.me) {
        const { query } = to;
        query.to = 'download';
        next({ name: 'login', query });
        return;
      }
      const downloadUrl = getDownloadPageUrl(store, i18n);
      window.location.href = downloadUrl;
    },
  },
  {
    path: '/verifyemail',
    name: 'verifyemail',
    component: VerifyEmail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
  },
  {
    path: '/emailverified',
    name: 'emailverified',
    component: EmailVerified,
  },
  {
    path: '/licenses',
    name: 'licenses',
    component: License,
    meta: {
      requiresVerification: true,
    },
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      requiresVerification: true,
    },
  },
  {
    path: '/team',
    name: 'team',
    component: Team,
    meta: {
      requiresVerification: true,
    },
  },
  {
    path: '/invites/:inviteId',
    name: 'invites',
    component: Invites,
  },
  {
    path: '/medications',
    name: 'medications',
    component: Medications,
    meta: {
      requiresVerification: true,
    },
  },
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    meta: {
      featureKey: 'REPORT',
      requiresLicense: true,
    },
  },
  {
    path: '/medications/schedule',
    name: 'schedule',
    component: Schedule,
    meta: {
      requiresLicense: true,
    },
  },
  {
    path: '/phq9-start',
    name: 'selfassessment',
    component: SelfAssessment,
    meta: {
      featureKey: 'PHQ',
      requiresLicense: true,
    },
  },
  {
    path: '/phq9-form',
    name: 'phq9-form',
    component: PHQ9,
    meta: {
      featureKey: 'PHQ',
      requiresLicense: true,
    },
  },
  {
    path: '/phq9-finish',
    name: 'phq9-finish',
    component: PHQ9Finish,
    meta: {
      featureKey: 'PHQ',
      requiresLicense: true,
    },
  },
  {
    path: '/phq9-result',
    name: 'phq9-result',
    component: PHQ9Result,
    meta: {
      featureKey: 'PHQ',
      requiresLicense: true,
    },
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: ResetPassword,
  },
  {
    path: '/newpassword',
    name: 'newpassword',
    component: NewPassword,
  },
  {
    path: '/loginsmall',
    name: 'loginsmall',
    component: LoginSmall,
  },
  // For all else, route to 404
  { path: '/:pathMatch(.*)*', name: '404', component: PathNotFound },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

async function verifyUserLoggedin(to, next) {
  try {
    await Auth.currentAuthenticatedUser();
    if (store.state.api.me == null) {
      throw new Error('Could not get user data. Please login again.');
    }
    return true;
  } catch (err) {
    await signOut(store, router, false);
    if (to.name && to.name !== 'home') {
      displayMessage(store, `${i18n.t('login.requiresAuth')} ${to.name}`, 'warning');
      next({ name: 'login', query: { to: to.name, ...to.params } });
    } else {
      next({ name: 'signup' });
    }
    return false;
  }
}

async function resolveInternalPath(to, next) {
  const toTarget = to.query.to;
  if (
    toTarget &&
    typeof toTarget === 'string' &&
    _.find(routes, { name: toTarget, meta: { requiresVerification: true } })
  ) {
    // If user is logged in, redirect to right page
    let targetQuery = to.query;
    if (!store.state.isInvalid) {
      // Remove to parameter to avoid inf redirect cycle if session is valid
      targetQuery = _.omit(targetQuery, 'to');
    }
    next({ name: toTarget, query: targetQuery });
  } else {
    next();
  }
}

// eslint-disable-next-line max-lines-per-function
router.beforeResolve(async (to, from, next) => {
  // Locale override with query
  const { lang } = to.query;
  if (lang) {
    i18n.locale = lang.toString();
  } else {
    const { me } = store.state.api;
    if (me) {
      i18n.locale = me.language;
    } else {
      const userLang = localStorage.getItem('USER_LANG');
      if (userLang) {
        i18n.locale = userLang;
      } else {
        const { language } = navigator;
        if (language.startsWith('sv')) {
          i18n.locale = 'sv';
        } else if (language.startsWith('de')) {
          i18n.locale = 'de';
        } else if (language.startsWith('fr')) {
          i18n.locale = 'fr';
        } else {
          i18n.locale = 'en';
        }
      }
    }
  }

  // Set document title
  document.title = process.env.VUE_APP_APP_TITLE;
  // Disable loading state
  store.commit('loading', false);
  const { matched } = to;
  const { api } = store.state;
  // Check if logged in & verified & has license
  if (
    matched.some(
      (record) =>
        record.meta.requiresLicense || record.meta.requiresVerification || record.meta.requiresAuth,
    )
  ) {
    const { requiresLicense, requiresVerification, featureKey } = matched[0].meta;
    const loggedIn = await verifyUserLoggedin(to, next);
    let disabled = false;
    if (featureKey) {
      // Feature key features require login
      disabled = !loggedIn || !(await isFeatureEnabled(store, featureKey));
    }
    if (loggedIn) {
      if ((requiresLicense || requiresVerification) && !api.me.emailVerified) {
        next({ name: 'verifyemail' });
      } else if (disabled || (requiresLicense && !store.getters['api/hasValidLicense'])) {
        next({ name: 'home' });
      } else {
        resolveInternalPath(to, next);
      }
    } else {
      next({ name: 'login', query: to.query });
    }
  } else {
    next({ query: to.query }); // does not require auth
  }
});

export default router;
