<template>
  <v-container>
    <v-card
      style="max-width: 500px; margin: auto"
      class="primary pt-8 pb-4 px-5"
      elevation="0"
      rounded="xl"
    >
      <h2 class="ma-0 text-center">
        {{ $t('reports.steps') }}
      </h2>

      <p class="pa-5 text-center subtitle" v-html="stepDescription" />
      <section class="graph">
        <div class="steps">
          <p ref="blocks" v-for="(s, i) in yAxis" :key="`bs${i}`">{{ s }}</p>
        </div>
        <div class="content" ref="graph">
          <div class="steps-goal" :style="{ bottom: `${goalHeight(this.stepGoal)}` }">
            <p>step goal ({{ this.stepGoal }})</p>
          </div>
          <div class="bars" :style="{ paddingBottom: `${blockHeight}px` }">
            <div
              v-for="(v, i) in this.reportData"
              :key="`v${i}`"
              :style="{
                height: `${barHeight(v)}`,
              }"
            >
              <v-tooltip zIndex="9999" color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="bar-content" v-bind="attrs" v-on="on"></div>
                </template>
                <span>{{ v }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="xaxis">
            <div v-for="(d, i) in this.selectedReport.xAxis" :key="`d${i}`">
              <p>
                {{ d.day }} <br />
                {{ d.date }}
              </p>
            </div>
          </div>
        </div>
      </section>
    </v-card>
  </v-container>
</template>
<script>
import { getWeeklySensorData } from '../../progress/routines';

export default {
  name: 'ReportSteps',
  props: {
    selectedReport: Object,
  },
  data() {
    return {
      viewId: '018',
      loaded: false,
      yAxis: [],
      reportData: [],
      blockSize: 500,
      blockHeight: 0,
      graphHeight: 0,
      interval: 0,
      stepGoal: 0,
      highest: 0,
    };
  },
  computed: {
    stepDescription() {
      if (this.loaded === false) {
        return this.$i18n.t('reports.loading');
      }
      const avgStep = this.calculateAverageStep(this.reportData);
      return this.$i18n.t('reports.stepsResultHtml', {
        numberOfSteps: avgStep,
      });
    },
  },
  methods: {
    getHighest() {
      const values = [...this.reportData];
      values.push(this.selectedReport.stepGoal);
      values.push(this.blockSize);
      const onlyNumbers = values.filter((value) => !Number.isNaN(value));
      return Math.round(Math.max(...onlyNumbers) / 100) * 100;
    },
    calculateAverageStep(arr) {
      const onlyNumbers = arr.filter((value) => !Number.isNaN(value));
      const sum = onlyNumbers.reduce((acc, val) => acc + val, 0);
      return onlyNumbers.length === 0 ? null : Math.round(sum / onlyNumbers.length);
    },
    calculateYAxis() {
      const yAxis = [];
      for (let i = this.highest / this.blockSize; i >= 0; i -= 1) {
        yAxis.push(Math.round(this.blockSize * i));
      }
      return yAxis;
    },
    getBlockSize() {
      if (this.highest <= 5000) return 500;
      if (this.highest <= 10000) return 1000;
      if (this.highest <= 15000) return 2000;
      if (this.highest <= 15000) return 2500;
      return 5000;
    },
    barHeight(value) {
      if (!this.loaded || value === 0) return '0%';
      return `${this.scaleValue(value)}px`;
    },
    goalHeight(value) {
      if (!this.loaded || value === 0) return '0';
      return `${this.scaleValue(value, true)}px`;
    },
    scaleValue(value, addPadding = false) {
      let scaledValue = (value / this.highest) * (this.graphHeight - this.blockHeight * 1.5);
      if (addPadding) {
        scaledValue += this.blockHeight;
      }
      return scaledValue;
    },
  },
  updated() {
    this.blockHeight = this.$refs.blocks[0].clientHeight;
    this.graphHeight = this.$refs.graph.clientHeight;
  },
  async mounted() {
    console.log(`Mounted Steps ${JSON.stringify(this.selectedReport)}`);
    const sensorData = await getWeeklySensorData(
      this.$store,
      this.$router,
      this.viewId,
      `walk-steps`,
      this.selectedReport.week,
    );
    this.reportData = sensorData.map((d) => (d && d[0] && d[0].value) || 0);
    this.highest = this.getHighest();
    this.blockSize = this.getBlockSize();
    this.yAxis = this.calculateYAxis();
    this.stepGoal = this.selectedReport.stepGoal;

    this.loaded = true;
  },
};
</script>
<style lang="scss" scoped>
.subtitle {
  font-size: larger;
}

$datesPos: 65px;
.graph {
  position: relative;
  display: flex;
  padding-bottom: $datesPos;
}

.yaxis {
  display: flex;
  flex-direction: column-reverse;
  > p {
    flex: 1;
  }
  p:first-child {
    margin-bottom: 0 !important;
  }
}
.content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  .bars {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex: 1;
    > div {
      position: relative;
      width: 100%;
      max-width: 30px;
      margin: 0 auto;
      flex: 1;
      border-radius: 50px;
      height: 80px;
      transition: all 0.3s;
      background-color: #5db6bf;
      border-color: #5db6bf;
      &:hover {
        background: #85d8e0 !important;
      }
      .bar-content {
        height: 100%;
      }
      @media (max-width: 500px) {
        max-width: 20px;
      }
    }
  }
}
.xaxis {
  display: flex;
  text-align: center;
  position: absolute;
  bottom: -$datesPos;
  width: 100%;
  > div {
    position: relative;
    flex: 1;
  }
  > p {
    flex: 1;
    margin-bottom: 0;
  }
}

.steps-goal {
  position: absolute;
  z-index: 99;
  left: 0;
  width: 100%;
  p {
    margin-bottom: 0;
    padding-left: 4px;
    color: cyan;
    border-bottom: 2px solid cyan;
  }
}
.v-tooltip__content.menuable__content__active::before {
  content: '';
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #a55dff;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
