var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "520px", "content-class": "elevation-0" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "p",
                    _vm._g(
                      {
                        staticClass: "mb-3",
                        attrs: {
                          color: "secondary",
                          rounded: "",
                          block: "",
                          large: "",
                          depressed: "",
                        },
                      },
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.displayDialog,
            callback: function ($$v) {
              _vm.displayDialog = $$v
            },
            expression: "displayDialog",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                padding: "40px 10px 20px 10px",
                "background-color": "transparent",
                overflow: "hidden",
              },
            },
            [
              _c(
                "v-card",
                {
                  staticStyle: {
                    "border-radius": "20px",
                    "text-align": "center",
                    "vertical-align": "top",
                    display: "inline-block",
                    width: "100%",
                    "background-color": "transparent",
                  },
                  attrs: { color: "primary" },
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        "justify-content": "center",
                        "align-items": "center",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "120px", height: "200px" },
                        attrs: {
                          src: require("../assets/closeapp_popup picture.png"),
                        },
                      }),
                      _c(
                        "h3",
                        {
                          staticStyle: {
                            padding: "25px 0px 20px 0px",
                            "max-width": "400px",
                            "word-break": "break-word",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("fig.medication.reopen")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            padding: "10px 0px 15px 0px",
                            "font-weight": "400",
                            "max-width": "400px",
                            "word-break": "break-word",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("fig.medication.restartGame")) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "px-15",
                          attrs: {
                            color: "secondary",
                            rounded: "",
                            depressed: "",
                            "x-large": "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.displayDialog = false
                            },
                          },
                        },
                        [
                          _c("b", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("fig.medication.gotIt"))
                            ),
                          ]),
                        ]
                      ),
                      _c("br"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }