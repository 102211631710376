<template>
  <v-container>
    <v-card
      style="max-width: 500px; margin: auto"
      class="primary pt-8 pb-4 px-5"
      elevation="0"
      rounded="xl"
    >
      <h2 class="ma-0 text-center" v-if="loaded">
        {{ $t('reports.mood') }}
      </h2>

      <p class="pa-5 text-center subtitle" v-html="moodDescription" />
      <!-- Graph -->
      <section class="graph">
        <div ref="graph" class="yaxis">
          <div v-for="m in yAxis" :key="m.label">
            <p>{{ m.label }}</p>
            <div class="lines" :class="{ two: m.line == 2, noline: m.line === 0 }">
              <div c v-for="line in m.line" :key="`l${line}`" />
            </div>
          </div>
        </div>
        <div class="xaxis" v-if="loaded">
          <div v-for="(d, i) in this.selectedReport.xAxis" :key="`d${i}`">
            <p>
              {{ d.day }} <br />
              {{ d.date }}
            </p>
            <div
              class="circle"
              :style="{
                background: getColor(i),
                transform: `translate(-50%, -${translateY(i)}px)`,
              }"
            />
          </div>
        </div>
      </section>
    </v-card>
  </v-container>
</template>

<script>
import { getWeeklySensorData } from '../../progress/routines';

export default {
  name: 'ReportMood',
  props: {
    selectedReport: Object,
  },
  data() {
    return {
      viewId: '017',
      loaded: false,
      yAxis: [],
      reportData: [],
    };
  },
  graphHeight: null,
  totalMoods: null,
  moodHeight: null,
  valuesInRange: [],
  computed: {
    moodDescription() {
      if (this.loaded === false) {
        return this.$i18n.t('reports.loading');
      }
      const avgMood = this.calculateAverageMood(this.reportData);
      if (avgMood === null) {
        return this.$i18n.t('reports.noData');
      }
      return this.$i18n.t('reports.moodResultHtml', {
        moodLevel: this.$i18n.t(`reports.moods.${this.selectedReport.condition}_${avgMood}`),
      });
    },
  },
  methods: {
    translateY(pos) {
      const data = this.reportData[pos];
      if (this.selectedReport.condition === 'bipolar') {
        // has to add 1 to moodHeight to offset extra bar
        return (this.moodHeight + 1) * (data + 1) - 5;
      }
      // 5  is difference bettwen yAxis margin-bottom (20) and circle radius (15)
      return this.moodHeight * (data + 1) - 5;
    },
    getColor(pos) {
      const data = this.reportData[pos];
      const index = this.valuesInRange.findIndex((arr) => arr.includes(data));
      if (index === -1) return 'transparent';
      return this.yAxis[index].color;
    },
    calculateAverageMood(arr) {
      const onlyNumbers = arr.filter((x) => typeof x === 'number' && !Number.isNaN(x));
      const sum = onlyNumbers.reduce((acc, val) => acc + val, 0);
      const mood = onlyNumbers.length === 0 ? null : Math.round(sum / onlyNumbers.length);
      return this.transformMoodValue(mood);
    },
    transformMoodValue(value) {
      if (this.selectedReport.condition === 'bipolar') {
        switch (value) {
          case 0:
          case 1:
            return 0;
          case 2:
          case 3:
            return 1;
          case 4:
          case 5:
          case 6:
            return 2;
          case 7:
          case 8:
            return 3;
          case 9:
          case 10:
            return 4;
          default:
            return null; // Invalid input value
        }
      } else {
        switch (value) {
          case 0:
          case 1:
            return 0;
          case 2:
          case 3:
            return 1;
          case 4:
            return 1;
          case 5:
          case 6:
          case 7:
            return 2;
          case 8:
          case 9:
            return 3;
          case 10:
            return 4;
          default:
            return null; // Invalid input value
        }
      }
    },
    getYAxis(condition) {
      const langKey = `reports.moods.${this.selectedReport.condition}`;
      if (condition === 'bipolar') {
        return [
          { label: this.$i18n.t(`${langKey}_4`), color: '#F0775B', line: 1 },
          { label: this.$i18n.t(`${langKey}_3`), color: '#F09981', line: 1 },
          { label: this.$i18n.t(`${langKey}_2`), color: '#A1DDA1', line: 2 },
          { label: this.$i18n.t(`${langKey}_1`), color: '#8E93E9', line: 1 },
          { label: this.$i18n.t(`${langKey}_0`), color: '#6C61B0', line: 1 },
        ];
      }

      return [
        { label: this.$i18n.t(`${langKey}_4`), color: '#58BB7A', line: 0 },
        { label: this.$i18n.t(`${langKey}_3`), color: '#83D27C', line: 1 },
        { label: this.$i18n.t(`${langKey}_2`), color: '#A1DDA1', line: 2 },
        { label: this.$i18n.t(`${langKey}_1`), color: '#8987FF', line: 2 },
        { label: this.$i18n.t(`${langKey}_0`), color: '#6058BB', line: 1 },
      ];
    },
    getValuesInRange() {
      let start = this.totalMoods;
      return this.yAxis.map((m) =>
        new Array(m.line + 1).fill(0).map(() => {
          start -= 1;
          return start;
        }),
      );
    },
  },
  async mounted() {
    console.log(`Mounted Mood ${JSON.stringify(this.selectedReport)}`);
    this.yAxis = this.getYAxis(this.selectedReport.condition);
    const sensorData = await getWeeklySensorData(
      this.$store,
      this.$router,
      this.viewId,
      `mood-${this.selectedReport.condition}`,
      this.selectedReport.week,
    );
    this.reportData = sensorData.map((d) => {
      if (d && d[0] !== undefined && d[0].value !== undefined) {
        return d[0].value;
      }
      return null;
    });
    this.totalMoods = this.yAxis.map((a) => a.line + 1).reduce((v1, v2) => v1 + v2);
    this.graphHeight = this.$refs.graph.clientHeight;
    this.moodHeight = Math.floor(this.$refs.graph.clientHeight / this.totalMoods);
    this.valuesInRange = this.getValuesInRange();
    this.loaded = true;
  },
};
</script>
<style lang="scss" scoped>
$space: 39px;
$gapStart: 20px;

.subtitle {
  font-size: larger;
}

.graph {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.yaxis {
  display: flex;
  flex-direction: column;
  margin-bottom: $gapStart;
  > div {
    display: flex;
    width: 100%;
    padding-left: 8px;
    &:first-child {
      padding: 0 8px;
      padding-right: 0;
    }
    &:nth-child(odd) {
      background: #56369a;
    }
    p {
      display: flex;
      align-items: center;
      flex: 0.18;
      margin-bottom: 0;
    }
    .lines {
      padding: $space 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      > div {
        width: 100%;
        height: 1.5px;
        background-color: #875fdb;
      }
    }
    .noline {
      padding: 0;
      height: 39.75px;
    }
    .lines.two {
      > div:first-child {
        margin-bottom: $space;
      }
    }
  }
}

.xaxis {
  display: flex;
  text-align: center;
  padding-left: 80px;
  width: 100%;
  > div {
    position: relative;
    flex: 1;
  }
  p {
    margin-bottom: 0;
  }
  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: -$gapStart;
    left: 50%;
  }
}
</style>
