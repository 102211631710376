import { progressDispatch } from '../utils/dispatch';

/**
 * Get user playstart as date
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {string} viewId
 * @returns {Promise<string>}
 */
export async function getPlayStart(store, router, viewId) {
  if (!store.getters['progress/hasIntializedProgress']) {
    await progressDispatch(store, router, viewId, 'initialize');
  }

  return store.getters['progress/playStart'];
}

/**
 * Get user timezone as string
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {string} viewId
 * @returns {Promise<string>}
 */
export async function getTimeZone(store, router, viewId) {
  if (!store.getters['progress/hasIntializedProgress']) {
    await progressDispatch(store, router, viewId, 'initialize');
  }

  return store.getters['progress/timeZone'];
}

/**
 * Get Info on routine
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {string} viewId
 * @returns {Promise<import("../store/modules/progress").RoutineSetup[]>}
 */
export async function getRoutineSetup(store, router, viewId) {
  if (!store.getters['progress/hasIntializedProgress']) {
    await progressDispatch(store, router, viewId, 'initialize');
  }
  return store.getters['progress/getRoutineSetup'];
}

/**
 * Get weekly data for sensor
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {string} viewId
 * @param {string} sensorName
 * @param {number} weekIndex
 * @returns {Promise<import("../store/modules/helpers/sensordata").OptionalSensorData[][]>}
 */
export async function getWeeklySensorData(store, router, viewId, sensorName, weekIndex) {
  if (!store.getters['progress/hasIntializedProgress']) {
    await progressDispatch(store, router, viewId, 'initialize');
  }
  return store.getters['progress/getSensorDataForWeek'](sensorName, weekIndex);
}
