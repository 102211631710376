var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "v-card-text",
        [
          _c("p", { staticClass: "font-italic" }, [
            _vm._v(_vm._s(_vm.$t("account.noLicenses"))),
          ]),
          _vm._v(" " + _vm._s(_vm.$t("account.noLicensesDescription"))),
          _c("br"),
          _vm.betaLinks.length > 0
            ? _c("div", [
                _vm._v(_vm._s(_vm.$t("account.noLicensesAcquire"))),
                _c("br"),
              ])
            : _vm._e(),
          _vm._l(_vm.betaLinks, function (beta, i) {
            return _c("div", { key: i }, [
              _c("a", { attrs: { href: beta.link, target: "_blank" } }, [
                _vm._v(" " + _vm._s(_vm.$t(beta.textId)) + " "),
              ]),
              _c("br"),
            ])
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }