var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center mb-3" }, [
                _c("h2", [_vm._v(_vm._s(_vm.$t("nav.account")))]),
              ]),
            ],
            1
          ),
          _vm.user != null
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "col-md-4 offset-md-4 text-center" },
                    [
                      _c("p", { staticClass: "font-weight-light truncate" }, [
                        _vm._v(" " + _vm._s(_vm.user.email) + " "),
                      ]),
                      _vm.user.nickname
                        ? _c("p", { staticClass: "truncate" }, [
                            _vm._v('"' + _vm._s(_vm.user.nickname) + '"'),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "col-md-4 offset-md-4 text-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "px-10",
                      attrs: {
                        color: "secondary",
                        rounded: "",
                        depressed: "",
                        "x-large": "",
                      },
                      on: { click: _vm.downloadData },
                    },
                    [_vm._v(_vm._s(_vm.$t("account.download")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "col-md-4 offset-md-4 text-center" }, [
                _c("p", { staticClass: "font-weight-light" }, [
                  _vm._v(" " + _vm._s(_vm.$t("account.removeText")) + " "),
                  _c(
                    "a",
                    {
                      staticClass: "white--text text-decoration-underline",
                      attrs: { href: "mailto:support@mindforcegamelab.com" },
                    },
                    [_vm._v("support@mindforcegamelab.com")]
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "col-md-4 offset-md-4 pb-0" },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "text-center px-5 pb-5",
                      staticStyle: { "max-width": "500px", margin: "auto" },
                      attrs: { text: "", color: "primaryDark", flat: "" },
                    },
                    [
                      _c(
                        "v-form",
                        {
                          model: {
                            value: _vm.isPasswordFormValid,
                            callback: function ($$v) {
                              _vm.isPasswordFormValid = $$v
                            },
                            expression: "isPasswordFormValid",
                          },
                        },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "justify-center" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("account.changePassword")) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "mx-5" },
                            [
                              _c("v-text-field", {
                                ref: "current",
                                staticClass: "mgl-append-green mb-4",
                                attrs: {
                                  placeholder: _vm.$t(
                                    "changePassword.currentPassword"
                                  ),
                                  outlined: "",
                                  rounded: "",
                                  color: "secondary",
                                  type: "password",
                                  "prepend-inner-icon": "mdi-lock-outline",
                                  "hide-details": "",
                                  "append-icon": _vm.currentPasswordValidated
                                    ? "mdi-check"
                                    : "",
                                },
                                on: { blur: _vm.confirmCurrentPassword },
                                model: {
                                  value: _vm.currentPassword,
                                  callback: function ($$v) {
                                    _vm.currentPassword = $$v
                                  },
                                  expression: "currentPassword",
                                },
                              }),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: {
                                    top: "",
                                    color: "secondary",
                                    "allow-overflow": "",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                ref: "newPassword",
                                                staticClass:
                                                  "mgl-append-green mb-4",
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "changePassword.newPassword"
                                                  ),
                                                  outlined: "",
                                                  rounded: "",
                                                  color: "secondary",
                                                  type: "password",
                                                  "prepend-inner-icon":
                                                    "mdi-lock-outline",
                                                  validateOnInput: "",
                                                  rules: [
                                                    _vm.rules.required,
                                                    _vm.rules.minLength,
                                                    _vm.rules.uppercase,
                                                    _vm.rules.lowercase,
                                                    _vm.rules.symbol,
                                                    _vm.rules.number,
                                                    _vm.rules.writableSymbols,
                                                  ],
                                                  required: "",
                                                  "hide-details": "",
                                                  "append-icon":
                                                    _vm.allCriteriaFullfilled
                                                      ? "mdi-check"
                                                      : "",
                                                },
                                                model: {
                                                  value: _vm.newPassword,
                                                  callback: function ($$v) {
                                                    _vm.newPassword = $$v
                                                  },
                                                  expression: "newPassword",
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.showTooltip,
                                    callback: function ($$v) {
                                      _vm.showTooltip = $$v
                                    },
                                    expression: "showTooltip",
                                  },
                                },
                                [
                                  _c(
                                    "ul",
                                    { staticClass: "mgl-pwd-list my-2" },
                                    _vm._l(
                                      _vm.passwordCriterias,
                                      function (criteria) {
                                        return _c(
                                          "li",
                                          {
                                            key: criteria.id,
                                            staticClass:
                                              "d-flex align-center mb-1",
                                          },
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  small: "",
                                                  color: criteria.fulfilled
                                                    ? "success"
                                                    : "white",
                                                  outlined: !criteria.fulfilled,
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          criteria.fulfilled,
                                                        expression:
                                                          "criteria.fulfilled",
                                                      },
                                                    ],
                                                    attrs: { small: "" },
                                                  },
                                                  [_vm._v("mdi-check")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(
                                              " " + _vm._s(criteria.text) + " "
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                              _c("v-text-field", {
                                ref: "repeatPassword",
                                staticClass: "mgl-append-green mb-4",
                                attrs: {
                                  placeholder: _vm.$t(
                                    "changePassword.repeatPassword"
                                  ),
                                  outlined: "",
                                  rounded: "",
                                  color: "secondary",
                                  name: "repeatPassword",
                                  "prepend-inner-icon": "mdi-lock-outline",
                                  type: "password",
                                  rules: [
                                    _vm.rules.required,
                                    _vm.rules.matchPassword,
                                  ],
                                  required: "",
                                  validateOnInput: "",
                                  "hide-details": "",
                                  "append-icon": _vm.passwordMatch
                                    ? "mdi-check"
                                    : "",
                                },
                                model: {
                                  value: _vm.repeatPassword,
                                  callback: function ($$v) {
                                    _vm.repeatPassword =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "repeatPassword",
                                },
                              }),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-2",
                                  attrs: {
                                    rounded: "",
                                    depressed: "",
                                    block: "",
                                    "x-large": "",
                                    color: "secondary",
                                    disabled: !_vm.isPasswordFormValid,
                                  },
                                  on: { click: _vm.changePassword },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("account.changePassword"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.user != null
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "col-md-4 offset-md-4 pb-0" },
                    [
                      !_vm.hasEvergreenLicense()
                        ? _c(
                            "v-card",
                            {
                              staticClass: "text-center mb-4",
                              staticStyle: {
                                "max-width": "500px",
                                margin: "auto",
                              },
                              attrs: {
                                text: "",
                                rounded: "",
                                flat: "",
                                color: "primaryDark",
                              },
                            },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "justify-center" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("account.licenses")) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm._l(_vm.user.licenses, function (license, i) {
                                return [
                                  _c("v-card-text", { key: i }, [
                                    _c(
                                      "div",
                                      {
                                        class: _vm.isValidLicense(license)
                                          ? "mx-5"
                                          : "mx-5 mgl-expired",
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            name: "license",
                                            outlined: "",
                                            rounded: "",
                                            disabled: "",
                                            color: "secondary",
                                            type: "text",
                                            "hide-details": "",
                                          },
                                          model: {
                                            value: license.id,
                                            callback: function ($$v) {
                                              _vm.$set(license, "id", $$v)
                                            },
                                            expression: "license.id",
                                          },
                                        }),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "font-weight-thin mt-1 mb-0",
                                          },
                                          [
                                            _vm.isValidLicense(license)
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "addLicense.validUntil"
                                                        )
                                                      ) +
                                                      ": " +
                                                      _vm._s(
                                                        _vm._f("license")(
                                                          license,
                                                          "validToDate"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              : [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "error--text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "addLicense.expired"
                                                            )
                                                          ) +
                                                          ": " +
                                                          _vm._s(
                                                            _vm._f("license")(
                                                              license,
                                                              "expireDate"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              }),
                              _c("BetaSignupLinks", {
                                attrs: { show: _vm.showSignupLinks },
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-expand-transition",
            [
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.canAddLicense,
                      expression: "canAddLicense",
                    },
                  ],
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "col-md-4 offset-md-4 pt-0" },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "text-center px-5 pb-5",
                          attrs: { text: "", color: "primaryDark", flat: "" },
                        },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "justify-center" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("addLicense.addNew")) + " "
                              ),
                            ]
                          ),
                          _c("p", [_vm._v(_vm._s(_vm.$t("addLicense.label")))]),
                          _c(
                            "div",
                            { staticClass: "mx-5" },
                            [
                              _c("v-text-field", {
                                ref: "license",
                                staticClass: "mb-4",
                                attrs: {
                                  placeholder: _vm.$t("addLicense.header"),
                                  outlined: "",
                                  rounded: "",
                                  color: "secondary",
                                  type: "text",
                                  rules: [
                                    _vm.rules.licenseRequired,
                                    _vm.rules.length11,
                                    _vm.rules.format,
                                  ],
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.tempLicense = _vm.tempLicense.replace(
                                      /\s+/g,
                                      ""
                                    )
                                  },
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.preventDefault()
                                  },
                                  paste: _vm.handlePaste,
                                },
                                model: {
                                  value: _vm.tempLicense,
                                  callback: function ($$v) {
                                    _vm.tempLicense = $$v
                                  },
                                  expression: "tempLicense",
                                },
                              }),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-2",
                                  attrs: {
                                    rounded: "",
                                    depressed: "",
                                    block: "",
                                    "x-large": "",
                                    disabled: !_vm.isFormValid,
                                    color: "secondary",
                                  },
                                  on: { click: _vm.addLicense },
                                },
                                [_vm._v(_vm._s(_vm.$t("addLicense.activate")))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.canAddLicense
            ? _c(
                "v-row",
                { staticClass: "mb-5" },
                [
                  _c("v-col", [
                    _c(
                      "a",
                      {
                        staticClass:
                          "float-right white--text text-decoration-underline",
                        attrs: {
                          color: "primary",
                          href: "mailto:support@mindforcegamelab.com",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("addLicense.askFor")))]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.isPlaytient
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "col-md-4 offset-md-4 pb-0" },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "text-center mb-4",
                          staticStyle: { "max-width": "500px", margin: "auto" },
                          attrs: {
                            text: "",
                            rounded: "",
                            flat: "",
                            color: "primaryDark",
                          },
                        },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "justify-center" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("account.deviationEmails")) +
                                  " "
                              ),
                            ]
                          ),
                          _c("p", { staticClass: "mx-5" }, [
                            _c("span", { staticStyle: { float: "left" } }, [
                              _vm._v(_vm._s(_vm.$t("account.adherenceEmails"))),
                            ]),
                            _c(
                              "span",
                              { staticStyle: { float: "right" } },
                              [
                                _c("toggle-button", {
                                  attrs: {
                                    color: {
                                      checked: "#A55DFF",
                                      unchecked: "#1C1051",
                                    },
                                    disabled: !_vm.hasMedicationRequired,
                                    value: _vm.hasSendAdherenceEmails,
                                    height: parseInt("24"),
                                  },
                                  on: { change: _vm.changeAdherenceEmails },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "v-card__text" }, [
                            _vm._v(
                              _vm._s(_vm.$t("account.adherenceEmailsText"))
                            ),
                          ]),
                          _c("hr", {
                            staticStyle: { "border-color": "#383c7a" },
                          }),
                          _c("p", { staticClass: "mx-5 pt-4" }, [
                            _c("span", { staticStyle: { float: "left" } }, [
                              _vm._v(_vm._s(_vm.$t("account.absenceEmails"))),
                            ]),
                            _c(
                              "span",
                              { staticStyle: { float: "right" } },
                              [
                                _c("toggle-button", {
                                  attrs: {
                                    color: {
                                      checked: "#A55DFF",
                                      unchecked: "#1C1051",
                                    },
                                    value: _vm.hasSendAbsenceEmails,
                                    height: parseInt("24"),
                                  },
                                  on: { change: _vm.changeAbsenceEmails },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "v-card__text" }, [
                            _vm._v(_vm._s(_vm.$t("account.absenceEmailsText"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }