var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        {
          staticClass: "primary pt-8 pb-4 px-5",
          staticStyle: { "max-width": "500px", margin: "auto" },
          attrs: { elevation: "0", rounded: "xl" },
        },
        [
          _c("h2", { staticClass: "ma-0 text-center" }, [
            _vm._v(" " + _vm._s(_vm.$t("reports.steps")) + " "),
          ]),
          _c("p", {
            staticClass: "pa-5 text-center subtitle",
            domProps: { innerHTML: _vm._s(_vm.stepDescription) },
          }),
          _c("section", { staticClass: "graph" }, [
            _c(
              "div",
              { staticClass: "steps" },
              _vm._l(_vm.yAxis, function (s, i) {
                return _c(
                  "p",
                  { key: "bs" + i, ref: "blocks", refInFor: true },
                  [_vm._v(_vm._s(s))]
                )
              }),
              0
            ),
            _c("div", { ref: "graph", staticClass: "content" }, [
              _c(
                "div",
                {
                  staticClass: "steps-goal",
                  style: { bottom: "" + _vm.goalHeight(this.stepGoal) },
                },
                [_c("p", [_vm._v("step goal (" + _vm._s(this.stepGoal) + ")")])]
              ),
              _c(
                "div",
                {
                  staticClass: "bars",
                  style: { paddingBottom: _vm.blockHeight + "px" },
                },
                _vm._l(this.reportData, function (v, i) {
                  return _c(
                    "div",
                    {
                      key: "v" + i,
                      style: {
                        height: "" + _vm.barHeight(v),
                      },
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            zIndex: "9999",
                            color: "secondary",
                            top: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b(
                                          { staticClass: "bar-content" },
                                          "div",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v(_vm._s(v))])]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "xaxis" },
                _vm._l(this.selectedReport.xAxis, function (d, i) {
                  return _c("div", { key: "d" + i }, [
                    _c("p", [
                      _vm._v(" " + _vm._s(d.day) + " "),
                      _c("br"),
                      _vm._v(" " + _vm._s(d.date) + " "),
                    ]),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }