var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-img", {
    staticClass: "mx-2",
    attrs: {
      src: _vm.logoUrl,
      "max-height": "80",
      "max-width": "250",
      contain: "",
    },
    on: { click: _vm.goToLogin },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }