/* eslint no-param-reassign: 0 */
import * as _ from 'lodash';
import { hasDefinedProperty } from '../../../utils/objects';
import { getTriggerDateTime } from '../../../utils/cron';
import {
  addDaysToDateTime,
  dateStrInTimeZone,
  datetimeForDateStrInTimeZone,
} from '../../../utils/datetime';
import { getSensorDataKey } from './routineConfig';
import { shouldOverwriteExistingData } from './sensordata';

/**
 * @typedef {{configs?: SensorConfigMap, sensors: SensorDateMap,
 * data: SensorDataMap, endConfigDate?: string}} SensorType
 * @typedef {{createdAt: string, sensors: SensorBasicInfo[]}} SensorConfigEntry
 * @typedef {[string: import('./sensordata').SensorDataEntry[]]} SensorDataMap
 * @typedef {{id: string, schedule: string, anonymousTemplateId?: string, clientId?: string}} SensorBasicInfo
 * @typedef {{sensorTypeId: string, durationInMinutes: number, createdAt: string, eventEpoch: string} & SensorBasicInfo} Sensor
 * @typedef {[string: SensorConfigEntry]} SensorConfigMap
 * @typedef {[string: Sensor[]]} SensorDateMap
 */

/**
 * Finds sensor in sensor date map
 * @param {SensorDateMap} sensorMap
 * @param {string} sensorId
 * @returns {Sensor|undefined}
 */
export function findSensorInSensorTypeMap(sensorMap, sensorId) {
  for (const dateKey in sensorMap) {
    if (hasDefinedProperty(sensorMap, dateKey)) {
      const sensors = sensorMap[dateKey];
      for (const sensor of sensors) {
        if (sensor && sensor.id === sensorId) {
          return sensor;
        }
      }
    }
  }
  return undefined;
}

/**
 * Checks if sensor is valid
 * @param {SensorDateMap} typeMap
 * @param {string} createdAt
 * @param {string} dateStr
 * @returns {boolean}
 */
export function sensorValid(typeMap, createdAt, dateStr) {
  if (!hasDefinedProperty(typeMap, dateStr)) {
    return true;
  }
  const curSensor = typeMap[dateStr];
  return createdAt > curSensor.createdAt;
}

/**
 * Inserts configured sensor for routine
 * @param {SensorType} typeInfo
 * @param {string} dateStr
 * @param {Sensor} sensor
 */
export function insertConfiguredSensorEntry(typeInfo, sensor, dateStr) {
  console.log(`Inserting configured sensor for date ${dateStr}`, sensor);
  const { sensorTypeId, id, createdAt, anonymousTemplateId } = sensor;
  /** @type {SensorConfigEntry|undefined} */
  const sensorConfigs = typeInfo.configs[dateStr];
  if (!sensorConfigs) {
    console.warn(`Found no configurations for sensor of type ${sensorTypeId} on day: ${dateStr}`);
    return;
  }
  const configuredSensors = sensorConfigs.sensors;
  let anonymousTemplateIdUsed = false;
  let index = _.findIndex(configuredSensors, { id });
  if (index === -1) {
    index = _.findIndex(configuredSensors, { id: anonymousTemplateId });
    if (index !== -1) {
      console.warn(
        `Found sensor configuration using anonymous template id instead of sensor id for sensor ${id}!`,
      );
      configuredSensors[index].anonymousTemplateId = anonymousTemplateId;
      anonymousTemplateIdUsed = true;
    }
  }
  if (index === -1) {
    console.warn(`Found no configured sensor on day ${dateStr} with id ${id}`);
    return;
  }

  if (!hasDefinedProperty(typeInfo.sensors, dateStr)) {
    const sensorArray = [];
    for (let i = 0; i < configuredSensors.length; i += 1) {
      sensorArray.push(null);
    }
    sensorArray[index] = sensor;
    insertConfiguredSensor(sensorArray, configuredSensors, index, sensor, anonymousTemplateIdUsed);
    typeInfo.sensors[dateStr] = sensorArray;
  } else {
    const prevSensorSensorEntry = typeInfo.sensors[dateStr][index];
    if (prevSensorSensorEntry === null || prevSensorSensorEntry.createdAt < createdAt) {
      insertConfiguredSensor(
        typeInfo.sensors[dateStr],
        configuredSensors,
        index,
        sensor,
        anonymousTemplateIdUsed,
      );
    } else {
      console.warn(`Skipping obsolete sensor day ${dateStr} with id ${id}`);
    }
  }
}

/**
 * Insert sensor into sensor array
 * @param {(Sensor|null)[]} sensorArray
 * @param {SensorBasicInfo[]} configuredSensors
 * @param {number} index
 * @param {Sensor} sensor
 * @param {boolean} anonymousTemplateIdUsed
 */
function insertConfiguredSensor(
  sensorArray,
  configuredSensors,
  index,
  sensor,
  anonymousTemplateIdUsed,
) {
  sensorArray[index] = sensor;
  if (anonymousTemplateIdUsed) {
    configuredSensors[index].id = sensor.id;
  }
}

/**
 * Get the sensor to use for day
 * @param {string} userTimezone
 * @param {SensorDateMap} sensorMap
 * @param {string} dateStr
 * @param {string} startDateStr
 * @returns {Sensor[]}
 */
function getSensorsForDate(userTimezone, sensorMap, dateStr, startDateStr) {
  return getNearestDateMapEntry(userTimezone, sensorMap, dateStr, startDateStr);
}

/**
 * Gets the nearest date map entry
 * @param {string} userTimezone
 * @param {SensorDateMap|SensorConfigMap} map
 * @param {string} dateStr
 * @param {string} startDateStr
 * @returns {any|undefined}
 */
function getNearestDateMapEntry(userTimezone, map, dateStr, startDateStr) {
  let currentDate = datetimeForDateStrInTimeZone(dateStr, userTimezone);
  const startDate = datetimeForDateStrInTimeZone(startDateStr, userTimezone);
  if (startDate > currentDate) {
    console.warn(
      `Start date ${startDate} is greater than current date ${currentDate}. Using input date: ${dateStr} and start date ${startDateStr} and time zone ${userTimezone}`,
    );
    return undefined;
  }
  let currentDateStr;
  do {
    currentDateStr = dateStrInTimeZone(currentDate.toISO(), userTimezone);
    if (hasDefinedProperty(map, currentDateStr)) {
      return map[currentDateStr];
    }
    currentDate = addDaysToDateTime(currentDate, -1);
  } while (currentDate >= startDate);

  return undefined;
}

/**
 * Get sensor values for day
 * @param {SensorType} typeInfo
 * @param {string} sensorTypeId
 * @param {string} dateStr
 * @param {string} playStartDateStr
 * @param {string} userTimezone
 * @returns {import('./sensordata').OptionalSensorData[]}
 */
export function getSensorValuesOnDay(
  typeInfo,
  sensorTypeId,
  dateStr,
  playStartDateStr,
  userTimezone,
) {
  let valueArr = [];
  const { configs, sensors } = typeInfo;
  const dataMap = typeInfo.data;
  if (hasDefinedProperty(dataMap, dateStr)) {
    const configMap = configs
      ? getSensorConfigForDate(userTimezone, configs, dateStr, playStartDateStr)
      : null;
    valueArr = getSensorDataValuesOnDay(
      dataMap[dateStr],
      sensorTypeId,
      dateStr,
      userTimezone,
      configMap,
    );
  } else if (configs) {
    valueArr = getEmptyDataForSensorConfig(userTimezone, configs, dateStr, playStartDateStr);
  } else {
    valueArr = getEmptyDataForSensor(userTimezone, sensors, dateStr, playStartDateStr);
  }
  return valueArr;
}

/**
 * Gets the sensor config to use for day
 * @param {string} userTimezone
 * @param {SensorConfigMap} configs
 * @param {string} dateStr
 * @param {string} startDateStr
 * @returns {SensorConfigEntry|undefined}
 */
export function getSensorConfigForDate(userTimezone, configs, dateStr, startDateStr) {
  return getNearestDateMapEntry(userTimezone, configs, dateStr, startDateStr);
}

/**
 * Gets the target date for sensor
 * @param {SensorBasicInfo} sensor
 * @param {string} dateStr
 * @param {string} timeZone
 * @returns {string|null}
 */
function getTargetTimestampStrForSensor(sensor, dateStr, timeZone) {
  const { schedule } = sensor;
  const targetAtDateTime = getTriggerDateTime(schedule, dateStr, timeZone);
  let targetAt = null;
  if (targetAtDateTime) {
    targetAt = targetAtDateTime.toUTC().toISO();
  }
  return targetAt;
}

/**
 * Returns data for sensor of type on given day
 * @param {import('./sensordata').SensorDataEntry[]} dayEntries
 * @param {string} sensorTypeId
 * @param {string} dateStr date string
 * @param {string} userTimeZone user timezone
 * @param {SensorConfigEntry|null} configEntry
 * @returns {import('./sensordata').OptionalSensorData[]}
 */
function getSensorDataValuesOnDay(dayEntries, sensorTypeId, dateStr, userTimeZone, configEntry) {
  const dataKeyName = getSensorDataKey(sensorTypeId);
  const logArr = [];
  let i = 0;
  for (const dayEntry of dayEntries) {
    if (dayEntry) {
      const { loggedTimestamp, timestamp } = dayEntry;
      let value;
      if (hasDefinedProperty(dayEntry, dataKeyName)) {
        value = dayEntry[dataKeyName];
      }
      logArr.push({ targetAt: timestamp, loggedAt: loggedTimestamp, value });
    } else if (configEntry) {
      const targetAt = getTargetTimestampForConfiguredDataEntry(
        configEntry,
        dateStr,
        userTimeZone,
        i,
      );
      logArr.push({ targetAt });
    }
    i += 1;
  }
  return logArr;
}

/**
 * Inserts sensor data entry for schedule configured sensor
 * @param {SensorDataMap} sensorDataMap
 * @param {SensorConfigEntry} configs
 * @param {string} dateStr
 * @param {import('./sensordata').SensorData} sensorData
 * @param {string} sensorTypeId
 */
export function insertSensorDataForConfiguredSensor(
  sensorDataMap,
  configs,
  dateStr,
  sensorData,
  sensorTypeId,
) {
  let dataArr;
  if (hasDefinedProperty(sensorDataMap, dateStr)) {
    dataArr = sensorDataMap[dateStr];
  } else {
    dataArr = [];
    for (let i = 0; i < configs.sensors.length; i += 1) {
      dataArr.push(undefined);
    }
  }
  const { sensorId } = sensorData;
  const index = _.findIndex(configs.sensors, { id: sensorId });
  if (index !== -1) {
    if (dataArr[index]) {
      if (shouldOverwriteExistingData(sensorTypeId, sensorData, dataArr[index])) {
        dataArr[index] = sensorData;
      }
    } else {
      dataArr[index] = sensorData;
    }
  } else {
    console.warn(`Could not find configured sensor with id: ${sensorId} on date ${dateStr}`);
    console.log('Sensors', configs.sensors);
  }
  sensorDataMap[dateStr] = dataArr;
}

/**
 * Gets an array of undefined values entries to use when sensor has no config
 * @param {string} userTimeZone
 * @param {SensorDateMap} sensorMap
 * @param {string} dateStr
 * @param {string} playStartDateStr
 * @returns {import('./sensordata').EmptySensorData[]}
 */
function getEmptyDataForSensor(userTimeZone, sensorMap, dateStr, playStartDateStr) {
  const targetSensor = getSensorsForDate(userTimeZone, sensorMap, dateStr, playStartDateStr);
  const valueArr = [];
  if (targetSensor) {
    for (let i = 0; i < targetSensor.length; i += 1) {
      const sensor = targetSensor[i];
      const targetAt = getTargetTimestampStrForSensor(sensor, dateStr, userTimeZone);
      valueArr.push({ targetAt });
    }
  } else {
    console.warn(`Found no sensor for date: ${dateStr}, using playStart: ${playStartDateStr}`);
  }

  return valueArr;
}

/**
 * Gets an array of undefined values entries to use when sensor has config
 * @param {string} userTimezone user time zone
 * @param {SensorConfigMap} configs
 * @param {string} dateStr
 * @param {string} playStartDateStr
 * @returns {import('./sensordata').EmptySensorData[]}
 */
function getEmptyDataForSensorConfig(userTimezone, configs, dateStr, playStartDateStr) {
  const targetConfig = getSensorConfigForDate(userTimezone, configs, dateStr, playStartDateStr);
  const valueArr = [];
  if (targetConfig) {
    for (let i = 0; i < targetConfig.sensors.length; i += 1) {
      const sensor = targetConfig.sensors[i];
      const targetAt = getTargetTimestampStrForSensor(sensor, dateStr, userTimezone);
      valueArr.push({ targetAt });
    }
  } else {
    console.warn(
      `Found no sensor config for date: ${dateStr}, using playStart: ${playStartDateStr}`,
    );
  }
  return valueArr;
}

/**
 * Get target timestamp for sensor data of configured sensor
 * @param {SensorConfigEntry} configEntry
 * @param {string} dateStr
 * @param {string} userTimeZone
 * @param {number} index
 * @returns {string|null}
 */
function getTargetTimestampForConfiguredDataEntry(configEntry, dateStr, userTimeZone, index) {
  const { sensors } = configEntry;
  if (index < sensors.length) {
    const sensor = sensors[index];
    if (sensor) {
      return getTargetTimestampStrForSensor(sensor, dateStr, userTimeZone);
    }
  }
  return null;
}

/**
 * Strip not needed data from sensor entry
 * @param {Sensor} sensorEntry
 * @returns {Sensor}
 */
export function stripSensorEntry(sensorEntry) {
  return _.pick(sensorEntry, [
    'id',
    'schedule',
    'anonymousTemplateId',
    'sensorTypeId',
    'durationInMinutes',
    'createdAt',
    'eventEpoch',
  ]);
}
