const apiErrorMapping = [
  { urlRegex: '^invites/([^/]+)/accept$', apiId: '001' },
  { urlRegex: '^invites$', apiId: '002' },
  { urlRegex: '^invites/([^/]+)$', apiId: '003' },
  { urlRegex: '^users/([^/]+)/team/([^/]+)$', apiId: '004' },
  { urlRegex: '^invites/my\\?expired=false$', apiId: '005' },
  { urlRegex: '^invites?organizationId=([^/]+)&issuerId=([^/]+)&expired=false$', apiId: '006' },
  { urlRegex: '^invites/([^/]+)/public$', apiId: '007' },
  { urlRegex: '^sensors/dataPaginated?appId=([^/]+)&userId=([^/]+)&page=([^/]+)$', apiId: '008' },
  { urlRegex: '^sensors/data\\?appId=([^/]+)&userId=([^/]+)$', apiId: '009' },
  { urlRegex: '^sensors/data\\?appId=([^/]+)&userId=([^/]+)&fromTimestamp=([^/]+)$', apiId: '010' },
  { urlRegex: '^storage\\?userId=([^/]+)&limit=-1$', apiId: '011' },
  {
    urlRegex: '^users/([^/]+)/team?appId=([^/]+)&organizationId=([^/]+)&skipExpired=true$',
    apiId: '012',
  },
  { urlRegex: '^users/([^/]+)/teams?appId=([^/]+)&addInvites=true$', apiId: '013' },
  { urlRegex: '^users/([^/]+)/register-encryption-key$', apiId: '014' },
  { urlRegex: '^users/([^/]+)/stage-encryption-key$', apiId: '015' },
  { urlRegex: '^users/([^/]+)/commit-encryption-key$', apiId: '016' },
  { urlRegex: '^users/([^/]+)/info$', apiId: '017' },
  { urlRegex: '^storage$', apiId: '018' },
  { urlRegex: '^storage\\?sort=-createdAt&key=doses&userId=([^/]+)&appId=([^/]+)$', apiId: '019' },
  { urlRegex: '^users/([^/]+)/add-license$', apiId: '020' },
  { urlRegex: '^users/([^/]+)/create-anonymous-user$', apiId: '021' },
  { urlRegex: '^users/([^/]+)/register-anonymous-user$', apiId: '022' },
  { urlRegex: '^users/([^/]+)/resend-verification-email$', apiId: '023' },
  { urlRegex: '^storage/\\?userId=([^/]+)&key=phq9&appId=([^/]+)&sort=-createdAt$', apiId: '024' },
  {
    urlRegex: '^storage/\\?userId=([^/]+)&key=sensor&appId=([^/]+)&sort=-createdAt&limit=100$',
    apiId: '025',
  },
  {
    urlRegex: '^storage/\\?userId=([^/]+)&key=doses&appId=([^/]+)&sort=-createdAt&limit=-1$',
    apiId: '026',
  },
  { urlRegex: '^me\\?client=portal(?:&key=master)?$', apiId: '027' },
  { urlRegex: '^users/([^/]+)/progress/([^/]+)$', apiId: '028' },
];

export function getApiErrorCodeFromUrl(inputString) {
  for (const { urlRegex, apiId } of apiErrorMapping) {
    const regex = new RegExp(urlRegex);
    if (regex.test(inputString)) {
      return apiId;
    }
  }
  return null;
}
