var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        {
          staticClass: "pa-5",
          staticStyle: { "max-width": "500px", margin: "auto" },
          attrs: { color: "primary", flat: "", rounded: "" },
        },
        [
          _c("h2", { staticClass: "ma-0 text-center" }, [
            _vm._v(" " + _vm._s(_vm.$t("reports.meals")) + " "),
          ]),
          _c("p", {
            staticClass: "pa-5 text-center subtitle",
            domProps: { innerHTML: _vm._s(_vm.mealsDescription) },
          }),
          _c("section", { staticClass: "graph" }, [
            _c("div", { staticClass: "meals" }, [
              _c("div", { staticClass: "yaxis" }, [
                _c("div", [_vm._v(_vm._s(_vm.$t("reports.breakfast")))]),
                _c("div", [_vm._v(_vm._s(_vm.$t("reports.lunch")))]),
                _c("div", [_vm._v(_vm._s(_vm.$t("reports.dinner")))]),
              ]),
              _c(
                "div",
                { staticClass: "checks-content" },
                _vm._l(_vm.reportData, function (c, i) {
                  return _c(
                    "div",
                    { key: "c" + i },
                    _vm._l(c, function (m, i) {
                      return _c(
                        "div",
                        {
                          key: "cc" + i,
                          staticClass: "check",
                          class: { checked: !!m, unchecked: m == false },
                        },
                        [
                          m == true
                            ? _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v("mdi-check"),
                              ])
                            : m == false
                            ? _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v("mdi-close"),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  )
                }),
                0
              ),
            ]),
            _c(
              "div",
              { staticClass: "xaxis" },
              _vm._l(this.selectedReport.xAxis, function (d, i) {
                return _c("div", { key: "d" + i }, [
                  _c("p", [
                    _vm._v(" " + _vm._s(d.day) + " "),
                    _c("br"),
                    _vm._v(" " + _vm._s(d.date) + " "),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }