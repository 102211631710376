<template>
  <div>
    <div :style="fixedBarStyle">
      <!-- Header -->
      <v-row style="display: contents">
        <v-col style="display: flex; justify-content: center">
          <router-link :to="{ name: 'selfassessment' }" style="color: white; align-self: center"
            ><v-icon thin>mdi-arrow-left</v-icon></router-link
          >
          <h2>{{ $t('PHQ9.title') }}</h2>
        </v-col>
      </v-row>
      <!-- Introduction -->
      <v-row style="margin-top: 0px">
        <v-col
          style="display: flex; justify-content: start"
          class="col-md-4 offset-md-4 text-center"
        >
          <p class="font-weight-light">{{ $t('PHQ9.startContext') }}</p>
        </v-col>
      </v-row>
    </div>
    <v-container fluid fill-height style="padding-top: 160px">
      <!-- Question 1 -->
      <v-row>
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-top: 20px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.firstQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 10px;
                "
              >
                <div>
                  <input type="radio" name="optradio" id="zero" value="0" v-model="picked1" />
                  <label style="padding: 10px" for="zero">{{ $t('PHQ9.firstChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="one" value="1" v-model="picked1" />
                  <label style="padding: 10px" for="one">{{ $t('PHQ9.secondChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="two" value="2" v-model="picked1" />
                  <label style="padding: 10px" for="two">{{ $t('PHQ9.thirdChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="three" value="3" v-model="picked1" />
                  <label style="padding: 10px" for="three">{{ $t('PHQ9.fourthChoice') }}</label>
                </div>
                <br />
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Question 2 -->
      <v-row style="padding-top: 60px">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-top: 20px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.secondQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 10px;
                "
              >
                <div>
                  <input type="radio" name="optradio" id="zero2" value="0" v-model="picked2" />
                  <label style="padding: 10px" for="zero2">{{ $t('PHQ9.firstChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="one2" value="1" v-model="picked2" />
                  <label style="padding: 10px" for="one2">{{ $t('PHQ9.secondChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="two2" value="2" v-model="picked2" />
                  <label style="padding: 10px" for="two2">{{ $t('PHQ9.thirdChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="three2" value="3" v-model="picked2" />
                  <label style="padding: 10px" for="three2">{{ $t('PHQ9.fourthChoice') }}</label>
                </div>
                <br />
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Question 3 -->
      <v-row style="padding-top: 60px">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-top: 20px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.thirdQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 10px;
                "
              >
                <div>
                  <input type="radio" name="optradio" id="zero3" value="0" v-model="picked3" />
                  <label style="padding: 10px" for="zero3">{{ $t('PHQ9.firstChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="one3" value="1" v-model="picked3" />
                  <label style="padding: 10px" for="one3">{{ $t('PHQ9.secondChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="two3" value="2" v-model="picked3" />
                  <label style="padding: 10px" for="two3">{{ $t('PHQ9.thirdChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="three3" value="3" v-model="picked3" />
                  <label style="padding: 10px" for="three3">{{ $t('PHQ9.fourthChoice') }}</label>
                </div>
                <br />
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Question 4 -->
      <v-row style="padding-top: 60px">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-top: 20px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.fourthQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 10px;
                "
              >
                <div>
                  <input type="radio" name="optradio" id="zero4" value="0" v-model="picked4" />
                  <label style="padding: 10px" for="zero4">{{ $t('PHQ9.firstChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="one4" value="1" v-model="picked4" />
                  <label style="padding: 10px" for="one4">{{ $t('PHQ9.secondChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="two4" value="2" v-model="picked4" />
                  <label style="padding: 10px" for="two4">{{ $t('PHQ9.thirdChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="three4" value="3" v-model="picked4" />
                  <label style="padding: 10px" for="three4">{{ $t('PHQ9.fourthChoice') }}</label>
                </div>
                <br />
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Question 5 -->
      <v-row style="padding-top: 60px">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-top: 20px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.fifthQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 10px;
                "
              >
                <div>
                  <input type="radio" name="optradio" id="zero5" value="0" v-model="picked5" />
                  <label style="padding: 10px" for="zero5">{{ $t('PHQ9.firstChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="one5" value="1" v-model="picked5" />
                  <label style="padding: 10px" for="one5">{{ $t('PHQ9.secondChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="two5" value="2" v-model="picked5" />
                  <label style="padding: 10px" for="two5">{{ $t('PHQ9.thirdChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="three5" value="3" v-model="picked5" />
                  <label style="padding: 10px" for="three5">{{ $t('PHQ9.fourthChoice') }}</label>
                </div>
                <br />
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Question 6 -->
      <v-row style="padding-top: 60px">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-top: 20px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.sixthQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 10px;
                "
              >
                <div>
                  <input type="radio" name="optradio" id="zero6" value="0" v-model="picked6" />
                  <label style="padding: 10px" for="zero6">{{ $t('PHQ9.firstChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="one6" value="1" v-model="picked6" />
                  <label style="padding: 10px" for="one6">{{ $t('PHQ9.secondChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="two6" value="2" v-model="picked6" />
                  <label style="padding: 10px" for="two6">{{ $t('PHQ9.thirdChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="three6" value="3" v-model="picked6" />
                  <label style="padding: 10px" for="three6">{{ $t('PHQ9.fourthChoice') }}</label>
                </div>
                <br />
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Question 7 -->
      <v-row style="padding-top: 60px">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-top: 20px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.seventhQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 10px;
                "
              >
                <div>
                  <input type="radio" name="optradio" id="zero7" value="0" v-model="picked7" />
                  <label style="padding: 10px" for="zero7">{{ $t('PHQ9.firstChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="one7" value="1" v-model="picked7" />
                  <label style="padding: 10px" for="one7">{{ $t('PHQ9.secondChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="two7" value="2" v-model="picked7" />
                  <label style="padding: 10px" for="two7">{{ $t('PHQ9.thirdChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="three7" value="3" v-model="picked7" />
                  <label style="padding: 10px" for="three7">{{ $t('PHQ9.fourthChoice') }}</label>
                </div>
                <br />
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Question 8 -->
      <v-row style="padding-top: 60px">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-top: 20px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.eightQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 10px;
                "
              >
                <div>
                  <input type="radio" name="optradio" id="zero8" value="0" v-model="picked8" />
                  <label style="padding: 10px" for="zero8">{{ $t('PHQ9.firstChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="one8" value="1" v-model="picked8" />
                  <label style="padding: 10px" for="one8">{{ $t('PHQ9.secondChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="two8" value="2" v-model="picked8" />
                  <label style="padding: 10px" for="two8">{{ $t('PHQ9.thirdChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="three8" value="3" v-model="picked8" />
                  <label style="padding: 10px" for="three8">{{ $t('PHQ9.fourthChoice') }}</label>
                </div>
                <br />
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Question 9 -->
      <v-row style="padding-top: 60px">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-top: 0px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.ninthQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 10px;
                "
              >
                <div>
                  <input type="radio" name="optradio" id="zero9" value="0" v-model="picked9" />
                  <label style="padding: 10px" for="zero9">{{ $t('PHQ9.firstChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="one9" value="1" v-model="picked9" />
                  <label style="padding: 10px" for="one9">{{ $t('PHQ9.secondChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="two9" value="2" v-model="picked9" />
                  <label style="padding: 10px" for="two9">{{ $t('PHQ9.thirdChoice') }}</label>
                </div>
                <br />
                <div>
                  <input type="radio" name="optradio" id="three9" value="3" v-model="picked9" />
                  <label style="padding: 10px" for="three9">{{ $t('PHQ9.fourthChoice') }}</label>
                </div>
                <br />
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="padding: 40px 0 10px 0; color: #ff9999">
        <v-col class="col-md-4 offset-md-4 text-center">
          <p v-if="!isFormFilled">
            {{ $t('PHQ9.needAnswer') }}
          </p>
        </v-col>
      </v-row>
      <v-row style="padding: 20px 0 40px 0">
        <v-col class="col-md-4 offset-md-4 text-center">
          <v-btn
            v-if="!isFormFilled || posting"
            color="secondary"
            v-on:click="saveScore"
            rounded
            disabled
            depressed
            x-large
            class="px-10 disabled"
          >
            {{ $t('PHQ9.finish') }}</v-btn
          >
          <v-btn
            v-else
            color="secondary"
            v-on:click="saveScore"
            rounded
            depressed
            x-large
            class="px-10"
          >
            {{ $t('PHQ9.finish') }}</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { apiPostPhqFormData } from '../api/storage';
import { postEvent } from '../api/unityAnalytics';

export default {
  name: 'PHQ9',
  data() {
    return {
      viewId: '011',
      picked1: '',
      picked2: '',
      picked3: '',
      picked4: '',
      picked5: '',
      picked6: '',
      picked7: '',
      picked8: '',
      picked9: '',
      posting: false,
      fixedBarMargin: 0,
      backgroundColor: 0,
    };
  },
  computed: {
    score() {
      return (
        +this.picked1 +
        +this.picked2 +
        +this.picked3 +
        +this.picked4 +
        +this.picked5 +
        +this.picked6 +
        +this.picked7 +
        +this.picked8 +
        +this.picked9
      );
    },
    isFormFilled() {
      return (
        this.picked1 &&
        this.picked2 &&
        this.picked3 &&
        this.picked4 &&
        this.picked5 &&
        this.picked6 &&
        this.picked7 &&
        this.picked8 &&
        this.picked9
      );
    },
    fixedBarStyle() {
      let style = `position: fixed; width: 100%; z-index: 1; margin-top: ${this.fixedBarMargin}px`;
      if (this.backgroundColor !== 0) {
        style += '; background-color: #4d2a96';
      }
      return style;
    },
  },
  methods: {
    async saveScore() {
      this.posting = true;
      const formDataPosted = await apiPostPhqFormData(this.$store, this.$router, this.viewId, {
        formId: 'phq9',
        formType: 'phq9',
        answers: [
          +this.picked1,
          +this.picked2,
          +this.picked3,
          +this.picked4,
          +this.picked5,
          +this.picked6,
          +this.picked7,
          +this.picked8,
          +this.picked9,
        ],
        score: this.score,
      });
      await postEvent(this.$store, 'PHQFormSubmitted', {
        phqScore: this.score,
        phqType: 'phq9',
      });
      this.$router.push({ name: 'phq9-finish' });
      return formDataPosted;
    },
    handleScroll(event) {
      const { scrollTop } = event.target.scrollingElement;
      this.fixedBarMargin = Math.max(-scrollTop, -80);
      if (this.fixedBarMargin <= -80) {
        this.backgroundColor = 1;
      } else {
        this.backgroundColor = 0;
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
<style lang="scss">
.truncate {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
input[type='radio'] {
  /* Hide original inputs */
  visibility: hidden;
  position: absolute;
}
input[type='radio'] + label:before {
  height: 15px;
  width: 15px;
  margin-left: 5px;
  margin-right: 10px;
  content: '';
  display: inline-block;
  vertical-align: baseline;
  border: 1px solid #777;
  background-color: lightgrey;
}
input[type='radio']:checked + label:before {
  background: #46c55a;
}

/* CUSTOM RADIO */
input[type='radio'] + label:before {
  border-radius: 50%;
}
.disabled {
  pointer-events: none;
}
</style>
