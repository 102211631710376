<template>
  <div>
    <v-container fluid fill-height>
      <!-- Header -->
      <v-row>
        <v-col style="display: flex; justify-content: center; padding: 20px">
          <router-link :to="{ name: 'selfassessment' }" style="color: white; align-self: center"
            ><v-icon thin>mdi-arrow-left</v-icon></router-link
          >
          <h2 style="padding: 20px">{{ $t('PHQ9.title') }}</h2>
        </v-col>
      </v-row>
      <!-- Introduction -->
      <v-row>
        <v-col class="col-md-4 offset-md-4">
          <p class="font-weight-light">{{ $t('PHQ9.explanation') }}</p>
        </v-col>
      </v-row>
      <!-- Evaluation text -->
      <v-row>
        <v-col class="col-md-4 offset-md-4">
          <p class="font-weight-light">{{ $t('PHQ9.evaluation') }}</p>
        </v-col>
      </v-row>
      <!-- RESULT -->
      <v-row>
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px; margin-bottom: 50px"
            rounded
            flat
            class="text-center px-1 pb-5"
          >
            <ScorePopup />
            <v-form style="margin-bottom: 10px">
              <v-row style="padding-top: 20px">
                <v-col style="padding-top: 0px">
                  <p class="font-weight-light" style="font-size: 20px">
                    <b
                      ><b> {{ $t('PHQ9.severity') }} </b></b
                    >
                  </p>
                </v-col>
              </v-row>
              <v-row style="padding-top: 0px">
                <v-col style="padding-top: 0px">
                  <p class="font-weight-light" style="font-size: 14px">
                    {{ formatDate(createdAt) }}
                  </p>
                </v-col>
              </v-row>
              <div style="padding-bottom: 10px">
                <v-btn
                  class="noHover"
                  style="
                    width: 100px;
                    height: 100px;
                    font-size: 24px;
                    background-color: purple;
                    border-radius: 50%;
                    cursor: auto;
                    hover: none;
                  "
                  color="secondary"
                >
                  {{ score }}/27</v-btn
                >
              </div>
              <v-row style="padding-bottom: 0px; margin-bottom: 10px">
                <v-col style="padding-bottom: 0px">
                  <p style="font-size: 14px; font-weight: 200">
                    {{ $t('PHQ9.accordance') }}
                  </p>
                  <h3 v-if="score < 5">
                    {{ $t('PHQ9.minimalDepression') }}
                  </h3>
                  <h3 v-else-if="score < 10">
                    {{ $t('PHQ9.mildDepression') }}
                  </h3>
                  <h3 v-else-if="score < 15">
                    {{ $t('PHQ9.moderateDepression') }}
                  </h3>
                  <h3 v-else-if="score < 20">
                    {{ $t('PHQ9.moderateSevereDepression') }}
                  </h3>
                  <h3 v-else>{{ $t('PHQ9.severeDepression') }}</h3>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Question 1 -->
      <v-row>
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-bottom: 0px">
              <v-col style="padding-bottom: 0px">
                <p class="font-weight-light" style="font-size: 12px">
                  {{ $t('PHQ9.overLast2Weeks') }}
                </p>
              </v-col>
            </v-row>
            <v-row style="padding-top: 0px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.firstQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <fieldset disabled="disabled">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 10px;
                  "
                >
                  <div>
                    <input type="radio" name="optradio" id="zero" value="0" v-model="picked1" />
                    <label style="padding: 10px" for="zero">{{ $t('PHQ9.firstChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="one" value="1" v-model="picked1" />
                    <label style="padding: 10px" for="one">{{ $t('PHQ9.secondChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="two" value="2" v-model="picked1" />
                    <label style="padding: 10px" for="two">{{ $t('PHQ9.thirdChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="three" value="3" v-model="picked1" />
                    <label style="padding: 10px" for="three">{{ $t('PHQ9.fourthChoice') }}</label>
                  </div>
                  <br />
                </div>
              </fieldset>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Question 2 -->
      <v-row style="padding-top: 60px">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-bottom: 0px">
              <v-col style="padding-bottom: 0px">
                <p class="font-weight-light" style="font-size: 12px">
                  {{ $t('PHQ9.overLast2Weeks') }}
                </p>
              </v-col>
            </v-row>
            <v-row style="padding-top: 0px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.secondQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <fieldset disabled="disabled">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 10px;
                  "
                >
                  <div>
                    <input type="radio" name="optradio" id="zero2" value="0" v-model="picked2" />
                    <label style="padding: 10px" for="zero2">{{ $t('PHQ9.firstChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="one2" value="1" v-model="picked2" />
                    <label style="padding: 10px" for="one2">{{ $t('PHQ9.secondChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="two2" value="2" v-model="picked2" />
                    <label style="padding: 10px" for="two2">{{ $t('PHQ9.thirdChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="three2" value="3" v-model="picked2" />
                    <label style="padding: 10px" for="three2">{{ $t('PHQ9.fourthChoice') }}</label>
                  </div>
                  <br />
                </div>
              </fieldset>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Question 3 -->
      <v-row style="padding-top: 60px">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-bottom: 0px">
              <v-col style="padding-bottom: 0px">
                <p class="font-weight-light" style="font-size: 12px">
                  {{ $t('PHQ9.overLast2Weeks') }}
                </p>
              </v-col>
            </v-row>
            <v-row style="padding-top: 0px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.thirdQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <fieldset disabled="disabled">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 10px;
                  "
                >
                  <div>
                    <input
                      type="radio"
                      name="optradio"
                      id="zero3"
                      value="0"
                      disabled="disabled"
                      selected="selected"
                      v-model="picked3"
                    />
                    <label style="padding: 10px" for="zero3">{{ $t('PHQ9.firstChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input
                      type="radio"
                      name="optradio"
                      id="one3"
                      value="1"
                      disabled="disabled"
                      selected="selected"
                      v-model="picked3"
                    />
                    <label style="padding: 10px" for="one3">{{ $t('PHQ9.secondChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input
                      type="radio"
                      name="optradio"
                      id="two3"
                      value="2"
                      disabled="disabled"
                      selected="selected"
                      v-model="picked3"
                    />
                    <label style="padding: 10px" for="two3">{{ $t('PHQ9.thirdChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input
                      type="radio"
                      name="optradio"
                      id="three3"
                      value="3"
                      disabled="disabled"
                      selected="selected"
                      v-model="picked3"
                    />
                    <label style="padding: 10px" for="three3">{{ $t('PHQ9.fourthChoice') }}</label>
                  </div>
                  <br />
                </div>
              </fieldset>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Question 4 -->
      <v-row style="padding-top: 60px">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-bottom: 0px">
              <v-col style="padding-bottom: 0px">
                <p class="font-weight-light" style="font-size: 12px">
                  {{ $t('PHQ9.overLast2Weeks') }}
                </p>
              </v-col>
            </v-row>
            <v-row style="padding-top: 0px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.fourthQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <fieldset disabled="disabled">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 10px;
                  "
                >
                  <div>
                    <input type="radio" name="optradio" id="zero4" value="0" v-model="picked4" />
                    <label style="padding: 10px" for="zero4">{{ $t('PHQ9.firstChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="one4" value="1" v-model="picked4" />
                    <label style="padding: 10px" for="one4">{{ $t('PHQ9.secondChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="two4" value="2" v-model="picked4" />
                    <label style="padding: 10px" for="two4">{{ $t('PHQ9.thirdChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="three4" value="3" v-model="picked4" />
                    <label style="padding: 10px" for="three4">{{ $t('PHQ9.fourthChoice') }}</label>
                  </div>
                  <br />
                </div>
              </fieldset>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Question 5 -->
      <v-row style="padding-top: 60px">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-bottom: 0px">
              <v-col style="padding-bottom: 0px">
                <p class="font-weight-light" style="font-size: 12px">
                  {{ $t('PHQ9.overLast2Weeks') }}
                </p>
              </v-col>
            </v-row>
            <v-row style="padding-top: 0px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.fifthQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <fieldset disabled="disabled">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 10px;
                  "
                >
                  <div>
                    <input type="radio" name="optradio" id="zero5" value="0" v-model="picked5" />
                    <label style="padding: 10px" for="zero5">{{ $t('PHQ9.firstChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="one5" value="1" v-model="picked5" />
                    <label style="padding: 10px" for="one5">{{ $t('PHQ9.secondChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="two5" value="2" v-model="picked5" />
                    <label style="padding: 10px" for="two5">{{ $t('PHQ9.thirdChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="three5" value="3" v-model="picked5" />
                    <label style="padding: 10px" for="three5">{{ $t('PHQ9.fourthChoice') }}</label>
                  </div>
                  <br />
                </div>
              </fieldset>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Question 6 -->
      <v-row style="padding-top: 60px">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-bottom: 0px">
              <v-col style="padding-bottom: 0px">
                <p class="font-weight-light" style="font-size: 12px">
                  {{ $t('PHQ9.overLast2Weeks') }}
                </p>
              </v-col>
            </v-row>
            <v-row style="padding-top: 0px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.sixthQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <fieldset disabled="disabled">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 10px;
                  "
                >
                  <div>
                    <input type="radio" name="optradio" id="zero6" value="0" v-model="picked6" />
                    <label style="padding: 10px" for="zero6">{{ $t('PHQ9.firstChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="one6" value="1" v-model="picked6" />
                    <label style="padding: 10px" for="one6">{{ $t('PHQ9.secondChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="two6" value="2" v-model="picked6" />
                    <label style="padding: 10px" for="two6">{{ $t('PHQ9.thirdChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="three6" value="3" v-model="picked6" />
                    <label style="padding: 10px" for="three6">{{ $t('PHQ9.fourthChoice') }}</label>
                  </div>
                  <br />
                </div>
              </fieldset>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Question 7 -->
      <v-row style="padding-top: 60px">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-bottom: 0px">
              <v-col style="padding-bottom: 0px">
                <p class="font-weight-light" style="font-size: 12px">
                  {{ $t('PHQ9.overLast2Weeks') }}
                </p>
              </v-col>
            </v-row>
            <v-row style="padding-top: 0px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.seventhQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <fieldset disabled="disabled">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 10px;
                  "
                >
                  <div>
                    <input type="radio" name="optradio" id="zero7" value="0" v-model="picked7" />
                    <label style="padding: 10px" for="zero7">{{ $t('PHQ9.firstChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="one7" value="1" v-model="picked7" />
                    <label style="padding: 10px" for="one7">{{ $t('PHQ9.secondChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="two7" value="2" v-model="picked7" />
                    <label style="padding: 10px" for="two7">{{ $t('PHQ9.thirdChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="three7" value="3" v-model="picked7" />
                    <label style="padding: 10px" for="three7">{{ $t('PHQ9.fourthChoice') }}</label>
                  </div>
                  <br />
                </div>
              </fieldset>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Question 8 -->
      <v-row style="padding-top: 60px">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-bottom: 0px">
              <v-col style="padding-bottom: 0px">
                <p class="font-weight-light" style="font-size: 12px">
                  {{ $t('PHQ9.overLast2Weeks') }}
                </p>
              </v-col>
            </v-row>
            <v-row style="padding-top: 0px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.eightQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <fieldset disabled="disabled">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 10px;
                  "
                >
                  <div>
                    <input type="radio" name="optradio" id="zero8" value="0" v-model="picked8" />
                    <label style="padding: 10px" for="zero8">{{ $t('PHQ9.firstChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="one8" value="1" v-model="picked8" />
                    <label style="padding: 10px" for="one8">{{ $t('PHQ9.secondChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="two8" value="2" v-model="picked8" />
                    <label style="padding: 10px" for="two8">{{ $t('PHQ9.thirdChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="three8" value="3" v-model="picked8" />
                    <label style="padding: 10px" for="three8">{{ $t('PHQ9.fourthChoice') }}</label>
                  </div>
                  <br />
                </div>
              </fieldset>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <!-- Question 9 -->
      <v-row style="padding-top: 60px">
        <v-col class="col-md-4 offset-md-4 pb-0">
          <v-card
            style="background-color: #41257e; padding-bottom: 0px"
            rounded
            flat
            class="text-center px-5 pb-5"
          >
            <v-row style="padding-bottom: 0px">
              <v-col style="padding-bottom: 0px">
                <p class="font-weight-light" style="font-size: 12px">
                  {{ $t('PHQ9.overLast2Weeks') }}
                </p>
              </v-col>
            </v-row>
            <v-row style="padding-top: 0px">
              <v-col style="padding-top: 0px">
                <p class="font-weight-light" style="padding-top: 0px">
                  <b
                    ><b> {{ $t('PHQ9.ninthQuestion') }} </b></b
                  >
                </p>
              </v-col>
            </v-row>
            <v-form>
              <fieldset disabled="disabled">
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 10px;
                  "
                >
                  <div>
                    <input type="radio" name="optradio" id="zero9" value="0" v-model="picked9" />
                    <label style="padding: 10px" for="zero9">{{ $t('PHQ9.firstChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="one9" value="1" v-model="picked9" />
                    <label style="padding: 10px" for="one9">{{ $t('PHQ9.secondChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="two9" value="2" v-model="picked9" />
                    <label style="padding: 10px" for="two9">{{ $t('PHQ9.thirdChoice') }}</label>
                  </div>
                  <br />
                  <div>
                    <input type="radio" name="optradio" id="three9" value="3" v-model="picked9" />
                    <label style="padding: 10px" for="three9">{{ $t('PHQ9.fourthChoice') }}</label>
                  </div>
                  <br />
                </div>
              </fieldset>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="padding: 40px 0 40px 0">
        <v-col class="col-md-4 offset-md-4 text-center"> </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ScorePopup from '../components/ScorePopup.vue';
import { apiGetPhqFormData } from '../api/storage';

export default {
  components: { ScorePopup },
  name: 'PHQ9Result',
  data() {
    return {
      viewId: '013',
      picked1: '',
      picked2: '',
      picked3: '',
      picked4: '',
      picked5: '',
      picked6: '',
      picked7: '',
      picked8: '',
      picked9: '',
      score: 0,
      createdAt: '',
    };
  },
  methods: {
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      // @ts-ignore
      return new Date(date).toLocaleDateString('en-GB', options);
    },
    setPickedAnswers(answerArr) {
      for (let i = 0; i < answerArr.length; i += 1) {
        this[`picked${i + 1}`] = `${answerArr[i]}`;
      }
    },
  },
  async mounted() {
    const formId = this.$route.query.id;
    if (!formId) {
      console.log('Missing form entry id');
      return;
    }
    const forms = await apiGetPhqFormData(this.$store, this.$router, this.viewId, true);
    for (const form of forms) {
      if (form.id === formId) {
        this.score = form.score;
        this.createdAt = form.createdAt;
        this.setPickedAnswers(form.answers);
        break;
      }
    }
  },
};
</script>
<style lang="scss">
.truncate {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noHover {
  pointer-events: none;
}

fieldset {
  border: 0;
}
</style>
