import { hasDefinedProperty } from '../../../utils/objects';
import { getRoutineDefinition } from './routineConfig';

/**
 * @typedef {{name: string, unlockedAt?: string, unlockDate?: Date}} RoutineEntry
 * @typedef {{name: string, unlocked: boolean, unlockedAt?: string, types: [string: import('./sensors').SensorType] }} RoutineInfo
 */

/**
 * Get routine info object
 * @param {string} routineName
 * @param {string|undefined} unlockDateStr
 * @param {object} [extras]
 * @return {RoutineInfo}
 */
export function getRoutineInfo(routineName, unlockDateStr, extras) {
  const config = getRoutineDefinition(routineName);
  const { name } = config;
  const info = { name, unlocked: false, types: {} };
  if (unlockDateStr) {
    info.unlocked = true;
    info.unlockedAt = unlockDateStr;
  }
  if (extras) {
    Object.assign(info, extras);
  }
  // @ts-ignore
  return info;
}

/**
 * Get routine entry
 * @param {RoutineInfo} routine
 * @returns {RoutineEntry}
 */
export function getRoutineEntry(routine) {
  const entry = { name: '?' };
  for (const key in routine) {
    if (hasDefinedProperty(routine, key) && key !== 'types' && key !== 'unlocked') {
      entry[key] = routine[key];
    }
  }
  return entry;
}
