<template>
  <div>
    <v-app-bar flat color="primary" class="mgl-hero d-flex align-center justify-center">
      <Logo />
    </v-app-bar>

    <SimpleLocalePicker class="mgl-simple-locale" />

    <SignupForm
      ref="signupForm"
      v-bind:inviteEmail="inviteEmail"
      v-bind:stepId="stepId"
      v-bind:betaClosedError="betaClosedError"
      @signup="signUp"
    />
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import Logo from '../components/Logo.vue';
import SimpleLocalePicker from '../components/SimpleLocalePicker.vue';
import SignupForm from './components/SignupForm.vue';
import { initRsaKey } from '../utils/crypto';
import { initAnonymousUser } from '../utils/anonymousAuth';
import signOut from '../utils/auth';
import { displayError, displayMessage, setLoading, setLoadingText } from '../utils/loading';
import { apiDispatch } from '../utils/dispatch';
import { getTargetRoute } from '../utils/routeHandler';
import { postEvent } from '../api/unityAnalytics';
import { isFeatureEnabled } from '../utils/config';

/**
 * @typedef {{type: string, lang: string, inviteId: string|undefined, nickname: string, onboarded: string,
 * signupHash: string|undefined, signupApp?: string}} ClientMetadata
 */

export default {
  name: 'Signup',
  components: {
    Logo,
    SimpleLocalePicker,
    SignupForm,
  },
  data() {
    return {
      viewId: '007',
      inviteEmail: '',
      inviteId: undefined,
      inviteObj: null,
      stepId: '1',
      signupHash: undefined,
      signupApp: undefined,
      betaClosedError: false,
    };
  },
  beforeCreate() {
    if (!('stepId' in this.$route.query)) {
      console.log('No stepId in query. Signing out..');
      signOut(this.$store, this.$router, false);
    }
  },
  async created() {
    // Set signup hash and check if beta signup is allowed
    let signupHash = this.$route.query.shash;
    const signupApp = this.$route.query.app;
    const betaSignupAllowed = await isFeatureEnabled(this.$store, 'ALLOW_BETA_SIGNUP');
    console.log('Beta signup allowed:', betaSignupAllowed);
    if (!betaSignupAllowed && (!signupApp || signupApp === 'beta')) {
      if (signupHash) {
        this.betaClosedError = true;
      }
      signupHash = undefined;
    }
    this.signupHash = signupHash;
    this.signupApp = signupApp;

    // check if invite is used here:
    const { query } = this.$route;
    if ('inviteId' in query) {
      const { inviteId } = query;
      this.inviteId = inviteId;
      // Load invite email and set type to team
      let inviteObj = this.$store.getters['api/getInviteObject'];
      if (!inviteObj) {
        inviteObj = await apiDispatch(this.$store, this.$router, 'getInvitePublic', {
          inviteId,
          viewId: this.viewId,
        });
        console.log('Fetched invite object');
        this.inviteObj = inviteObj;
      }
      if (inviteObj && inviteObj.email) {
        this.inviteEmail = inviteObj.email;
      } else {
        this.inviteId = undefined;
      }
    }
    if ('stepId' in query) {
      const { stepId } = query;
      this.stepId = stepId;
    }
  },
  methods: {
    getSignupForm() {
      return this.$refs.signupForm;
    },
    async beginSignup(signupHash, step, signupApp) {
      console.log('Begin signup!!');
      const { password, email, anonymousUserInfo, userInfo } = this.getSignupForm();
      let username = this.inviteEmail || email;
      username = username.toLowerCase();
      setLoading(this.$store, true, 'signup.title', 'signup.creatingAccount');
      const { inviteId } = this;
      if (step === '2') {
        return { password, inviteId };
      }

      const language = this.$i18n.locale;
      const userType = anonymousUserInfo.type;
      /** @type {ClientMetadata} */
      const clientMetadata = {
        type: userType,
        lang: language,
        inviteId,
        nickname: userInfo.nickname,
        onboarded: 'false',
        signupHash,
      };
      if (signupApp) {
        clientMetadata.signupApp = signupApp;
      }

      // if user signed up as team member, skip onboarding (set onboarded)
      if (userType === 'Team') {
        clientMetadata.onboarded = 'true';
      }
      const signupParams = {
        username,
        password,
        clientMetadata,
      };
      await Auth.signUp(signupParams);
      await Auth.signIn(username, password);
      setLoadingText(this.$store, this.$i18n.t('loading.signup.encryptingAccount'));
      return { password, inviteId };
    },
    async setupAnonymousUser(user, anonymousUserInfo, rsaKey, signupHash) {
      const onboardingStepsToPost = ['signup'];
      const { emailVerified, licenseAutoAssigned } = user;
      const anoymousUserInfoObj = anonymousUserInfo;
      if (licenseAutoAssigned && user.licenses.length) {
        anoymousUserInfoObj.groupIds = user.licenses[0].groupId;
      }
      if (emailVerified && signupHash) {
        onboardingStepsToPost.push('verification');
        if (licenseAutoAssigned) {
          onboardingStepsToPost.push('license');
        }
      }
      await initAnonymousUser(
        this.$store,
        this.$router,
        this.viewId,
        rsaKey,
        user.anonymousUserCredentials,
        anoymousUserInfoObj,
      );
      return onboardingStepsToPost;
    },
    async postUnityAnalyticsOnboardingEvents(onboardingStepsToPost) {
      for (const onboardingStep of onboardingStepsToPost) {
        // eslint-disable-next-line no-await-in-loop
        await postEvent(this.$store, 'OnboardingStepCompleted', {
          onboardingStep,
        });
      }
    },
    async acceptInvite() {
      // Make accept call
      console.log('Accepting invite..');
      this.inviteObj.viewId = this.viewId;
      const [invite] = await apiDispatch(this.$store, this.$router, 'acceptInvite', this.inviteObj);
      if (invite && invite.playtientId) {
        await postEvent(this.$store, 'TeamJoined', {
          teamId: invite.playtientId,
        });
      }
    },
    // eslint-disable-next-line max-lines-per-function
    async signUp(step) {
      const signupForm = this.getSignupForm();
      try {
        const { password, inviteId } = await this.beginSignup(
          this.signupHash,
          step,
          this.signupApp,
        );
        const user = await apiDispatch(this.$store, this.$router, 'getMe', {
          getMasterKey: true,
          viewId: this.viewId,
        });
        const rsaKey = await initRsaKey(
          this.$store,
          this.$router,
          step !== '2' ? password : window.sessionStorage.getItem('signinPwd'),
          user.privateKey,
          user.publicKey,
          this.viewId,
          user.salt,
          user.iv,
          true,
        );
        if (rsaKey === null) {
          throw new Error(this.$i18n.t('account.errorEncryptionKey').toString());
        }

        // Init anonymous user with right group id if user was automatically verified and assigned a license here
        const { anonymousUserInfo } = signupForm;
        if (step === '2') {
          user.anonymousUserCredentials = null;
        }
        const onboardingStepsToPost = await this.setupAnonymousUser(
          user,
          anonymousUserInfo,
          rsaKey,
          this.signupHash,
        );

        // If user signed up from an invite, accept invite here
        if (inviteId) {
          await this.acceptInvite();
        }
        displayMessage(this.$store, this.$i18n.t('signup.signupCompleted'), 'success');
        setLoading(this.$store, false);

        // Send event to Unity Analytics
        await this.postUnityAnalyticsOnboardingEvents(onboardingStepsToPost);

        window.sessionStorage.removeItem('signinPwd');
        // Redirect to home page or verified email
        const targetRoute = getTargetRoute(this.$route, this.$router, user, this.inviteId);
        this.$router.push(targetRoute);
      } catch (error) {
        window.sessionStorage.removeItem('signinPwd');
        displayError(this.$store, error);
      }
    },
  },
};
</script>
