var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "text-center" }, [
                _c("h2", [
                  _vm._v(_vm._s(_vm.$t("fig.medication.scheduleTitle"))),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-stepper",
        {
          staticClass: "mgl-stepper",
          attrs: { vertical: "" },
          model: {
            value: _vm.stepIndex,
            callback: function ($$v) {
              _vm.stepIndex = $$v
            },
            expression: "stepIndex",
          },
        },
        [
          _c(
            "v-stepper-step",
            {
              staticClass: "px-2 py-0",
              attrs: {
                complete: _vm.stepIndex > 1,
                step: "1",
                color: "secondary",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("fig.medication.timeAndDay")) + " ")]
          ),
          _c(
            "v-expand-transition",
            [
              _c(
                "v-container",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.stepIndex > 1,
                      expression: "stepIndex > 1",
                    },
                  ],
                  staticClass: "ml-5 pl-3 pr-8 mgl-step-summary",
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-2 mb-0",
                      attrs: { color: "primaryDark", flat: "", rounded: "" },
                      on: {
                        click: function ($event) {
                          _vm.stepIndex = 1
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "body-2 d-flex justify-space-around font-weight-bold",
                        },
                        [
                          _c("div", { staticClass: "pr-4" }, [
                            _vm._v(_vm._s(_vm.reminderTime)),
                          ]),
                          _c("div", [
                            _c(
                              "ul",
                              { staticClass: "mgl-weekdays-list ml-4" },
                              _vm._l(_vm.weekDays, function (weekDay, i) {
                                return _c("li", { key: i }, [
                                  _vm.reminderDays.includes(weekDay.name)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "White--text font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(weekDay.label) + " "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "secondary--text" },
                                        [_vm._v(_vm._s(weekDay.label))]
                                      ),
                                ])
                              }),
                              0
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-content",
            {
              class:
                _vm.stepIndex > 1 ? "ml-5 pl-3 pr-12 pt-12" : "ml-5 pl-3 pr-12",
              attrs: { step: "1" },
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "mb-5 text-center col-md-6 offset-md-3",
                  attrs: { color: "primaryDark", flat: "" },
                },
                [
                  _c("v-card-text", { staticClass: "subtitle-1 white--text" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("fig.medication.timeDescription")) +
                        " "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column align-center" },
                    [
                      _c(
                        "v-dialog",
                        {
                          ref: "dialog",
                          attrs: {
                            "return-value": _vm.reminderTime,
                            persistent: "",
                            width: "290px",
                          },
                          on: {
                            "update:returnValue": function ($event) {
                              _vm.reminderTime = $event
                            },
                            "update:return-value": function ($event) {
                              _vm.reminderTime = $event
                            },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mgl-time-select",
                                          attrs: {
                                            placeholder: "--:--",
                                            readonly: "",
                                            color: "accent",
                                            dense: "",
                                            outlined: "",
                                            rounded: "",
                                            "hide-details": "",
                                          },
                                          model: {
                                            value: _vm.reminderTime,
                                            callback: function ($$v) {
                                              _vm.reminderTime = $$v
                                            },
                                            expression: "reminderTime",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.timeModal,
                            callback: function ($$v) {
                              _vm.timeModal = $$v
                            },
                            expression: "timeModal",
                          },
                        },
                        [
                          _vm.timeModal
                            ? _c(
                                "v-time-picker",
                                {
                                  staticClass: "mgl-time-picker",
                                  attrs: {
                                    color: "secondary",
                                    "allowed-minutes": _vm.allowedMinutes,
                                    format: "24hr",
                                    flat: "",
                                    "full-width": "",
                                  },
                                  model: {
                                    value: _vm.reminderTime,
                                    callback: function ($$v) {
                                      _vm.reminderTime = $$v
                                    },
                                    expression: "reminderTime",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "initial text-decoration-underline",
                                      attrs: { text: "", large: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.timeModal = !_vm.timeModal
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("generic.cancel")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "px-5 mx-2",
                                      attrs: {
                                        color: "secondary",
                                        rounded: "",
                                        depressed: "",
                                        large: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.dialog.save(
                                            _vm.reminderTime
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("generic.done")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pb-0 body-1 white--text" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("fig.medication.reminderWindow")) +
                          " "
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: { persistent: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mgl-reminder-btn",
                                          attrs: { icon: "" },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass: "pa-1",
                                          attrs: {
                                            "x-small": "",
                                            color: "secondary",
                                          },
                                        },
                                        [_vm._v("?")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.dialog,
                            callback: function ($$v) {
                              _vm.dialog = $$v
                            },
                            expression: "dialog",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "pt-5",
                              attrs: { color: "primary" },
                            },
                            [
                              _c("h3", { staticClass: "text-center mb-5" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("fig.medication.reminders")) +
                                    " "
                                ),
                              ]),
                              _c(
                                "v-card-text",
                                { staticClass: "body-2 white--text" },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "fig.medication.reminderWindowHelpHtmlText"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "v-card-actions",
                                { staticClass: "d-flex justify-center pb-5" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "px-15",
                                      attrs: {
                                        rounded: "",
                                        color: "secondary",
                                        large: "",
                                        depressed: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.dialog = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("generic.gotIt")) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-slider", {
                    staticClass: "mx-5 mt-6 mb-3 mgl-range",
                    attrs: {
                      min: "10",
                      max: "60",
                      step: "5",
                      "thumb-label": "always",
                      color: "secondary",
                      "hide-details": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "thumb-label",
                        fn: function (ref) {
                          var value = ref.value
                          return [
                            _c(
                              "div",
                              { staticClass: "mgl-thumb-label text-center" },
                              [_vm._v(_vm._s(value) + " min")]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.reminderWindow,
                      callback: function ($$v) {
                        _vm.reminderWindow = $$v
                      },
                      expression: "reminderWindow",
                    },
                  }),
                  _c("v-card-text", { staticClass: "subtitle-1 white--text" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("fig.medication.dayDescription")) +
                        " "
                    ),
                  ]),
                  _c("v-select", {
                    staticClass: "mx-5",
                    attrs: {
                      items: _vm.dayOptions,
                      placeholder: _vm.$t("fig.medication.selectDays"),
                      dense: "",
                      outlined: "",
                      rounded: "",
                      color: "accent",
                      "item-color": "accent",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.dayOption,
                      callback: function ($$v) {
                        _vm.dayOption = $$v
                      },
                      expression: "dayOption",
                    },
                  }),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _vm.dayOption === "choose"
                            ? _c(
                                "v-col",
                                { staticClass: "px-0" },
                                [
                                  _c(
                                    "v-chip-group",
                                    {
                                      staticClass: "px-2 mgl-reminder-chips",
                                      attrs: { multiple: "" },
                                      model: {
                                        value: _vm.reminderDays,
                                        callback: function ($$v) {
                                          _vm.reminderDays = $$v
                                        },
                                        expression: "reminderDays",
                                      },
                                    },
                                    _vm._l(_vm.weekDays, function (day) {
                                      return _c(
                                        "v-chip",
                                        {
                                          key: day.name,
                                          staticClass: "ma-0",
                                          attrs: {
                                            value: day.name,
                                            "active-class": "secondary",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(day.label) + " ")]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "row",
                    "justify-content": "space-around",
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: {
                        width: "400px",
                        margin: "auto",
                        "min-width": "280px",
                      },
                      attrs: {
                        color: "secondary",
                        rounded: "",
                        depressed: "",
                        large: "",
                        disabled:
                          _vm.reminderDays.length === 0 ||
                          _vm.reminderTime == "" ||
                          _vm.reminderTimeInValid,
                      },
                      on: {
                        click: function ($event) {
                          _vm.stepIndex = 2
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("generic.next")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              staticClass: "px-2 t-12",
              attrs: {
                complete: _vm.stepIndex > 2,
                step: "2",
                color: "secondary",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("fig.medication.addMedication")) + " ")]
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "290" },
              model: {
                value: _vm.dialogConfirm,
                callback: function ($$v) {
                  _vm.dialogConfirm = $$v
                },
                expression: "dialogConfirm",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("fig.medication.removeMedication")) +
                        " "
                    ),
                  ]),
                  _c("v-card-text", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("fig.medication.removeMedicationConfirmation")
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "pa-5" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "secondary",
                            rounded: "",
                            depressed: "",
                            dense: "",
                          },
                          on: { click: _vm.removeMedication },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("generic.yes")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "grey darken-3",
                            rounded: "",
                            depressed: "",
                            dense: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.dialogConfirm = false
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("generic.no")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-content",
            { staticClass: "ml-5 pl-3 pt-0 pr-12", attrs: { step: "2" } },
            [
              _c(
                "v-form",
                { ref: "medicationsForm" },
                [
                  _c(
                    "transition-group",
                    { attrs: { name: "mgl-medications-list", tag: "div" } },
                    _vm._l(_vm.medications, function (medication, i) {
                      return _c(
                        "v-card",
                        {
                          key: i + 1,
                          staticClass:
                            "pa-0 text-center mgl-medications-card col-md-6 offset-md-3",
                          attrs: {
                            color: "primaryDark",
                            flat: "",
                            rounded: "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "position-relative ma-3 rounded" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mgl-delete-medication",
                                  attrs: { icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirmRemove(i)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass: "px-0 py-3 mgl-delete-btn",
                                      attrs: {
                                        "x-small": "",
                                        color: "secondary",
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("mdi-close"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            staticClass: "pb-3",
                                            attrs: {
                                              placeholder: _vm.$t(
                                                "fig.medication.medicationPlaceholder"
                                              ),
                                              outlined: "",
                                              rounded: "",
                                              dense: "",
                                              "hide-details": "",
                                              color: "accent",
                                              "background-color": "primary",
                                              type: "text",
                                              rules: [
                                                _vm.rules.notEmpty,
                                                _vm.rules.maxChars45,
                                              ],
                                            },
                                            model: {
                                              value: medication.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  medication,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "medication.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "mgl-medication-selects d-flex justify-space-between",
                                      attrs: { "no-gutters": "" },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mgl-shaped-left",
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              placeholder: "0",
                                              color: "accent",
                                              type: "number",
                                              "background-color": "primary",
                                              "hide-details": "",
                                              rules: [
                                                _vm.rules.min1,
                                                _vm.rules.max99999,
                                                _vm.rules.notEmpty,
                                              ],
                                            },
                                            model: {
                                              value: medication.weight,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  medication,
                                                  "weight",
                                                  $$v
                                                )
                                              },
                                              expression: "medication.weight",
                                            },
                                          }),
                                          _c("v-select", {
                                            staticClass:
                                              "mgl-truncated mgl-shaped-right mr-1",
                                            attrs: {
                                              outlined: "",
                                              items: _vm.weightUnits,
                                              dense: "",
                                              color: "accent",
                                              "item-color": "accent",
                                              "background-color": "primary",
                                              "hide-details": "",
                                              value: medication.weightUnit,
                                            },
                                            model: {
                                              value: medication.weightUnit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  medication,
                                                  "weightUnit",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "medication.weightUnit",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "d-flex",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c("v-text-field", {
                                            class:
                                              medication.quantityUnit == ""
                                                ? "mgl-shaped-left ml-1 mgl-invisible"
                                                : "mgl-shaped-left ml-1",
                                            attrs: {
                                              outlined: "",
                                              dense: "",
                                              placeholder: "1",
                                              color: "accent",
                                              type: "number",
                                              "background-color": "primary",
                                              "hide-details": "",
                                              rules: [
                                                _vm.rules.max100,
                                                _vm.rules.min0,
                                                _vm.rules.notEmpty,
                                              ],
                                              disabled:
                                                medication.quantityUnit == "",
                                            },
                                            model: {
                                              value: medication.quantity,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  medication,
                                                  "quantity",
                                                  $$v
                                                )
                                              },
                                              expression: "medication.quantity",
                                            },
                                          }),
                                          _c("v-select", {
                                            staticClass:
                                              "mgl-truncated mgl-shaped-right",
                                            attrs: {
                                              outlined: "",
                                              items: _vm.quantityUnits,
                                              dense: "",
                                              color: "accent",
                                              "item-color": "accent",
                                              "background-color": "primary",
                                              "hide-details": "",
                                            },
                                            model: {
                                              value: medication.quantityUnit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  medication,
                                                  "quantityUnit",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "medication.quantityUnit",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.hasReachedMedicationLimit
                ? _c(
                    "v-card",
                    {
                      staticClass: "pa-2 mb-5 col-md-6 offset-md-3",
                      attrs: { color: "warning", flat: "", rounded: "" },
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-flex",
                            { staticClass: "pa-3 col-md-2" },
                            [
                              _c("v-img", {
                                staticClass: "mx-1",
                                staticStyle: { opacity: "0.5" },
                                attrs: {
                                  src: "/warningCentered.png",
                                  "max-height": "50",
                                  contain: "",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { staticClass: "pa-3 col-md-10" },
                            [
                              _c(
                                "v-card-text",
                                {
                                  staticClass: "text-center",
                                  staticStyle: { color: "#eee" },
                                },
                                [
                                  _c("h3", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "fig.medication.reachedMedicationLimit"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-card",
                    {
                      staticClass:
                        "pa-0 text-center mgl-medications-card col-md-6 offset-md-3",
                      attrs: { color: "primaryDark", flat: "", rounded: "" },
                    },
                    [
                      _c(
                        "v-container",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row",
                            "justify-content": "space-around",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "secondary mgl-fab",
                                      attrs: {
                                        rounded: "",
                                        depressed: "",
                                        fab: "",
                                        small: "",
                                        icon: "",
                                      },
                                      on: { click: _vm.addMedication },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-uppercase caption ma-0 pt-3 d-flex align-center justify-center",
                                    },
                                    [
                                      _vm.medications.length === 0
                                        ? [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "fig.medication.addAMedication"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        : [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "fig.medication.addAnotherMedication"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass: "ml-1",
                                          attrs: {
                                            outlined: "",
                                            small: "",
                                            color: "secondary",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "white--text" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.reminderTime) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c(
                "v-col",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "row",
                    "justify-content": "space-around",
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      staticStyle: {
                        width: "400px",
                        margin: "auto",
                        "min-width": "200px",
                      },
                      attrs: {
                        color: "secondary",
                        rounded: "",
                        large: "",
                        depressed: "",
                        disabled: _vm.medications.length === 0,
                      },
                      on: { click: _vm.saveMedications },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("fig.medication.saveDose")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "float-right initial text-decoration-underline",
                  attrs: { color: "red lighten-2", text: "" },
                  on: { click: _vm.confirmCancel },
                },
                [_vm._v(_vm._s(_vm.$t("generic.cancel")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }