var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideDrawer,
          expression: "hideDrawer",
        },
      ],
    },
    [
      _c(
        "v-app-bar",
        { attrs: { color: "primary", flat: "", tile: "", height: "80" } },
        [
          _c(
            "router-link",
            { attrs: { to: "/" } },
            [
              _c("v-img", {
                attrs: {
                  src: _vm.logoUrl,
                  "max-height": "60",
                  "max-width": "120",
                  contain: "",
                },
              }),
            ],
            1
          ),
          _c("v-spacer"),
          _vm.isLoggedIn
            ? _c("v-app-bar-nav-icon", {
                attrs: { "x-large": "" },
                on: { click: _vm.menuClick },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.isLoggedIn
        ? _c(
            "v-navigation-drawer",
            {
              staticClass: "mgl-navigation-drawer",
              attrs: { app: "", right: "", temporary: "", color: "primary" },
              model: {
                value: _vm.menu,
                callback: function ($$v) {
                  _vm.menu = $$v
                },
                expression: "menu",
              },
            },
            [
              _c(
                "v-list",
                [
                  _vm._l(_vm.items, function (item) {
                    return [
                      item.enabled
                        ? _c(
                            "v-list-item",
                            {
                              key: item.title,
                              staticClass: "d-flex justify-center",
                              on: { click: item.method },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "font-weight-light" },
                                        [_vm._v(_vm._s(item.title))]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  }),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "d-flex" },
                        [
                          _c("SimpleLocalePicker", {
                            staticClass: "justify-center",
                            attrs: { menu: _vm.menu },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm.version
                ? _c(
                    "div",
                    {
                      staticClass:
                        "mx-5 mb-2 overline mgl-version grey--text text--darken-1",
                    },
                    [_vm._v(" Version: " + _vm._s(_vm.version) + " ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }