var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-5",
                      attrs: {
                        text: "",
                        rounded: "",
                        color: "primary",
                        flat: "",
                      },
                    },
                    [
                      _c("v-card-title", [
                        _vm._v(" " + _vm._s(_vm.$t("404.header")) + " "),
                      ]),
                      _c("v-card-text", [
                        _vm._v(" " + _vm._s(_vm.$t("404.text")) + " "),
                      ]),
                      _c(
                        "v-card-actions",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                          },
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text-center initial truncated",
                                  attrs: {
                                    "x-large": "",
                                    depressed: "",
                                    block: "",
                                    rounded: "",
                                    color: "secondary",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("404.back")))]
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }