<template>
  <v-card v-click-outside="hideDrawer">
    <v-app-bar color="primary" flat tile height="80">
      <router-link to="/">
        <v-img :src="logoUrl" max-height="60" max-width="120" contain></v-img>
      </router-link>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon v-if="isLoggedIn" @click="menuClick" x-large></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer
      class="mgl-navigation-drawer"
      v-model="menu"
      app
      right
      temporary
      color="primary"
      v-if="isLoggedIn"
    >
      <v-list>
        <template v-for="item in items">
          <v-list-item
            :key="item.title"
            v-if="item.enabled"
            @click="item.method"
            class="d-flex justify-center"
          >
            <div>
              <v-list-item-content>
                <v-list-item-title class="font-weight-light">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </div>
          </v-list-item>
        </template>
        <v-list-item>
          <v-list-item-content class="d-flex">
            <SimpleLocalePicker v-bind:menu="menu" class="justify-center" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div v-if="version" class="mx-5 mb-2 overline mgl-version grey--text text--darken-1">
        Version: {{ version }}
      </div>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import SimpleLocalePicker from './SimpleLocalePicker.vue';
import signOut from '../utils/auth';
import { displayError } from '../utils/loading';
import { getDownloadPageUrl } from '../utils/licenses';
import { isFeatureEnabled } from '../utils/config';

export default {
  name: 'NavMenu',
  components: {
    SimpleLocalePicker,
  },
  data() {
    return {
      menu: false,
      logoUrl: process.env.VUE_APP_LOGO_FILENAME,
      phqEnabled: false,
      reportsEnabled: false,
    };
  },
  methods: {
    menuClick() {
      this.menu = true;
    },
    hideDrawer(e) {
      if (e.target.className === 'v-overlay__scrim') {
        this.$emit('hide-drawer', false);
      }
    },
    getRoutingMenuItem(titleTextId, enabled, name) {
      return {
        title: this.$i18n.t(titleTextId),
        enabled,
        method: () => {
          this.$router.push({ name });
          this.menu = false;
        },
      };
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.api.me !== null;
    },
    version() {
      return process.env.VUE_APP_VERSION;
    },
    hasLicense() {
      return this.isLoggedIn ? this.$store.getters['api/hasValidLicense'] : false;
    },
    isPlaytient() {
      if (!this.isLoggedIn) {
        return false;
      }
      const { type } = this.$store.state.api.me;
      return type === 'Playtient' || type === 'MGL';
    },
    isOnboarded() {
      return this.$store.state.api.me.onboarded;
    },
    hasDoses() {
      return this.$store.state.api.doses.length > 0;
    },
    items() {
      return [
        this.getRoutingMenuItem('nav.account', true, 'account'),
        this.getRoutingMenuItem('nav.team', this.hasLicense || !this.isPlaytient, 'team'),
        this.getRoutingMenuItem(
          'nav.assessment',
          this.hasLicense && this.phqEnabled,
          'selfassessment',
        ),
        this.getRoutingMenuItem(
          'nav.medications',
          this.isPlaytient && this.hasLicense && this.$store.state.api.me.medicationRequired,
          'medications',
        ),
        this.getRoutingMenuItem('nav.reports', this.hasLicense && this.reportsEnabled, 'reports'),
        {
          title: this.$i18n.t('nav.logOut'),
          enabled: true,
          method: async () => {
            try {
              await signOut(this.$store, this.$router, true);
              this.menu = false;
            } catch (error) {
              displayError(this.$store, error);
            }
          },
        },
        {
          title: this.$i18n.t('nav.openGame'),
          enabled: this.isPlaytient && this.isOnboarded,
          method: () => {
            const downloadUrl = getDownloadPageUrl(this.$store, this.$i18n);
            window.open(downloadUrl, '_blank');
          },
        },
      ];
    },
  },
  mounted() {
    this.logoUrl = this.isPlaytient
      ? process.env.VUE_APP_LOGO_FILENAME
      : process.env.VUE_APP_LOGO_TEAM;
  },
  async updated() {
    if (!this.menu || !this.isPlaytient) {
      return;
    }
    this.phqEnabled = await isFeatureEnabled(this.$store, 'PHQ');
    this.reportsEnabled = await isFeatureEnabled(this.$store, 'REPORT');
  },
};
</script>

<style lang="scss" scoped>
.mgl-version {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
