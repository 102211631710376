var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      staticClass: "mgl-snackbar",
      attrs: {
        color: _vm.color,
        timeout: _vm.timeout,
        fixed: "",
        top: "",
        tile: "",
        app: "",
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("p", {
        staticClass: "text-center ma-0",
        domProps: { innerHTML: _vm._s(_vm.message) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }