/* eslint no-param-reassign: 0 */
import { getUserProgress } from '../../api/users';
import { apiGetSensors, apiGetAllDoseConfigs } from '../../api/storage';
import { getAllSensorData } from '../../api/sensors';
import { hasSaveFile, getUser } from '../../api/me';
import { readSavefileBasicRoutineData, readTimeZone } from '../../utils/savefiles';
import {
  addDaysToDateTime,
  dateStrInTimeZone,
  datetimeForDateStrInTimeZone,
  getUTCTimeStamp,
} from '../../utils/datetime';

import { hasDefinedProperty } from '../../utils/objects';
import { getRoutineSensorInfo, getSensorName, getSensorTypeId } from './helpers/routineConfig';
import { getRoutineEntry, getRoutineInfo } from './helpers/routines';
import {
  findSensorInSensorTypeMap,
  getSensorValuesOnDay,
  sensorValid,
  stripSensorEntry,
  getSensorConfigForDate,
  insertSensorDataForConfiguredSensor,
  insertConfiguredSensorEntry,
} from './helpers/sensors';
import {
  getDateStringForSensorData,
  shouldOverwriteExistingData,
  stripSensorDataEntry,
} from './helpers/sensordata';
import { SENSOR_TYPE_ADHERENCE } from '../../utils/constants';

/**
 * @typedef {import('./helpers/routines').RoutineEntry & {sensors: {name: string, typeId: string}[]}} RoutineSetup
 * @typedef {{playStart: string|null, routines: import('./helpers/routines').RoutineInfo[], hasIntialized: boolean, timeZone: string}} ProgressState
 */

const timezoneDebug = false;

/**
 * Getter for default state
 * @returns {ProgressState}
 */
const getDefaultState = () => {
  return {
    playStart: null,
    routines: [],
    hasIntialized: false,
    timeZone: null,
  };
};

/**
 * Finds routine using sensor type id
 * @param {ProgressState} state
 * @param {string} sensorTypeId
 * @returns {import('./helpers/routines').RoutineInfo}
 */
function findRoutine(state, sensorTypeId) {
  const sensorInfo = getRoutineSensorInfo(sensorTypeId);
  if (!sensorInfo) {
    return undefined;
  }
  return findRoutineByName(state, sensorInfo.routine);
}

/**
 * Finds routine by name
 * @param {ProgressState} state
 * @param {string} routineName
 * @returns {import('./helpers/routines').RoutineInfo}
 */
function findRoutineByName(state, routineName) {
  for (const routine of state.routines) {
    if (routine.name === routineName) {
      return routine;
    }
  }
  return undefined;
}

/**
 * Finds sensor for data
 * @param {ProgressState} state
 * @param {import('./helpers/sensordata').SensorDataEntry} sensorData
 * @returns {import('./helpers/sensors').Sensor}
 */
function findSensor(state, sensorData) {
  const { sensorId } = sensorData;
  if (!sensorId) {
    console.warn(`A sensor data entry has no sensor id!`);
    return undefined;
  }
  for (const routine of state.routines) {
    for (const sensorKeyType in routine.types) {
      if (hasDefinedProperty(routine.types, sensorKeyType)) {
        const sensor = findSensorInSensorTypeMap(routine.types[sensorKeyType].sensors, sensorId);
        if (sensor) {
          return sensor;
        }
      }
    }
  }
  console.warn(`Could not find sensor with id ${sensorData.sensorId}`);
  return undefined;
}

/**
 * Gets start date for week index
 * @param {ProgressState} state
 * @param {number} weekIndex
 * @returns {import("luxon").DateTime}
 */
function getStartDateForWeekIndex(state, weekIndex) {
  const { playStart, timeZone } = state;
  const playStartDate = datetimeForDateStrInTimeZone(playStart, timeZone, timezoneDebug);
  if (timezoneDebug) {
    console.log(`Got play start date ${playStartDate} with epoch ${playStartDate.toSeconds()}`);
  }
  return addDaysToDateTime(playStartDate, weekIndex * 7);
}

/**
 * Inserts sensor data entry for schedule configured sensor
 * @param {string} userTimezone
 * @param {import('./helpers/routines').RoutineInfo} routine
 * @param {string} dateStr
 * @param {import('./helpers/sensordata').SensorData} sensorData
 * @param {string} sensorTypeId
 */
function addSensorDataForConfiguredSensor(
  userTimezone,
  routine,
  dateStr,
  sensorData,
  sensorTypeId,
) {
  const sensorConfigs = routine.types[sensorTypeId].configs;
  const startDateStr = routine.unlockedAt;
  const configs = getSensorConfigForDate(userTimezone, sensorConfigs, dateStr, startDateStr);
  if (!configs) {
    console.warn(
      `Found no sensor config at date ${dateStr} for sensor of type: ${sensorTypeId}. Using start date ${startDateStr}`,
    );
    return;
  }
  const sensorDataMap = routine.types[sensorTypeId].data;
  insertSensorDataForConfiguredSensor(sensorDataMap, configs, dateStr, sensorData, sensorTypeId);
}

/**
 * Inserts configured sensor for routine
 * @param {import('./helpers/routines').RoutineInfo} routine
 * @param {string} dateStr
 * @param {import('./helpers/sensors').Sensor} sensor
 */
function insertConfiguredSensorEntryForRoutine(routine, sensor, dateStr) {
  const { sensorTypeId } = sensor;
  insertConfiguredSensorEntry(routine.types[sensorTypeId], sensor, dateStr);
}

/**
 * Inserts configured sensor for routine on previous days, if it is configured for theese days also
 * @param {string} dateStr date string
 * @param {import('./helpers/routines').RoutineInfo} routine routine info
 * @param {import('./helpers/sensors').Sensor} sensor sensor
 * @param {string} userTimezone user time zone
 */
function insertConfiguredSensorEntryOnLaterDays(dateStr, routine, sensor, userTimezone) {
  const { sensorTypeId } = sensor;
  const { configs, endConfigDate } = routine.types[sensorTypeId];
  if (!endConfigDate) {
    console.warn(
      `End config date not found for routine ${routine.name}. Skipping sensor setup on later days for sensor ${sensor.id}`,
    );
    return;
  }
  let currentDate = addDaysToDateTime(datetimeForDateStrInTimeZone(dateStr, userTimezone), 1);
  const endDate = datetimeForDateStrInTimeZone(endConfigDate, userTimezone);
  while (currentDate <= endDate) {
    const currentDateStr = dateStrInTimeZone(currentDate.toISO(), userTimezone);
    if (hasDefinedProperty(configs, currentDateStr)) {
      const config = configs[currentDateStr];
      for (const configSensor of config.sensors) {
        if (sensor.id === configSensor.id || sensor.anonymousTemplateId === configSensor.id) {
          insertConfiguredSensorEntryForRoutine(routine, sensor, currentDateStr);
        }
      }
    }
    currentDate = addDaysToDateTime(currentDate, 1);
  }
}

export default {
  namespaced: true,
  name: 'progress',
  state: getDefaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    setUserProgress(state, payload) {
      state.hasIntialized = true;
      const { tz: timeZone, progress } = payload;
      // Parse user progress and read routines and playstart!
      if (progress.length) {
        const saveFileEntry = progress[0];
        const basicRoutineData = readSavefileBasicRoutineData(saveFileEntry, timeZone);
        state.timeZone = timeZone;
        if (basicRoutineData) {
          const { playStart, inventory } = basicRoutineData;
          let { stepGoal, sleepBedTime } = basicRoutineData.global;
          if (stepGoal === undefined) {
            stepGoal = 5000;
          }
          if (sleepBedTime === undefined) {
            sleepBedTime = '22:00';
          }
          const routines = [];
          if (playStart) {
            state.playStart = playStart;
            routines.push(getRoutineInfo('wellbeing', playStart));
            routines.push(getRoutineInfo('medication', playStart));
            const { foodUnlockDate, sleepUnlockDate, exerciseUnlockDate } = inventory;
            routines.push(getRoutineInfo('food', foodUnlockDate));
            routines.push(getRoutineInfo('sleep', sleepUnlockDate, { sleepBedTime }));
            routines.push(getRoutineInfo('exercise', exerciseUnlockDate, { stepGoal }));
          }
          state.routines = routines;
        }
      }
    },
    assignSensorConfigsToRoutines(state, configData) {
      const { configs, typeId, entryKey, startDateTime } = configData;
      const routine = findRoutine(state, typeId);
      if (!routine) {
        console.error('Could not find routine for sensorType: ', typeId);
        return;
      }
      const userTimezone = state.timeZone;
      if (!hasDefinedProperty(routine.types, typeId)) {
        // @ts-ignore
        routine.types[typeId] = { sensors: {}, data: {}, configs: {}, endConfigDate: undefined };
      }
      const sensorMap = routine.types[typeId];
      let lastConfigDate;
      for (const config of configs) {
        if (hasDefinedProperty(config, entryKey)) {
          const sensorArr = config[entryKey];
          const { createdAt } = config;
          const configSensors = [];
          for (const sensor of sensorArr) {
            const { sensorId, schedule } = sensor;
            configSensors.push({ id: sensorId, schedule });
          }
          const configObj = { createdAt, sensors: configSensors };
          let dateStr = dateStrInTimeZone(createdAt, userTimezone);
          let configDate = datetimeForDateStrInTimeZone(dateStr, userTimezone);
          if (configDate < startDateTime) {
            console.log(`Config date ${dateStr} is before start date, using start date instead`);
            dateStr = dateStrInTimeZone(startDateTime.toISO(), userTimezone);
            configDate = startDateTime;
          }
          if (hasDefinedProperty(sensorMap.configs, dateStr)) {
            const prevConfig = sensorMap.configs[dateStr];
            if (prevConfig.createdAt < createdAt) {
              sensorMap.configs[dateStr] = configObj;
            }
          } else {
            sensorMap.configs[dateStr] = configObj;
          }
          if (!lastConfigDate || configDate > lastConfigDate) {
            lastConfigDate = configDate;
          }
        }
      }
      if (lastConfigDate) {
        sensorMap.endConfigDate = dateStrInTimeZone(lastConfigDate.toISO(), userTimezone);
      }
    },
    assignSensorsToRoutines(state, sensors) {
      const userTimezone = state.timeZone;
      for (const sensorEntry of sensors) {
        const sensor = stripSensorEntry(sensorEntry);
        const { sensorTypeId, createdAt, eventEpoch } = sensor;
        if (sensorTypeId && createdAt) {
          const routine = findRoutine(state, sensorTypeId);
          if (routine) {
            if (!hasDefinedProperty(routine.types, sensorTypeId)) {
              // @ts-ignore
              routine.types[sensorTypeId] = { sensors: {}, data: {} };
            }
            const utcDatetimeStr = getUTCTimeStamp(eventEpoch);
            const dateStr = dateStrInTimeZone(utcDatetimeStr, userTimezone);
            if (routine.types[sensorTypeId].configs) {
              // sensor has configurations (E.g. Medical)
              insertConfiguredSensorEntryForRoutine(routine, sensor, dateStr);
              // Also insert sensor for all other later dates it is configured for
              insertConfiguredSensorEntryOnLaterDays(dateStr, routine, sensor, userTimezone);
            } else if (sensorValid(routine.types[sensorTypeId].sensors, createdAt, dateStr)) {
              routine.types[sensorTypeId].sensors[dateStr] = [sensor];
            } else {
              console.warn(`Filtered invalid sensor: `, sensor);
            }
          }
        }
      }
    },
    setSensorData(state, sensorDatas) {
      const userTimeZone = state.timeZone;
      for (const sensorDataEntry of sensorDatas) {
        const sensor = findSensor(state, sensorDataEntry);
        if (sensor) {
          const { sensorTypeId } = sensor;
          const sensorData = stripSensorDataEntry(sensorDataEntry, sensorTypeId);
          const dateStr = getDateStringForSensorData(userTimeZone, sensorData);
          const routine = findRoutine(state, sensorTypeId);
          const sensorConfigs = routine.types[sensorTypeId].configs;
          if (!hasDefinedProperty(routine.types[sensorTypeId].data, dateStr)) {
            if (sensorConfigs) {
              addSensorDataForConfiguredSensor(
                userTimeZone,
                routine,
                dateStr,
                sensorData,
                sensorTypeId,
              );
            } else {
              routine.types[sensorTypeId].data[dateStr] = [sensorData];
            }
          } else if (sensorConfigs) {
            addSensorDataForConfiguredSensor(
              userTimeZone,
              routine,
              dateStr,
              sensorData,
              sensorTypeId,
            );
          } else {
            const prevEntryArr = routine.types[sensorTypeId].data[dateStr];
            if (shouldOverwriteExistingData(sensorTypeId, sensorData, prevEntryArr[0])) {
              routine.types[sensorTypeId].data[dateStr] = [sensorData];
            }
          }
        }
      }
    },
  },
  actions: {
    async initialize({ commit }, { store, router, data }) {
      const { viewId } = data;
      // 0. Check that we have a savefile first:
      if (!hasSaveFile(store)) {
        console.log('User has no savefile, setting progress to zero');
        commit('setUserProgress', []);
        return;
      }

      // 1 Check that we have a play start date:
      const { playstart: playStart } = getUser(store);
      if (!playStart) {
        console.log('User has no play start date, setting progress to zero');
        commit('setUserProgress', []);
        return;
      }

      // 2. Get progress data (This is needed to get the user's time zone and play start date)
      const userProgress = await getUserProgress(store, router, viewId);
      const timeZone = readTimeZone(userProgress);
      const basicRoutineData = readSavefileBasicRoutineData(userProgress[0], timeZone);
      let startDateTime;
      if (basicRoutineData.playStart) {
        startDateTime = datetimeForDateStrInTimeZone(basicRoutineData.playStart, timeZone);
      } else {
        startDateTime = datetimeForDateStrInTimeZone(playStart, timeZone);
      }
      console.log(`Using start date ${startDateTime.toISODate()} and time zone ${timeZone}`);

      // 3. Get dose configs, sensors, and sensor data
      const [doseConfigs, sensors, sensorData] = await Promise.all([
        apiGetAllDoseConfigs(store, router, viewId),
        apiGetSensors(store, router, viewId, false),
        getAllSensorData(store, router, viewId, startDateTime.toJSDate()),
      ]);

      // 4. Commit all data:
      commit('setUserProgress', { progress: userProgress, tz: timeZone });
      commit('assignSensorConfigsToRoutines', {
        configs: doseConfigs,
        typeId: SENSOR_TYPE_ADHERENCE,
        entryKey: 'doses',
        startDateTime,
      });
      commit('assignSensorsToRoutines', sensors);
      commit('setSensorData', sensorData);
    },
  },
  getters: {
    /**
     * Get Play start date
     * @param {ProgressState} state
     * @returns {string|null}
     */
    playStart: (state) => {
      return state.playStart;
    },
    /**
     * Get User time zone
     * @param {ProgressState} state
     * @returns {string|null}
     */
    timeZone: (state) => {
      return state.timeZone;
    },
    /**
     * Get If progress has been initialized
     * @param {ProgressState} state
     * @returns {boolean}
     */
    hasIntializedProgress: (state) => {
      return state.hasIntialized;
    },
    /**
     * Get the routine setup
     * @param {ProgressState} state
     * @returns {RoutineSetup[]}
     */
    getRoutineSetup: (state) => {
      const routineArr = [];
      for (const routine of state.routines) {
        const { unlocked } = routine;
        if (unlocked) {
          const routineEntry = getRoutineEntry(routine);
          const sensors = [];
          for (const typeKey in routine.types) {
            if (hasDefinedProperty(routine.types, typeKey)) {
              sensors.push({
                name: getSensorName(typeKey),
                typeId: typeKey,
              });
            } else {
              console.warn('Undefined type: ', typeKey);
            }
          }
          routineArr.push({ ...routineEntry, sensors });
        }
      }
      return routineArr;
    },
    /**
     * Get all sensor data for given week
     * @param {ProgressState} state
     * @returns {(sensorName: string, weekIndex: number) => import('./helpers/sensordata').OptionalSensorData[][]|undefined}
     */
    getSensorDataForWeek: (state) => (sensorName, weekIndex) => {
      const sensorTypeId = getSensorTypeId(sensorName);
      if (!sensorTypeId) {
        console.warn(`Found no sensor with name: ${sensorName}`);
        return undefined;
      }
      const routine = findRoutine(state, sensorTypeId);
      if (!routine) {
        console.warn(`Found no routine for sensor type: ${sensorTypeId}`);
        return undefined;
      }
      const { types } = routine;
      if (!hasDefinedProperty(types, sensorTypeId)) {
        console.warn(`Found no type definition for sensor type: ${sensorTypeId}`);
        return undefined;
      }
      const startDate = getStartDateForWeekIndex(state, weekIndex);
      let currentDate = startDate;
      const dataArr = [];
      const userTimezone = state.timeZone;
      const playStartDateStr = state.playStart;
      for (let i = 0; i < 7; i += 1) {
        const dateStr = dateStrInTimeZone(currentDate.toISO(), userTimezone, timezoneDebug);
        if (timezoneDebug) {
          console.log(
            `${sensorName}, ${i}: Using date string: ${dateStr} for date: ${currentDate}`,
          );
        }
        const valueArr = getSensorValuesOnDay(
          types[sensorTypeId],
          sensorTypeId,
          dateStr,
          playStartDateStr,
          userTimezone,
        );
        dataArr.push(valueArr);
        currentDate = addDaysToDateTime(currentDate, 1);
      }
      return dataArr;
    },
  },
};
