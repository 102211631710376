/* eslint no-param-reassign: 0 */
import { getAnonymousAuth, clearSessionKeys } from '../../utils/anonymousAuth';
import {
  getAxiosGetPromise,
  getAxiosPostPromise,
  getAxiosPutPromise,
} from '../../utils/requestHandler';
import { CATEGORY_ADHERENCE, SENSOR_TYPE_ADHERENCE } from '../../utils/constants';
import { anonymousApiDispatch } from '../../utils/dispatch';

/**
 * @typedef {{birthYear: number, diagnosis: string, diagnosisDate: null|string, gender: string, groupIds: string,
 * id: string, sensorTemplateGroupId: string, type: string}} AnonymousInfo
 * @typedef {{anonymousInfo: AnonymousInfo|null, medications: object[], sensors: object[], weeklyReports: object[]}} AnonymousState
 * @typedef {{commit: (string, any)=>void, state: AnonymousState}} DispatchArg1
 * @typedef {{store: import("vuex").Store, router: import("vue-router").default, data?: object}} DispatchArg2
 */

/**
 * Returns default state
 * @returns {AnonymousState}
 */
const getDefaultState = () => {
  return {
    anonymousInfo: null,
    medications: [],
    sensors: [],
    weeklyReports: [],
  };
};

/**
 * Adds medications
 * @param {import("vuex").Store} store
 * @param {import("vue-router").default} router
 * @param {?} doseRelations
 * @param {string} appId app id
 * @param {string} viewId view id
 * @returns {Promise<?>}
 */
const addMedications = async (store, router, doseRelations, appId, viewId) => {
  const promies = [];
  for (const relation of doseRelations) {
    const { medication } = relation;
    if (!medication.id) {
      promies.push(
        anonymousApiDispatch(store, router, 'postMedication', {
          appId,
          medication,
          viewId,
        }),
      );
    }
  }

  const newMedications = await Promise.all(promies);
  let resultsIndex = 0;
  for (const relation of doseRelations) {
    const { medication } = relation;
    if (!medication.id) {
      medication.id = newMedications[resultsIndex][0].id;
      resultsIndex += 1;
    }
  }

  return doseRelations.map((relation) => {
    return {
      amount: parseFloat(parseFloat(relation.amount).toFixed(2)) || 1,
      medicationId: relation.medication.id,
    };
  });
};

export default {
  namespaced: true,
  name: 'anonymousApi',
  state: getDefaultState(),
  actions: {
    /**
     * Post medication
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async postMedication(
      { commit },
      { store, router, data: payload }, // appId: string, // medication: Medication,
    ) {
      const userInfo = await getAnonymousAuth(store, router);
      const data = {
        ...payload.medication,
        appId: payload.appId,
        anonymousUserId: userInfo.userId,
      };
      return getAxiosPostPromise(
        store,
        'medications',
        userInfo.accessToken,
        data,
        (res) => {
          commit('setMedications', {
            payload,
          });
          return res.data;
        },
        null,
        (error) => {
          store.commit('invalid', true);
          return error;
        },
        null,
        payload.viewId,
      );
    },
    /**
     * Get medications
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async getMedications({ commit }, { store, router }) {
      const userInfo = await getAnonymousAuth(store, router);
      return getAxiosGetPromise(
        store,
        'medications',
        userInfo.accessToken,
        (res) => {
          commit('setMedications', res.data);
          return res.data;
        },
        null,
      );
    },
    /**
     * Delete sensor
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async deleteSensor({ commit }, { store, router, data: payload }) {
      const userInfo = await getAnonymousAuth(store, router);
      return getAxiosPostPromise(
        store,
        `sensors/delete/${payload.dose.sensorId}`,
        userInfo.accessToken,
        {
          appId: payload.appId,
          anonymousUserId: userInfo.userId,
        },
        (res) => {
          commit('setSensor', res.data);
          return res.data.id;
        },
        'almostDone',
        null,
        null,
        payload.viewId,
      );
    },
    /**
     * Add sensor
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async addSensor(
      { commit },
      { store, router, data: payload }, // anonymousToken, // anonymousUserId, // appId, // dose,
    ) {
      const { appId, dose, journalDay, viewId } = payload;
      const { schedule, durationInMinutes, prevSensorId } = dose;
      const relations = await addMedications(store, router, dose.relations, appId, viewId);

      const postUrl = prevSensorId ? `sensors/edit/${prevSensorId}` : 'sensors';
      const anonymousUser = await getAnonymousAuth(store, router);
      const data = {
        appId,
        anonymousUserId: anonymousUser.userId,
        categoryId: CATEGORY_ADHERENCE,
        schedule,
        durationInMinutes,
        sensorTypeId: SENSOR_TYPE_ADHERENCE,
        relations,
        groupId: anonymousUser.sensorTemplateGroupId,
        journalDay,
        platform: 'WEB',
      };
      return getAxiosPostPromise(
        store,
        postUrl,
        anonymousUser.accessToken,
        data,
        (res) => {
          commit('setSensor', res.data);
          return res.data.id;
        },
        'almostDone',
        null,
        null,
        viewId,
      );
    },
    /**
     * Create sensor group
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async createSensorGroup({ commit }, { store, router, data: payload }) {
      const anonymousInfo = await getAnonymousAuth(store, router);
      const { viewId } = payload;
      delete payload.viewId;
      return getAxiosPostPromise(
        store,
        'sensors/create-group',
        anonymousInfo.accessToken,
        payload,
        (res) => {
          const { data } = res;
          commit('setAnonymousUserInfo', { sensorTemplateGroupId: data.id });
          return data;
        },
        null,
        null,
        null,
        viewId,
      );
    },
    /**
     * Set user info
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async setUserInfo({ commit }, { store, router, data: payload }) {
      const anonymousInfo = await getAnonymousAuth(store, router);
      const { viewId } = payload;
      delete payload.viewId;
      return getAxiosPutPromise(
        store,
        `users/${anonymousInfo.userId}/anonymous-info`,
        anonymousInfo.accessToken,
        payload,
        (res) => {
          commit('setAnonymousUserInfo', payload);
          return res.data;
        },
        null,
        null,
        null,
        viewId,
      );
    },
    /**
     * Get user info
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async getUserInfo({ commit }, { store, router }) {
      const anonymousInfo = await getAnonymousAuth(store, router);
      return getAxiosGetPromise(
        store,
        `users/${anonymousInfo.userId}/anonymous-info`,
        anonymousInfo.accessToken,
        (res) => {
          const { data } = res;
          commit('setAnonymousUserInfo', data[0]);
          return data;
        },
      );
    },
    /**
     * Get weekly reports
     * NOTE: this method does not work currently
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async getWeeklyReports({ commit }, { store, router, data: payload }) {
      const anonymousInfo = await getAnonymousAuth(store, router);
      return getAxiosGetPromise(
        store,
        `reports/weeklyreports?appId=${payload.appId}&anonymousUserId=${anonymousInfo.userId}&playstart=${payload.playstart}`,
        anonymousInfo.accessToken,
        (res) => {
          commit('setWeeklyReports', res.data);
          return res.data;
        },
        null,
        null,
        payload.viewId,
      );
    },
    /**
     * Get weekly reports
     * NOTE: this method does not work currently
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async getWeeklyReport({ commit }, { store, router, data: payload }) {
      const anonymousInfo = await getAnonymousAuth(store, router);
      return getAxiosGetPromise(
        store,
        `reports/weeklyreports/${payload.startDate}?appId=${payload.appId}&anonymousUserId=${anonymousInfo.userId}`,
        anonymousInfo.accessToken,
        (res) => {
          commit('setWeeklyReport', res.data);
          return res.data;
        },
        null,
        null,
        payload.viewId,
      );
    },
    /**
     * Register anonymous user
     * @param {DispatchArg1} param0
     * @param {DispatchArg2} param1
     * @returns {Promise<?>}
     */
    async register({ commit }, { store, router, data: payload }) {
      const anonymousInfo = await getAnonymousAuth(store, router);
      return getAxiosPostPromise(
        store,
        `users/${anonymousInfo.userId}/anonymous-register`,
        anonymousInfo.accessToken,
        payload.userInfo,
        (res) => {
          commit('voidMutation');
          return res.data;
        },
        null,
        null,
        null,
        payload.viewId,
      );
    },
  },
  mutations: {
    voidMutation() {
      // For actions that does not affect the state
    },
    resetState(state) {
      clearSessionKeys();
      // Merge rather than replace so we don't lose observers
      Object.assign(state, getDefaultState());
    },
    setMedications(state, payload) {
      state.medications = payload;
    },
    setSensor(state, payload) {
      // todo: handle multiple sensors
      state.sensors = [payload];
    },
    setAnonymousUserInfo(state, payload) {
      state.anonymousInfo = { ...state.anonymousInfo, ...payload };
    },
    setWeeklyReports(state, payload) {
      state.weeklyReports = payload;
    },
  },
  getters: {
    anonymousInfo: (state) => {
      return state.anonymousInfo;
    },
    medications: (state) => {
      return state.medications;
    },
    sensors: (state) => {
      return state.sensors;
    },
  },
};
