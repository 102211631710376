<template>
  <v-container>
    <v-card
      style="max-width: 500px; margin: auto"
      class="primary pt-8 pb-4 px-5"
      elevation="0"
      rounded="xl"
    >
      <h2 class="ma-0 text-center">
        {{ $t('reports.sleep') }}
      </h2>

      <p class="pa-5 text-center subtitle" v-html="sleepDescription" />
      <!-- Graph -->
      <section class="graph" ref="graph">
        <div class="yaxis">
          <p ref="blocks" v-for="(s, i) in yAxis" :key="`ya${i}`">{{ s }}</p>
        </div>
        <div class="content">
          <div class="bedtime-goal" :style="{ top: `${goalHeight()}px` }">
            <p>bedtime goal ({{ this.sleepBedTime }})</p>
          </div>
          <div class="bars">
            <div
              v-for="(b, i) in this.reportData"
              :key="`b${i}`"
              :style="{
                height: `${minutesToPixels(b.length)}px`,
                transform: `translateY(${minutesToPixels(minutesFromTime(b.startTime), true)}px)`,
              }"
            >
              <v-tooltip zIndex="99" color="secondary" top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <p class="bar-indicator top">{{ b.startTime }}</p>
                    <p class="bar-indicator bottom">{{ b.endTime }}</p>
                  </div>
                </template>
                <span>{{ b.lengthStr }}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="xaxis">
            <div v-for="(d, i) in this.selectedReport.xAxis" :key="`d${i}`">
              <p>
                {{ d.day }} <br />
                {{ d.date }}
              </p>
            </div>
          </div>
        </div>
      </section>
    </v-card>
  </v-container>
</template>
<script>
import { DateTime } from 'luxon';
import { getWeeklySensorData } from '../../progress/routines';
import { shortTimeStrFromTimestampStrInTimeZone } from '../../utils/datetime';

export default {
  name: 'ReportSleep',
  props: {
    selectedReport: Object,
  },
  data() {
    return {
      viewId: '018',
      yAxis: [],
      reportData: [],
      loaded: false,
      sleepBedTime: '',
      sleepBedTimeHour: 0,
      bedTimeThresholdHour: 19,
      earliestBedTime: '',
      latestBedTime: '',
      sleepBedTimeOffset: 5,
      averageSleepMinutes: 0,
      blockHeight: 0,
      minuteHeight: 0,
    };
  },
  computed: {
    sleepDescription() {
      if (this.loaded === false) {
        return this.$i18n.t('reports.loading');
      }
      if (this.averageSleepMinutes === null) {
        return this.$i18n.t('reports.noData');
      }
      return this.$i18n.t('reports.sleepResultHtml', {
        averageSleepTime: `${this.lengthToStr(this.averageSleepMinutes)}`,
        sleepWindow: `${this.earliestBedTime} - ${this.latestBedTime}`,
      });
    },
  },
  methods: {
    calculateChart() {
      /* Bed time threshold is the earliest time a person can go to bed
         it is considered point 0 (base) for all calculations based on the hours
         It's currently defined as 5 hours before bedTimeGoal
         ex: if bedTimeGoal is 23:00, bedTimeThreshold is 18
         so going to bed at 18 is earlier then going to bed at 1
         but later to bed at 17 is later then going to bed at 1 */
      const bedTimeThresholdStr = DateTime.fromISO(this.sleepBedTime)
        .minus({ hours: this.sleepBedTimeOffset })
        .toFormat('HH:mm');
      this.bedTimeThresholdHour = this.timeToHour(bedTimeThresholdStr);
      this.averageSleepMinutes = this.calculateAverageSleepMinutes();
      if (this.averageSleepMinutes === null) {
        const maxYStr = DateTime.fromISO(this.sleepBedTime)
          .plus({ hours: this.sleepBedTimeOffset })
          .toFormat('HH:mm');
        this.calculateYAxis(this.bedTimeThresholdHour, this.timeToHour(maxYStr));
        return;
      }
      const earliestBedTimeHour = this.calculateBedTimeData();
      const latestWakeUpHour = this.calculateWakeUpTimeData();
      this.calculateYAxis(earliestBedTimeHour, latestWakeUpHour);
    },
    calculateBedTimeData() {
      const bedTimeData = this.reportData
        .filter((value) => value.startTime != null)
        .map((value) => {
          return {
            basedHour: this.hourToMinSleepBedTimeBase(this.timeToHour(value.startTime)),
            time: value.startTime,
          };
        });

      let earliestSleep = bedTimeData[0];
      let latestSleep = bedTimeData[0];
      for (let i = 0; i < bedTimeData.length; i += 1) {
        const data = bedTimeData[i];

        if (
          data.basedHour < earliestSleep.basedHour ||
          (data.basedHour === earliestSleep.basedHour && data.time < earliestSleep.time)
        ) {
          earliestSleep = data;
        }

        if (
          data.basedHour > latestSleep.basedHour ||
          (data.basedHour === latestSleep.basedHour && data.time > latestSleep.time)
        ) {
          latestSleep = data;
        }
      }
      this.earliestBedTime = earliestSleep.time;
      this.latestBedTime = latestSleep.time;

      const bedTimeHours = bedTimeData.map((value) => value.basedHour);
      bedTimeHours.push(this.hourToMinSleepBedTimeBase(this.sleepBedTimeHour));
      return this.minSleepBedTimeBaseToHour(Math.min(...bedTimeHours));
    },
    calculateWakeUpTimeData() {
      const wakeUpHours = this.reportData
        .filter((value) => value.startTime != null)
        .map((value) => {
          return this.hourToMinSleepBedTimeBase(this.timeToHour(value.endTime, true));
        });
      return this.minSleepBedTimeBaseToHour(Math.max(...wakeUpHours));
    },
    hourToMinSleepBedTimeBase(num) {
      return (24 + num - this.bedTimeThresholdHour) % 24;
    },
    minSleepBedTimeBaseToHour(num) {
      return (num + this.bedTimeThresholdHour) % 24;
    },
    calculateAverageSleepMinutes() {
      const onlyNumbers = this.reportData
        .map((value) => value.length)
        .filter((value) => typeof value === 'number');
      const sum = onlyNumbers.reduce((acc, val) => acc + val, 0);
      return onlyNumbers.length === 0 ? null : Math.round(sum / onlyNumbers.length);
    },
    calculateEndTime(startTime, length) {
      if (startTime == null) return null;
      let endTime = parseInt(startTime, 10);
      endTime += length * 60;
      return shortTimeStrFromTimestampStrInTimeZone(
        endTime.toString(),
        this.selectedReport.userTimezone,
      );
    },
    lengthToStr(minutes) {
      const h = Math.floor(minutes / 60);
      const m = minutes % 60;
      return `${h}h ${m}m`;
    },
    timeToHour(time, roundUp = false) {
      const [hours, minutes] = time.split(':');
      let result = parseInt(hours, 10);
      if (roundUp && parseInt(minutes, 10) > 0) {
        result += 1;
      }
      return result;
    },
    calculateYAxis(startHour, endHour) {
      const hourArray = [];
      for (let hour = startHour; hour !== endHour; hour = (hour + 1) % 24) {
        hourArray.push(`${hour.toString().padStart(2, '0')}:00`);
      }
      hourArray.push(`${endHour.toString().padStart(2, '0')}:00`);
      this.yAxis = hourArray;
    },
    minutesFromTime(time) {
      if (!this.loaded || time === null) {
        return 0;
      }
      const [hours, minutes] = time.split(':');
      const idx = this.yAxis.indexOf(`${hours}:00`);
      return idx * 60 + parseInt(minutes, 10);
    },
    minutesToPixels(minutes, addPadding = false) {
      if (!this.loaded || minutes === undefined) {
        return 0;
      }
      let result = minutes * this.minuteHeight;
      if (addPadding) {
        result += this.blockHeight / 2;
      }
      return result;
    },
    goalHeight() {
      if (!this.loaded) {
        return 0;
      }
      const minutes = this.minutesFromTime(this.sleepBedTime);
      return this.minutesToPixels(minutes) - this.blockHeight / 2;
    },
  },
  updated() {
    const graphHeight = this.$refs.graph.clientHeight;
    this.blockHeight = this.$refs.blocks[0].clientHeight;
    if (this.blockHeight === undefined) {
      return;
    }
    this.minuteHeight = (graphHeight - this.blockHeight * 2) / (this.yAxis.length * 60);
  },
  // eslint-disable-next-line max-lines-per-function
  async mounted() {
    console.log(`Mounted Sleep ${JSON.stringify(this.selectedReport)}`);
    const bedTimeAssessed = await getWeeklySensorData(
      this.$store,
      this.$router,
      this.viewId,
      `bedtime-epoch-assessed`,
      this.selectedReport.week,
    );
    const bedTimeMeasured = await getWeeklySensorData(
      this.$store,
      this.$router,
      this.viewId,
      `bedtime-epoch-measured`,
      this.selectedReport.week,
    );
    const sleepAssessed = await getWeeklySensorData(
      this.$store,
      this.$router,
      this.viewId,
      `sleep-duration-assessed`,
      this.selectedReport.week,
    );
    const sleepMeasured = await getWeeklySensorData(
      this.$store,
      this.$router,
      this.viewId,
      `sleep-duration-measured`,
      this.selectedReport.week,
    );
    const bedTime = bedTimeAssessed.map((obj, index) => {
      return [{ ...bedTimeMeasured[index][0], ...obj[0] }];
    });
    const sleep = sleepAssessed.map((obj, index) => {
      return [{ ...sleepMeasured[index][0], ...obj[0] }];
    });
    this.reportData = bedTime.map((obj, index) => ({
      startTime: shortTimeStrFromTimestampStrInTimeZone(
        obj[0].value,
        this.selectedReport.userTimezone,
      ),
      endTime: this.calculateEndTime(obj[0].value, sleep[index][0].value),
      length: sleep[index][0].value,
      lengthStr: this.lengthToStr(sleep[index][0].value),
    }));
    this.sleepBedTime = this.selectedReport.sleepBedTime;
    this.sleepBedTimeHour = this.timeToHour(this.sleepBedTime);
    this.calculateChart();
    this.loaded = true;
  },
};
</script>
<style lang="scss" scoped>
$datesPos: 65px;
.subtitle {
  font-size: larger;
}

.graph {
  position: relative;
  display: flex;
  padding-bottom: $datesPos;
}

.yaxis {
  display: flex;
  flex-direction: column;
  > p {
    flex: 1;
  }
  p:last-child {
    margin-bottom: 0 !important;
  }
}
.content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  .bars {
    position: relative;
    display: flex;
    flex: 1;
    > div {
      position: relative;
      width: 100%;
      max-width: 30px;
      margin: 0 auto;
      flex: 1;
      border-radius: 50px;
      height: 80px;
      transition: all 0.3s;
      background-color: #5db6bf;
      border-color: #5db6bf;
      > div {
        height: 100%;
      }
      &:hover {
        background: #85d8e0 !important;
      }
      &:hover .bar-indicator {
        display: block;
      }
      .bar-indicator {
        display: none;
        position: absolute;
        width: 85px;
        font-size: 14px;
        color: #a55dff;
        margin: 1px 0;
      }
      .bar-indicator.top {
        border-top: 3px solid #a55dff;
        top: -3px;
        right: -15px;
      }
      .bar-indicator.bottom {
        border-bottom: 3px solid #a55dff;
        bottom: -3px;
        right: -15px;
      }
      @media (max-width: 500px) {
        max-width: 20px;
      }
    }
  }
}
.xaxis {
  display: flex;
  text-align: center;
  position: absolute;
  bottom: -$datesPos;
  width: 100%;
  > div {
    position: relative;
    flex: 1;
  }
  > p {
    flex: 1;
    margin-bottom: 0;
  }
}

.bedtime-goal {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  background-color: rgba(cyan, 0.2);
  p {
    padding-left: 4px;
    color: cyan;
    border-bottom: 2px solid cyan;
  }
}

.v-tooltip__content.menuable__content__active::before {
  content: '';
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #a55dff;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
