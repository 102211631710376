/* eslint no-param-reassign: 0 */
import * as _ from 'lodash';
import { dateStrInTimeZone, getDateForEpoch } from '../../../utils/datetime';
import { getSensorDataKey } from './routineConfig';

/**
 * @typedef {{targetAt: string|null}} EmptySensorData
 * @typedef {EmptySensorData & {loggedAt: string, value?: any}} FullSensorData
 * @typedef {EmptySensorData & {loggedAt?: string, value?: any}} OptionalSensorData
 * @typedef {{id: string, createdAt: string, eventEpoch?: number, loggedTimestamp: string, origin: number, sensorId: string, timestamp: string}} SensorData
 * @typedef {{value: any, sensorId: string, sensorName: string, eventEpoch:number, timestamp: string, createdAt: string, loggedTimestamp: string}} SensorDataEntry
 */

const dataOverwriteDebug = false;

/**
 * Strip not needed data from sensor data entry
 * @param {SensorData} sensorDataEntry
 * @param {string} sensorTypeId
 * @returns {SensorData}
 */
export function stripSensorDataEntry(sensorDataEntry, sensorTypeId) {
  const fieldsArr = [
    'id',
    'createdAt',
    'eventEpoch',
    'loggedTimestamp',
    'origin',
    'sensorId',
    'timestamp',
  ];
  const dataKey = getSensorDataKey(sensorTypeId);
  fieldsArr.push(dataKey);
  return _.pick(sensorDataEntry, fieldsArr);
}

/**
 * Get date string for sensor data
 * @param {string} userTimezone user timezone
 * @param {SensorData} sensorData
 * @returns {string}
 */
export function getDateStringForSensorData(userTimezone, sensorData) {
  const { eventEpoch, timestamp } = sensorData;
  let eventDateStr;
  if (timestamp) {
    eventDateStr = timestamp;
  } else {
    eventDateStr = getDateForEpoch(eventEpoch).toISOString();
  }
  return dateStrInTimeZone(eventDateStr, userTimezone);
}

/**
 * Returns true if sensor data should overwrite existing data
 * @param {string} sensorTypeId sensor type id
 * @param {SensorData} sensorData sensor data
 * @param {SensorData} existingSensorData existing sensor data
 * @returns {boolean}
 */
export function shouldOverwriteExistingData(sensorTypeId, sensorData, existingSensorData) {
  const { createdAt } = sensorData;
  const { createdAt: prevCreatedAt } = existingSensorData;
  if (new Date(createdAt) > new Date(prevCreatedAt)) {
    if (dataOverwriteDebug) {
      console.warn(
        `Overwriting previous sensor data of type ${sensorTypeId} created at ${prevCreatedAt} with data created at ${createdAt}`,
      );
    }
    return true;
  }
  if (dataOverwriteDebug) {
    console.warn(
      `Ignoring previous sensor data of type ${sensorTypeId} created at ${createdAt}, since current data was created at ${prevCreatedAt}`,
    );
  }

  return false;
}
