<template>
  <div>
    <v-container fill-height class="pb-0">
      <v-row>
        <v-col class="text-center pb-0">
          <h2>{{ $t('fig.medication.title') }}</h2>
          <p v-if="this.$store.state.api.isDirty">
            <MedicationPopup />
          </p>
        </v-col>
      </v-row>

      <v-row class="mb-3">
        <v-col class="text-center col-10 offset-1 col-md-4 offset-md-4">
          {{ $t('fig.medication.description') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center pb-0">
          <h3 class="mb-2">{{ $t('fig.medication.yourSchedule') }}</h3>
          <p class="mb-2 body-2 font-weight-light">
            {{ $t('fig.medication.yourScheduleDescription') }}
          </p>
        </v-col>
      </v-row>
      <v-row v-if="hasNoLicense">
        <v-col class="text-center">
          <v-card color="error" flat rounded class="pa-5">
            <h3 class="mb-2">{{ $t('fig.medication.invalidLicenseHeader') }}</h3>
            <p class="mb-2 body-2 font-weight-light">
              {{ $t('fig.medication.invalidLicenseBody') }}
            </p>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else-if="hasReachedDoseLimit">
        <v-col class="text-center">
          <v-card color="warning" flat rounded class="pa-5">
            <h3 class="mb-2">{{ $t('fig.medication.doseLimitHeader') }}</h3>
            <p class="mb-2 body-2 font-weight-light">
              {{ $t('fig.medication.reachedDoseLimit') }}
            </p>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="showScheduleNewDoseButton">
        <v-col class="text-center">
          <v-card style="max-width: 500px; margin: auto" color="primary" flat rounded class="pa-5">
            <v-btn
              large
              depressed
              rounded
              color="secondary"
              class="mgl-fab mb-5 mt-2"
              :disabled="hasReachedDoseLimit"
              fab
              @click="showDisclaimerBeforePush"
            >
              <v-icon x-large>mdi-plus</v-icon>
            </v-btn>
            <p class="text-uppercase caption ma-0">
              {{ $t('fig.medication.schedule') }}
            </p>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="doses.length > 0">
        <v-col v-for="(dose, i) in doses" :key="i" cols="12">
          <v-card
            style="max-width: 500px; margin: auto"
            text
            rounded
            color="primaryDark"
            flat
            :disabled="hasNoLicense"
          >
            <v-toolbar flat color="primary">
              <v-toolbar-title class="subtitle-1 font-weight-bold">
                {{ dose.schedule | cron2date('hoursMinutes') }}</v-toolbar-title
              >
              <ul class="mgl-weekdays-list ml-4">
                <li v-for="(weekDay, i) in weekDays" :key="i">
                  <span
                    v-if="doseIncludesWeekDay(dose.schedule, weekDay)"
                    class="White--text font-weight-bold"
                  >
                    {{ $t(weekDay.label) }}
                  </span>
                  <span v-else class="secondary--text">{{ $t(weekDay.label) }}</span>
                </li>
              </ul>
              <v-spacer></v-spacer>
              <v-menu bottom close-on-click transition="slide-y-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon large>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list tile class="mgl-menu-list py-0" color="primary">
                  <template v-for="(item, index) in medicationMenuItems">
                    <v-list-item
                      :key="index"
                      v-if="
                        !(item.id == 'copy' && hasReachedDoseLimit) &&
                        !(item.id == 'remove' && deleteDisabled)
                      "
                      @click="item.method(dose)"
                    >
                      <v-list-item-title :class="item.class">{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </v-toolbar>
            <v-card-text>
              <ul>
                <li v-for="(relation, i) in dose.relations" :key="i">
                  {{ relation.medication.name }},
                  {{ relation.medication.amount }}
                  {{ relation.medication.unit }}.
                  <span v-show="relation.amount > 0">
                    {{ relation.amount }}
                    {{
                      relation.medication.form
                        ? $t(`fig.medication.unit.${relation.medication.form}`)
                        : $t('fig.medication.unit.pills')
                    }}
                  </span>
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="showOptOutButtonValue">
        <v-col class="text-center" style="padding-left: 45px">
          <v-card
            style="
              max-width: 465px;
              margin: auto;
              padding-left: 25px !important;
              padding-bottom: 12px !important;
              padding-top: 0px !important;
            "
            color="primary v-timeline mgl-timeline v-timeline--align-top v-timeline--dense theme--dark"
            flat
            rounded
            class="pa-5"
            @click="optOutMedication"
          >
            <v-card-text style="display: flex">
              <div class="v-timeline-item__divider" style="margin-left: -70px">
                <div class="v-timeline-item__dot">
                  <div class="v-timeline-item__inner-dot primary">
                    <i
                      aria-hidden="true"
                      class="v-icon notranslate mdi mdi-chevron-double-right theme--dark"
                    ></i>
                  </div>
                </div>
              </div>
              <div style="margin: auto; padding-left: 30px">
                {{ $t('fig.medication.optOutMessage') }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-btn
        color="secondary"
        block
        large
        rounded
        depressed
        class="my-5"
        v-if="showDoneButton"
        @click="doneWithScheduling"
        >{{ $t('fig.medication.doneWithScheduling') }}<v-icon class="pl-2">mdi-arrow-right</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import { fetchRsaKeysFromLocalStorage } from '../utils/crypto';
import { isGlobalFlagEnabled } from '../utils/config';
import { FIG_APPID } from '../utils/constants';
import { displayError, setLoading, displayMessage } from '../utils/loading';
import { apiDispatch, anonymousApiDispatch } from '../utils/dispatch';
import { postEvent } from '../api/unityAnalytics';
import { getCurrentAdherence, getJournalDayIndex, getUserGroupIdCsv } from '../api/me';
import { setUserInfo } from '../api/users';
import MedicationPopup from '../components/MedicationPopup.vue';

export default {
  name: 'Medications',
  components: { MedicationPopup },
  data() {
    return {
      viewId: '005',
      showOptOutButtonValue: false,
      medicationMenuItems: [
        {
          title: this.$i18n.t('generic.edit'),
          id: 'schedule',
          class: 'text-center px-5',
          method: (dose) => {
            this.$router.push({
              name: 'schedule',
              query: { edit: dose.sensorId },
            });
          },
        },
        {
          title: this.$i18n.t('fig.medication.copyDose'),
          id: 'copy',
          class: 'text-center px-5',
          method: (dose) => {
            this.$router.push({
              name: 'schedule',
              query: { edit: dose.sensorId, duplicate: null },
            });
          },
        },
        {
          title: this.$i18n.t('generic.remove'),
          id: 'remove',
          class: 'text-center px-5 red--text text--lighten-2',
          method: async (dose) => {
            return this.removeDose(dose);
          },
        },
      ],
      weekDays: [
        { name: 'mon', label: 'generic.weekdays.short.1', index: 1 },
        { name: 'tue', label: 'generic.weekdays.short.2', index: 2 },
        { name: 'wed', label: 'generic.weekdays.short.3', index: 3 },
        { name: 'thu', label: 'generic.weekdays.short.4', index: 4 },
        { name: 'fri', label: 'generic.weekdays.short.5', index: 5 },
        { name: 'sat', label: 'generic.weekdays.short.6', index: 6 },
        { name: 'sun', label: 'generic.weekdays.short.7', index: 0 },
      ],
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.api.me !== null;
    },
    hasLicense() {
      return this.isLoggedIn ? this.$store.getters['api/hasValidLicense'] : false;
    },
    hasNoLicense() {
      return !this.hasLicense;
    },
    // For now we must limit doses to 4 due to limitations in number of possible reminders on iOS
    hasReachedDoseLimit() {
      return this.doses.length >= 4;
    },
    showDoneButton() {
      return this.doses.length > 0 && !this.$store.state.api.me.onboarded;
    },
    doses() {
      return this.$store.state.api.doses.filter((dose) => dose.deleted_at === undefined);
    },
    deleteDisabled() {
      return this.doses.length === 1;
    },
    nextDisabled() {
      return this.doses.length === 0;
    },
    showScheduleNewDoseButton() {
      return this.hasLicense && this.$store.state.api.me.medicationRequired;
    },
  },
  methods: {
    showDisclaimerBeforePush() {
      this.$root
        .$confirm(
          this.$i18n.t('fig.medication.disclaimer'),
          this.$i18n.t('fig.medication.disclaimerHtmlText'),
          {
            disableCancel: true,
            asHtml: true,
            actionButtonI18nKey: 'generic.okay',
          },
        )
        .then(async (confirm) => {
          if (confirm) {
            this.$router.push({ name: 'schedule' });
          }
        });
    },
    doseIncludesWeekDay(cronSchedule, weekDay) {
      const cronWeekDays = this.$options.filters.cron2date(cronSchedule, 'weekDays');
      return cronWeekDays.includes(weekDay.name);
    },
    doneWithScheduling() {
      displayMessage(this.$store, this.$i18n.t('fig.medication.medicationScheduleDone'), 'success');
      this.$router.push({ name: 'home' });
    },
    isVlcsUser() {
      const groupIdCsv = getUserGroupIdCsv(this.$store);
      return groupIdCsv != null && groupIdCsv.includes('vlcs');
    },
    async optOutMedication() {
      this.$root
        .$confirm(
          this.$i18n.t('generic.areYouSure'),
          this.$i18n.t('fig.medication.optOutDisclaimer'),
          {
            disableCancel: false,
            actionButtonI18nKey: 'generic.yes',
          },
        )
        .then(async (confirm) => {
          if (confirm) {
            const optOutEnabled = await isGlobalFlagEnabled(this.$store, 'MEDICATION_OPT_OUT');
            if (!optOutEnabled) {
              this.showOptOutButtonValue = false;
              return;
            }
            await setUserInfo(this.$store, this.$router, {
              medicationRequired: false,
              viewId: this.viewId,
            });
            displayMessage(this.$store, this.$i18n.t('fig.medication.optOutPlanSet'), 'success');
            this.$router.push({ name: 'home' });
          }
        });
    },
    async sendAdherenceAnalyticsEvent() {
      const journalDay = getJournalDayIndex(this.$store);
      if (journalDay === undefined) {
        return;
      }
      const adherence = await getCurrentAdherence(this.$store, this.$$router, this.viewId);
      await postEvent(this.$store, 'MedicationLogged', {
        adherence,
        journalDay,
      });
    },
    async removeDose(dose) {
      const confirm = await this.$root.$confirm(
        this.$i18n.t('generic.areYouSure'),
        this.$i18n.t('fig.medication.removeDoseConfirmation'),
        {
          disableCancel: false,
          actionButtonI18nKey: 'generic.yes',
        },
      );
      if (!confirm) {
        return;
      }
      setLoading(this.$store, true);
      // Remove dose from state
      this.$store.commit('api/deleteDose', dose);
      try {
        const { doses } = this.$store.state.api;
        const rsaKeys = await fetchRsaKeysFromLocalStorage(this.$store);
        // Save the doses from state
        await apiDispatch(this.$store, this.router, 'addDoses', {
          publicKey: rsaKeys.publicKey,
          appId: FIG_APPID,
          doses,
          viewId: this.viewId,
        });
        await anonymousApiDispatch(this.$store, this.$router, 'deleteSensor', {
          appId: FIG_APPID,
          dose,
          viewId: this.viewId,
        });
        await this.sendAdherenceAnalyticsEvent();
        displayMessage(this.$store, this.$i18n.t('fig.medication.removeDoseSuccess'), 'success');
      } catch (error) {
        displayError(this.$store, error);
      }
    },
    async showOptOutButton() {
      const optOutEnabled = await isGlobalFlagEnabled(this.$store, 'MEDICATION_OPT_OUT');
      this.showOptOutButtonValue = this.doses.length === 0 && !this.isVlcsUser() && optOutEnabled;
    },
  },
  created() {
    this.showOptOutButton();
  },
};
</script>

<style lang="scss" scoped>
.mgl-weekdays-list {
  list-style-type: none;
  li {
    float: left;
    margin-left: 8px;
    margin-bottom: 0;
  }
}
ul {
  list-style-type: none;
  padding: 0;
  li {
    margin-bottom: 5px;
  }
}
</style>
