import Vue from 'vue';
import axios from 'axios';
// import i18n from 'vue-i18n';
import i18n from './i18n';
import store from '../store';
import router from '../router';
import signOut from '../utils/auth';
import { displayError } from '../utils/loading';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

axios.interceptors.request.use(
  function onFullfilled(conf) {
    // Do something before request is sent
    return conf;
  },
  function onError(error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  function onFullfilled(response) {
    // Do something with response data
    return response;
  },
  async function onError(error) {
    if (error && error.response) {
      if (error.response.status === 403 || error.response.status === 500) {
        if (
          error.response.data === 'Not Authorized' ||
          (error.response.data.ctx && error.response.data.ctx.message === 'Not Authorized')
        ) {
          await signOut(store, router, true);
          displayError(store, new Error(i18n.t('session.timeOut').toString()));
        }
      }
    }
    // Do something with response error
    return Promise.reject(error);
  },
);

const axiosConfig = axios.create(config);

// @ts-ignore
Plugin.install = function onInstall(vue) {
  // eslint-disable-next-line no-param-reassign
  vue.axios = axiosConfig;
  // @ts-ignore
  window.axios = axiosConfig;
  Object.defineProperties(vue.prototype, {
    axios: {
      get() {
        return axiosConfig;
      },
    },
    $axios: {
      get() {
        return axiosConfig;
      },
    },
  });
};

// @ts-ignore
Vue.use(Plugin);

export default Plugin;
