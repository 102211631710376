import { DateTime, IANAZone } from 'luxon';

const msPerDay = 24 * 60 * 60 * 1000;

/**
 * Get date without time component
 * @deprecated Use luxon datetime instead
 * @param {Date} date date
 * @param {boolean} [utcFormat] if true, returned date will be in UTC format
 * @returns {Date}
 */
export function dateWithoutTime(date, utcFormat) {
  const dateCopy = new Date(date);
  if (utcFormat) {
    dateCopy.setUTCHours(0, 0, 0, 0);
    return dateCopy;
  }
  dateCopy.setHours(0, 0, 0, 0);
  return dateCopy;
}

/**
 * Converts an epoch to a UTC timestamp string
 * @param {number|string} epoch
 * @returns {string}
 */
export function getUTCTimeStamp(epoch) {
  let epochNumber = epoch;
  if (typeof epoch === 'string') {
    epochNumber = parseInt(epoch, 10);
  }
  return DateTime.fromSeconds(epochNumber).toUTC().toISO();
}

/**
 * Get date string for date in timezone
 * @param {string} timeStampStr time stamp string. If it contains Z, utc is assumed, else time zone
 * @param {string} timeZone time zone
 * @param {boolean} [debugMode] if true, debug info is printed to console
 * @returns {string}
 */
export function dateStrInTimeZone(timeStampStr, timeZone, debugMode) {
  let convertedTimeStampStr = timeStampStr;
  if (timeStampStr.includes('Z')) {
    const obj = DateTime.fromISO(timeStampStr, { zone: timeZone });
    convertedTimeStampStr = obj.toISO();
    if (debugMode) {
      console.log(
        `Input timestamp: ${timeStampStr}, tz: ${timeZone}, output timestamp: ${convertedTimeStampStr}`,
      );
    }
  } else if (timeStampStr.includes('T')) {
    // Assume time zone is correct in timestamp
    convertedTimeStampStr = timeStampStr;
  } else if (timeStampStr.includes(' ')) {
    // Assume time zone is correct in timestamp and convert to iso format
    convertedTimeStampStr = timeStampStr.replace(' ', 'T');
  }

  return convertedTimeStampStr.split('T')[0];
}

/**
 * Checks if time zone is a valid IANA time zone
 * @param {string} timeZoneStr
 * @returns {boolean}
 */
export function isValidIANATimeZone(timeZoneStr) {
  return IANAZone.isValidZone(timeZoneStr);
}

/**
 * Get short time string (hours and minutes) for date in timezone.
 * @param {string} timeStampStr time stamp string. Assumed ISO format
 * @returns {string}
 */
export function shortTimeStrFromISODateStr(timeStampStr) {
  return timeStampStr.split('T')[1].substring(0, 5);
}

/**
 * Get short time string (hours and minutes) for date in timezone
 * @param {string} timeStampStr time stamp string. Assumed ISO format
 * @param {string} timeZone time zone
 * @returns {string}
 */
export function shortTimeStrFromISODateStrInTimeZone(timeStampStr, timeZone) {
  const isoTime = datetimeForDateStrInTimeZone(timeStampStr, timeZone).toISOTime();
  return isoTime.substring(0, 5);
}

/**
 * Get short time string (hours and minutes) for Unix Timestamp string into a given timezone
 * @param {string} timeStampStr unix timestamp string. Assumed ISO format
 * @param {string} timeZone time zone
 * @returns {string}
 */
export function shortTimeStrFromTimestampStrInTimeZone(timeStampStr, timeZone) {
  if (timeStampStr == null) return null;
  const result = DateTime.fromSeconds(parseInt(timeStampStr, 10));
  return shortTimeStrFromISODateStrInTimeZone(result.toISO(), timeZone);
}

/**
 * Get date object for date string in timezone
 * @param {string} dateStr date string in formay YYYY-MM-DD
 * @param {string} timeZone time zone
 * @returns {DateTime}
 */
export function datetimeForDateStrInTimeZone(dateStr, timeZone, debugMode) {
  const dateTime = DateTime.fromISO(dateStr, { zone: timeZone });
  if (debugMode) {
    console.log(
      `Input date: ${dateStr}, tz: ${timeZone}, Output date: ${getDateInfoString(dateTime)}`,
    );
  }
  return dateTime;
}

/**
 * Get debug info text for date
 * @param {DateTime} dateTime
 * @returns {string}
 */
function getDateInfoString(dateTime) {
  return `{datetime: ${dateTime}, epoch: (${dateTime.toSeconds()}, offset: ${dateTime.offset}}`;
}

/**
 * Gets current date in a given timezone as a Date object
 * @param {string} timeZone time zone
 * @returns {DateTime}
 */
export function currentDateInTimeZone(timeZone) {
  return DateTime.now().setZone(timeZone);
}

/**
 * Add a number of days (can be negative) to a date
 * @deprecated use addDaysToDateTime instead
 * @param {Date} date
 * @param {number} days
 * @returns {Date}
 */
export function addDaysToDate(date, days) {
  return new Date(date.getTime() + msPerDay * days);
}

/**
 * Add a number of days (can be negative) to a luxon datetime
 * @param {DateTime} datetime
 * @param {number} days
 * @returns {DateTime}
 */
export function addDaysToDateTime(datetime, days) {
  return datetime.plus({ days });
}

/**
 * Get days between dates (rounded down)
 * @deprecated use luxon datetime instead
 * @param {Date} from
 * @param {Date} to
 * @returns {number}
 */
export function daysBetweenDates(from, to) {
  const msBetweenDates = to.getTime() - from.getTime();
  return Math.floor(msBetweenDates / msPerDay);
}

/**
 * Get day index for timestamp
 * @deprecated use luxon datetime instead
 * @param {string} playstartDateStr
 * @param {Date} [forDate] date to get day index for, if omitted current date is used
 * @returns {number}
 */
export function getDayIndex(playstartDateStr, forDate) {
  const targetDate = forDate || new Date();
  const date = dateWithoutTime(new Date(playstartDateStr), false);
  return daysBetweenDates(date, targetDate);
}

/**
 * Returns epoch in seconds for date
 * @deprecated use luxon datetime instead
 * @param {Date} date
 * @returns {number}
 */
export function getEpochSeconds(date) {
  return Math.round(date.getTime() / 1000);
}

/**
 * Returns date for epoch in seconds
 * @deprecated use luxon datetime instead
 * @param {number} epoch epoch in seconds
 * @returns {Date}
 */
export function getDateForEpoch(epoch) {
  return new Date(epoch * 1000);
}

/**
 * Get date as ISO date string (YYYY-MM-DD)
 * @deprecated use luxon datetime instead
 * @param {Date} date
 * @param {boolean} [local] if set, use local time instead of utc for date
 * @returns {string}
 */
export function getISODateString(date, local) {
  let targetDate = date;
  if (local) {
    targetDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  }
  return targetDate.toISOString().slice(0, 10);
}

/**
 * Get date from ISO date string (YYYY-MM-DD)
 * @deprecated use luxon datetime instead
 * @param {string} dateStr date string in ISO format to parse to date
 * @param {boolean} local if set, use local time instead of utc for date string
 * @returns {Date}
 */
export function fromISODateString(dateStr, local) {
  const date = new Date(dateStr);
  if (local) {
    date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
  }
  return date;
}

/**
 * Returns the given date in a formatted fashion.
 * @returns {string} The date and time in the format "yyyy-MM-dd HH:mm:ss.SSS".
 */
export function getFormattedISODateTimeString(date) {
  let isoStr = date.toISOString();
  isoStr = isoStr.substring(0, isoStr.length - 1);
  const parts = isoStr.split('T');
  return `${parts[0]} ${parts[1]}`;
}
